import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { history } from 'services/history';
import { EditCustomerStatus } from 'store/modules/customer/types';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';
import { checkPage } from 'utils/number';

import { riskActionsFunctions } from './action';
import { AllQuestions, ApiFetchCreateNewRiskRequestPayloadParams, ApiFetchEditRecommendationRequestPayloadParams, ApiFetchEditRiskRequestPayloadParams, ApiFetchEditRiskTreatsRequestPayloadParams, ApiFetchGetRiskDetailRequestPayloadParams, Recomendations, RecomendationsBodyType, Risk, RiskActions, RiskDetail, RiskInfoUpdateStatus, RiskPostBody, RiskResults, SearchFilteredRisksRequestPayload, TypesRecommendation } from './types';

const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE
} = ToastMessages;

const {
  GET_ALL_RISK_REQUEST,
  GET_ALL_RISK_OPTIONS_REQUEST,
  CREATE_NEW_RISK_REQUEST,
  GET_SEARCH_FILTERED_RISKS_REQUEST,
  GET_RISK_DETAIL_REQUEST,
  GET_ALL_RECOMENDATIONS_REQUEST,
  EDIT_RECOMENDATIONS_REQUEST,
  GET_ALL_RISK_STATUS_REQUEST,
  EDIT_RISK_REQUEST,
  UPDATE_RISK_THREAT_REQUEST
} = RiskActions;

const {
  getAllRisksSuccess,
  getAllRisksFailure,
  getAllQuestionsSuccess,
  getAllQuestionsFailure,
  createNewRiskSuccess,
  createNewRiskFailure,
  getSearchFilteredRisksSuccess,
  getSearchFilteredRisksFailure,
  getRiskDetailSuccess,
  getRiskDetailFailure,
  getAllRecomendationsSuccess,
  getAllRecomendationsFailure,
  editRecommendationsSuccess,
  editRecommendationsFailure,
  editRiskStatusSuccess,
  editRiskStatusFailure,
  editRiskSuccess,
  editRiskFailure,
  updateRiskThreatSuccess,
  updateRiskThreatFailure
 } = riskActionsFunctions;

const { getLastApiFetchDataSuccess, getLastApiFetchDataFailure } =
  lastApiFetchDataActionsFunctions;

function* fetchAllRisks(action: Action): Generator {
  try {
    const { payload } = action as any;
    const total: number = (yield select(state => state.risk.total)) as number;
    const last_page = checkPage(payload.last_page, total);
    const path = `controls/risks/?page=${last_page}`;
    const has_next = yield select(state => state.risk.has_next);

    if(has_next || last_page === 1){
      const riskFactorsResponse: AxiosResponse<RiskResults> | unknown =
        yield call(api, 'GET', path, {});

      const {
        data:  { results, count, next },
        config: { url },
        status,
        statusText,
      } = riskFactorsResponse as AxiosResponse<RiskResults>;

      yield put(
        getLastApiFetchDataSuccess({
          url,
          status,
          statusText,
        })
      );
      yield put(getAllRisksSuccess(results, count, last_page, !!next));
    }
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getAllRisksFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchEditRisks(action: Action): Generator {
  const { riskId, riskPutBody } = action.payload as ApiFetchEditRiskRequestPayloadParams;
  try {
    const riskRecommendationResponse: AxiosResponse<RecomendationsBodyType[]> | unknown =
      yield call(api, 'PUT', `controls/risks/${riskId}/`, {
        ...riskPutBody
      });

    const {
      config: { url },
      status,
      statusText,
    } = riskRecommendationResponse as AxiosResponse<RecomendationsBodyType[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editRiskSuccess());
    
    history.push(`/risk-visualization/${riskId}/`);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editRiskFailure());

    toast.error('Erro ao editar informações de risco.');
  }
}

function* fetchEditRisksThreats(action: Action): Generator {
  const { riskId, riskUpdateAdd, riskUpdateRemove } = action.payload as ApiFetchEditRiskTreatsRequestPayloadParams;

  const threats_add = riskUpdateAdd;
  const threats_remove = riskUpdateRemove;

  try {
    const threatResponse: AxiosResponse<ApiFetchEditRiskTreatsRequestPayloadParams> | unknown = yield call(
      api,
      'PUT',
      `/controls/risks/${riskId}/update-threats/`,
      { threats_add, threats_remove }
    );

    const {
      data: threatApiResponse,
      config: { url },
      status,
      statusText,
    } = threatResponse as AxiosResponse<ApiFetchEditRiskTreatsRequestPayloadParams>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(updateRiskThreatSuccess());

    toast.success('Ameaça editada com sucesso');
    history.push(`/risk-visualization/${riskId}/`);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(updateRiskThreatFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
        DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchEditRiskStatus(action: Action): Generator {
  const { riskId, riskStatus } = action.payload as RiskInfoUpdateStatus;
  try {
    const editControlStatus: AxiosResponse<EditCustomerStatus> | unknown = yield call(
      api,
      'PUT',
      `/controls/risks/${riskId}/update-status/`,
      {
        is_active: riskStatus
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = editControlStatus as AxiosResponse<EditCustomerStatus>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editRiskStatusSuccess());
    toast.success('Status editado com Sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editRiskStatusFailure());

    toast.error('Falha ao editar status.');
  }
}

function* fetchAllRecommendations(): Generator {
  try {
    const recommendationResponse: AxiosResponse<TypesRecommendation> | unknown =
      yield call(api, 'GET', 'controls/types-recommendation/', {});

    const {
      data: recommendation,
      config: { url },
      status,
      statusText,
    } = recommendationResponse as AxiosResponse<TypesRecommendation>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getAllRecomendationsSuccess(recommendation));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getAllRecomendationsFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
        DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchRiskDetail(action: Action): Generator {
  const { riskId } = action.payload as ApiFetchGetRiskDetailRequestPayloadParams;
  try {
    const riskFactorsResponse: AxiosResponse<RiskDetail> | unknown =
      yield call(api, 'GET', `controls/risks/${riskId}/detail/`, {});

    const {
      data: risks,
      config: { url },
      status,
      statusText,
    } = riskFactorsResponse as AxiosResponse<RiskDetail>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getRiskDetailSuccess(risks));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getRiskDetailFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
        DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchSearchFilteredRisks(action: Action): Generator {
  const { filteredRisks, isActive } =
    action.payload as SearchFilteredRisksRequestPayload;

  const parsedResponses = filteredRisks
    .filter(response => response !== null)
    .join(',');

  try {
    const filteredThreatsResponse: AxiosResponse<RiskResults> | unknown =
      yield call(api, 'GET', `controls/risks/?search=${parsedResponses}&&is_active=${isActive}`, {});

    const {
      data: risks,
      config: { url },
      status,
      statusText,
    } = filteredThreatsResponse as AxiosResponse<RiskResults>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredRisksSuccess(risks.results));

  } catch (error) {
    const currentError = error as AxiosError;
    
    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredRisksFailure());
  }
}

function* fetchRiskAllOptions(): Generator {
  try {
    const riskFactorsResponse: AxiosResponse<AllQuestions> | unknown =
      yield call(api, 'GET', 'controls/risk-table-config/', {});

    const {
      data: allQuestions,
      config: { url },
      status,
      statusText,
    } = riskFactorsResponse as AxiosResponse<AllQuestions>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );
    yield put(getAllQuestionsSuccess(allQuestions));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getAllQuestionsFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
        DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchCreateNewRisk(action: Action): Generator { 
  const { riskBody } = action.payload as ApiFetchCreateNewRiskRequestPayloadParams;
  try {
    const riskFactorsResponse: AxiosResponse<RiskPostBody> | unknown =
      yield call(api, 'POST', 'controls/risks/create/', {
        ...riskBody
      });

    const {
      data: riskResponse,
      config: { url },
      status,
      statusText,
    } = riskFactorsResponse as AxiosResponse<RiskPostBody>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(createNewRiskSuccess());
    history.push(`/risk-registration/${riskResponse.id}}`);
    toast.success('Novo Risco criado com sucesso.');

  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(createNewRiskFailure());

    if(currentError?.response?.data?.name[0] === 'risk with this name already exists.')
      toast.warn('Já existe um risco cadastrado com este nome.');
    else {
      toast.error(
        currentError?.response?.data?.messages[0]?.message ??
          DEFAULT_ERROR_MESSAGE
      );
    }    
  }
}

function* fetchEditControlRecommendations(action: Action): Generator {
  const { riskId, currentRecommendation } = action.payload as ApiFetchEditRecommendationRequestPayloadParams;
  try {
    const riskRecommendationResponse: AxiosResponse<RecomendationsBodyType[]> | unknown =
      yield call(api, 'PUT', `controls/risks/${riskId}/update-stages/`, {
        ...currentRecommendation
      });

    const {
      config: { url },
      status,
      statusText,
    } = riskRecommendationResponse as AxiosResponse<RecomendationsBodyType[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editRecommendationsSuccess());
    history.push(`/risk-visualization/${riskId}`);
    toast.success('Risco cadastrado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editRecommendationsFailure());

    if(currentError?.response?.data?.msg === 'stage with this name already exists') {
      toast.warn('já existe uma etapa com este nome.');
    } else {
      toast.error(
        currentError?.response?.data?.messages[0]?.message ??
          DEFAULT_ERROR_MESSAGE
      );
    }    
  }
}

export function* riskSagas() {
  yield all([
    takeEvery(GET_ALL_RISK_REQUEST, fetchAllRisks),
    takeLatest(GET_ALL_RISK_OPTIONS_REQUEST, fetchRiskAllOptions),
    takeLatest(CREATE_NEW_RISK_REQUEST, fetchCreateNewRisk),
    takeLatest(GET_SEARCH_FILTERED_RISKS_REQUEST, fetchSearchFilteredRisks),
    takeLatest(GET_RISK_DETAIL_REQUEST, fetchRiskDetail),
    takeLatest(GET_ALL_RECOMENDATIONS_REQUEST, fetchAllRecommendations),
    takeLatest(EDIT_RECOMENDATIONS_REQUEST, fetchEditControlRecommendations),
    takeLatest(GET_ALL_RISK_STATUS_REQUEST, fetchEditRiskStatus),
    takeLatest(EDIT_RISK_REQUEST, fetchEditRisks),
    takeLatest(UPDATE_RISK_THREAT_REQUEST, fetchEditRisksThreats),
  ]);
}
