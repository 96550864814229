import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';

import { medicalRecordsActionsFunctions } from './actions';
import {
  AllMedicalRecordQuestions,
  AllMedicalRecords,
  MedicalRecord,
  MedicalRecordPayload,
  MedicalRecordsActions,
  PostMedicalRecordRequestPayload,
  PutMedicalRecordRequestPayload,
  SearchFilteredMedicalRecordsPayload,
  SearchFilteredMedicalRecordsQuestionsPayload
} from './types';

const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE
} = ToastMessages;

const {
  ADD_MEDICALRECORDS_REQUEST
  ,EDIT_MEDICALRECORDS_REQUEST
  ,GET_ALL_MEDICALRECORDS_REQUEST
  ,GET_FILTERED_MEDICALRECORDS_REQUEST
  ,ACTIVE_OR_INACTIVE_MEDICALRECORDS_REQUEST
  ,GET_FILTERED_MEDICALRECORDS_QUESTIONS_REQUEST
} = MedicalRecordsActions;

const {
  getMedicalRecordsRequest
  ,putMedicalRecordSuccess
  ,putMedicalRecordFailure
  ,getMedicalRecordsSuccess
	,getMedicalRecordsFailure
  ,postMedicalRecordSuccess
  ,postMedicalRecordFailure
  ,getSearchFilteredMedicalRecordsSuccess
  ,getSearchFilteredMedicalRecordsFailure
  ,putActiveOrInactiveMedicalRecordSuccess
  ,putActiveOrInactiveMedicalRecordFailure
  ,getSearchFilteredMedicalRecordsQuestionsSuccess
  ,getSearchFilteredMedicalRecordsQuestionsFailure
} = medicalRecordsActionsFunctions;

const { getLastApiFetchDataSuccess, getLastApiFetchDataFailure } = lastApiFetchDataActionsFunctions;

function* fetchAllMedicalRecords(): Generator {
  try {
    const medicalRecordsResponse: AxiosResponse<AllMedicalRecords> | unknown = yield call(
      api,
      'GET',
      'controls/env-assessment/',
      {}
    );

    const {
      data: allMedicalRecords,
      config: { url },
      status,
      statusText,
    } = medicalRecordsResponse as AxiosResponse<AllMedicalRecords>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getMedicalRecordsSuccess(allMedicalRecords));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getMedicalRecordsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchTPutActiveOrInactiveMedicalRecord(action: Action): Generator {
  const { medicalRecord } = action.payload as MedicalRecordPayload;

  try {
    const updateStatusResponse: AxiosResponse<MedicalRecord> | unknown =
      yield call(api, 'PUT', `controls/env-assessment/${medicalRecord?.id}/`, {
        name: medicalRecord?.name,
        question: medicalRecord?.question?.id,
        is_active: !medicalRecord?.is_active
      });

    const {
      config: { url },
      status,
      statusText,
    } = updateStatusResponse as AxiosResponse<MedicalRecord>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(putActiveOrInactiveMedicalRecordSuccess());
    yield put(getMedicalRecordsRequest());

    toast.success('Edição do status do prontuário foi realizado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(putActiveOrInactiveMedicalRecordFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      'Tentativa de edição do status do prontuário falhou.'
    );
  }
}

function* fetchPostMedicalRecord(action: Action): Generator {
  const { name, question } = action.payload as PostMedicalRecordRequestPayload;

  try {
    const addNewResponse: AxiosResponse<MedicalRecord> | unknown = yield call(
      api,
      'POST',
      'controls/env-assessment/create/',
      {
        name,
        question,
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = addNewResponse as AxiosResponse<MedicalRecord>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(postMedicalRecordSuccess());
    yield put(getMedicalRecordsRequest());

    toast.success('Novo prontuário adicionado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(postMedicalRecordFailure());

    toast.error(
      currentError?.response?.data?.name[0] ??
        currentError?.response?.data?.detail ??
        'Tentativa de adicionar um novo prontuário falhou.'
    );
  }
}

function* fetchPutMedicalRecord(action: Action): Generator {
  const { id, name, question, is_active } = action.payload as PutMedicalRecordRequestPayload;

  try {
    const editingResponse: AxiosResponse<MedicalRecord> | unknown = yield call(
      api,
      'PUT',
      `controls/env-assessment/${id}/`,
      {
        name,
        question,
        is_active
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = editingResponse as AxiosResponse<MedicalRecord>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(putMedicalRecordSuccess());
    yield put(getMedicalRecordsRequest());

    toast.success('Edição do status do prontuário foi realizado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(putMedicalRecordFailure());

    toast.error(
      currentError?.response?.data?.detail ?? 'Tentativa de edição do prontuário falhou.'
    );
  }
}

function* fetchSearchFilteredMedicalRecords(action: Action): Generator {
  const { name, is_active } = action.payload as SearchFilteredMedicalRecordsPayload;

  let searchMedicalRecords = 'controls/env-assessment/';
  if (name !== null) {
    searchMedicalRecords = searchMedicalRecords.concat(`?search=${name}`);
    if (is_active !== null) {
      searchMedicalRecords = searchMedicalRecords.concat(`&&is_active=${is_active}`);
    }
  } else if (is_active !== null) {
    searchMedicalRecords = searchMedicalRecords.concat(`?is_active=${is_active}`);
  }

  try {
    const filteredMedicalRecordsResponse: AxiosResponse<AllMedicalRecords> | unknown = yield call(
      api,
      'GET',
      searchMedicalRecords,
      {}
    );

    const {
      data: allMedicalRecords,
      config: { url },
      status,
      statusText,
    } = filteredMedicalRecordsResponse as AxiosResponse<AllMedicalRecords>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredMedicalRecordsSuccess(allMedicalRecords));
    toast.success('Filtragem da pesquisa do(s) prontuário(s) foi realizada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredMedicalRecordsFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      'Tentativa de filtragem da pesquisa do(s) prontuário(s) falhou.'
    );
  }
}

function* fetchSearchFilteredMedicalRecordsQuestions(action: Action): Generator {
  const { question } = action.payload as SearchFilteredMedicalRecordsQuestionsPayload;

  try {
    const filteredMedicalRecordQuestionsResponse: AxiosResponse<AllMedicalRecordQuestions> | unknown = yield call(
      api,
      'GET',
      `controls/questions-id-name/?search=${question}`,
      {}
    );

    const {
      data: allMedicalRecordQuestions,
      config: { url },
      status,
      statusText,
    } = filteredMedicalRecordQuestionsResponse as AxiosResponse<AllMedicalRecordQuestions>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredMedicalRecordsQuestionsSuccess(allMedicalRecordQuestions));
    toast.success('Filtragem da pesquisa do(s) questionário(s) foi realizada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredMedicalRecordsQuestionsFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      'Tentativa de filtragem da pesquisa do(s) questionário(s) falhou.'
    );
  }
}

export function* medicalRecordsSagas() {
  yield all([
    takeLatest(ADD_MEDICALRECORDS_REQUEST, fetchPostMedicalRecord)
    ,takeLatest(EDIT_MEDICALRECORDS_REQUEST, fetchPutMedicalRecord)
    ,takeLatest(GET_ALL_MEDICALRECORDS_REQUEST, fetchAllMedicalRecords)
    ,takeLatest(GET_FILTERED_MEDICALRECORDS_REQUEST, fetchSearchFilteredMedicalRecords)
    ,takeLatest(ACTIVE_OR_INACTIVE_MEDICALRECORDS_REQUEST, fetchTPutActiveOrInactiveMedicalRecord)
    ,takeLatest(GET_FILTERED_MEDICALRECORDS_QUESTIONS_REQUEST, fetchSearchFilteredMedicalRecordsQuestions)
  ]);
}
