import { all } from 'redux-saga/effects';

import { authSagas } from './auth/sagas';
import { customerSectorsSagas } from './customer/customerSector/sagas';
import { customerSagas } from './customer/sagas';
import { customerProjectSagas } from './customerProject/sagas';
import { controlRecommendationsSagas } from './customerProjectControl/ControlRecommendations/sagas';
import { customerProjectControlSagas } from './customerProjectControl/CustomerControls/sagas';
import { customerProjectNistSagas } from './customerProjectNist/sagas';
import { customerProjectReportsSagas } from './customerProjectReport/sagas';
import { customerProjectRisksSagas } from './customerProjectRisk/sagas';
import { interwiiesSagas } from './interwiees/sagas';
import { activitiesSagas } from './Manutention/Activities/sagas';
import { applicationLogSagas } from './Manutention/ApplicationLogs/sagas';
import { checklistsSagas } from './Manutention/checklists/sagas';
import { controlSagas } from './Manutention/Control/sagas';
import { controlTypesSagas } from './Manutention/controlTypes/sagas';
import { crownJewelsSagas } from './Manutention/CrownJewels/sagas';
import { damagesSagas } from './Manutention/damages/sagas';
import { frameworksSagas } from './Manutention/Frameworks/sagas';
import { medicalRecordsSagas } from './Manutention/MedicalRecords/sagas';
import { questionnairesSagas } from './Manutention/questionnaries/sagas';
import { questionsSagas } from './Manutention/questions/sagas';
import { riskFactorsSagas } from './Manutention/riskFactors/sagas';
import { riskSagas } from './Manutention/Risks/sagas';
import { sectorsSagas } from './Manutention/sectors/sagas';
import { sizingTypesSagas } from './Manutention/SizingType/sagas';
import { solutionsSagas } from './Manutention/Solutions/sagas';
import { standardsSagas } from './Manutention/Standards/sagas';
import { vulnerabilitiesSagas } from './Manutention/vulnerabilities/sagas';
import { marketAverageSagas } from './marketAverage/sagas';
import { projectSettingsSagas } from './Project/ProjectSettings/sagas';
import { projectsForAnonimizationSagas } from './Project/ProjectsForAnonymization/sagas';
import { threatsSagas } from './threats/sagas';
import { userProfilesSagas } from './userProfile/sagas';
import { usersSagas } from './users/sagas';

export function* rootSaga(): Generator {
  return yield all([
    authSagas(),
    usersSagas(),
    sectorsSagas(),
    vulnerabilitiesSagas(),
    customerSagas(),
    interwiiesSagas(),
    riskFactorsSagas(),
    damagesSagas(),
    checklistsSagas(),
    threatsSagas(),
    riskSagas(),
    controlTypesSagas(),
    activitiesSagas(),
    questionsSagas(),
    crownJewelsSagas(),
    controlSagas(),
    customerProjectSagas(),
    projectSettingsSagas(),
    questionnairesSagas(),
    customerProjectRisksSagas(),
    customerProjectControlSagas(),
    customerProjectReportsSagas(),
    controlRecommendationsSagas(),
    customerProjectNistSagas(),
    customerSectorsSagas(),
    marketAverageSagas(),
    frameworksSagas(),
    standardsSagas(),
    projectsForAnonimizationSagas(),
    applicationLogSagas(),
    solutionsSagas(),
    userProfilesSagas(),
    medicalRecordsSagas(),
    sizingTypesSagas(),
  ]);
}
