import produce from 'immer';
import {
  Reducer
} from 'redux';

import {
  SizingTypesActions,
  SizingTypeState
} from './types';


const {
  GET_ALL_SIZINGTYPES_REQUEST
	,GET_ALL_SIZINGTYPES_SUCCESS
	,GET_ALL_SIZINGTYPES_FAILURE
  ,GET_FILTERED_SIZINGTYPES_REQUEST
  ,GET_FILTERED_SIZINGTYPES_SUCCESS
  ,GET_FILTERED_SIZINGTYPES_FAILURE
  ,ACTIVE_OR_INACTIVE_SIZINGTYPES_REQUEST
  ,ACTIVE_OR_INACTIVE_SIZINGTYPES_SUCCESS
  ,ACTIVE_OR_INACTIVE_SIZINGTYPES_FAILURE
} = SizingTypesActions;

const initialState: SizingTypeState = {
  allSizingTypes: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
};

export const sizingTypeReducer: Reducer<SizingTypeState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_SIZINGTYPES_REQUEST:
      case GET_FILTERED_SIZINGTYPES_REQUEST:
        break;

      case GET_ALL_SIZINGTYPES_SUCCESS:
      case GET_FILTERED_SIZINGTYPES_SUCCESS: {
        const { allSizingTypes } = action.payload;
        draft.allSizingTypes = allSizingTypes;
        break;
      }

      case GET_ALL_SIZINGTYPES_FAILURE:
      case GET_FILTERED_SIZINGTYPES_FAILURE: {
        draft.allSizingTypes = {
          count: 0,
          next: null,
          previous: null,
          results: []
        };
        break;
      }

      case ACTIVE_OR_INACTIVE_SIZINGTYPES_REQUEST:
      case ACTIVE_OR_INACTIVE_SIZINGTYPES_SUCCESS:
      case ACTIVE_OR_INACTIVE_SIZINGTYPES_FAILURE:
        break;

      default:
    }
  });
};
