import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { history } from 'services/history';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import { activitiesActionsFunctions } from 'store/modules/Manutention/Activities/actions';
import { checklistsActionsFunctions } from 'store/modules/Manutention/checklists/actions';
import { crownJewelsActionsFunctions } from 'store/modules/Manutention/CrownJewels/actions';
import type { Action } from 'store/types';


import { projectSettingsActionsFunctions } from './actions';
import type {
  ProjectSettings,
  EditProjectSettingsRequestPayload,
  ProjectSettingsDetails,
  ProjectSettingsDetailsRequest,
  CreateNewProjectSettingsPayloadApi,
  UpdateStatusRequestPayloadParams,
  SearchFilteredProjectSettingsRequestPayloadParams,
  ProjectSettingsWithAddAndRemove,
  Questionnaire,
  SearchFilteredQuestionnaireRequestPayload
} from './types';
import { ProjectSettingsActions } from './types';

const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE,
  EDIT_PROJECTSETTINGS_ERROR_MESSAGE,
  EDIT_PROJECTSETTINGS_SUCCESS_MESSAGE,
  NEW_PROJECTSETTINGS_ADDED_ERROR_MESSAGE,
  GET_PROJECTSETTINGS_DETAILS_ERROR_MESSAGE,
  GET_PROJECTSETTINGS_DETAILS_SUCCESS_MESSAGE,
  SEARCH_FILTERED_PROJECTSETTINGS_ERROR_MESSAGE,
  SEARCH_FILTERED_PROJECTSETTINGS_SUCCESS_MESSAGE,
  ACTIVE_OR_INACTIVE_PROJECTSETTINGS_ERROR_MESSAGE,
  ACTIVE_OR_INACTIVE_PROJECTSETTINGS_SUCCESS_MESSAGE,
  SEARCH_FILTERED_QUESTIONNAIRE_SUCCESS_MESSAGE,
  SEARCH_FILTERED_QUESTIONNAIRE_ERROR_MESSAGE
} = ToastMessages;

const { getActivitiesProjectSettingsRequest } = activitiesActionsFunctions;
const { getChecklistsProjectSettingsRequest } = checklistsActionsFunctions;
const { getCrownJewelsRequest } = crownJewelsActionsFunctions;

const {
  EDIT_PROJECTSETTING_REQUEST,
  CREATE_NEW_PROJECTSETTING_REQUEST,
  GET_ALL_PROJECTSETTING_REQUEST,
  GET_PROJECTSETTING_DETAILS_REQUEST,
  GET_FILTERED_PROJECTSETTING_REQUEST,
  ACTIVE_OR_INACTIVE_PROJECTSETTING_REQUEST,
  GET_ALL_QUESTIONNAIRE_PROJECTSETTINGS_REQUEST,
  GET_FILTERED_QUESTIONNAIRE_PROJECTSETTINGS_REQUEST
} = ProjectSettingsActions;

const {
  getProjectSettingsRequest,
  getProjectSettingsSuccess,
  getProjectSettingsFailure,
  editProjectSettingsSuccess,
  createNewProjectSettingSuccess,
  createNewProjectSettingFailure,
  getProjectSettingsDetailsSuccess,
  getProjectSettingsDetailsFailure,
  activeOrInactiveProjectSettingsSuccess,
  activeOrInactiveProjectSettingsFailure,
  getSearchFilteredProjectSettingsSuccess,
  getSearchFilteredProjectSettingsFailure,
  getQuestionnaireSuccess,
  getQuestionnaireFailure,
  getSearchFilteredQuestionnaireSuccess,
  getSearchFilteredQuestionnaireFailure
} = projectSettingsActionsFunctions;

const { getLastApiFetchDataSuccess, getLastApiFetchDataFailure } = lastApiFetchDataActionsFunctions;

function* fetchAllProjectSettings(): Generator {
  try {
    const projectSettingsResponse: AxiosResponse<ProjectSettings[]> | unknown = yield call(
      api,
      'GET',
      '/projects/project-types/',
      {}
    );

    const {
      data: projectSettings,
      config: { url },
      status,
      statusText,
    } = projectSettingsResponse as AxiosResponse<ProjectSettings[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getProjectSettingsSuccess(projectSettings));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getProjectSettingsFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchProjectSettingsDetails(action: Action): Generator {
  const { projectSettingsId } = action.payload as ProjectSettingsDetailsRequest;

  try {
    const projectSettingsDetailsResponse: AxiosResponse<ProjectSettingsDetails> | unknown = yield call(
      api,
      'GET',
      `projects/project-types/${projectSettingsId}/detail/`,
      {}
    );

    const {
      data: projectSettingsDetails,
      config: { url },
      status,
      statusText,
    } = projectSettingsDetailsResponse as AxiosResponse<ProjectSettingsDetails>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getProjectSettingsDetailsSuccess(projectSettingsDetails));

    toast.success(GET_PROJECTSETTINGS_DETAILS_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getProjectSettingsDetailsFailure());

    toast.error(GET_PROJECTSETTINGS_DETAILS_ERROR_MESSAGE);
  }
}

function* fetchSearchFilteredProjectSettings(action: Action): Generator {
  const { filteredProjectSettings, isActive } = action.payload as SearchFilteredProjectSettingsRequestPayloadParams;

  const parsedResponses = filteredProjectSettings.filter(response => response !== null).join(',');

  try {
    const filteredProjectSettingsResponse: AxiosResponse<ProjectSettings[]> | unknown = yield call(
      api,
      'GET',
      `projects/project-types/?search=${parsedResponses}&&is_active=${isActive}`,
      {}
    );

    const {
      data: filteredProjectSettings,
      config: { url },
      status,
      statusText,
    } = filteredProjectSettingsResponse as AxiosResponse<ProjectSettings[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredProjectSettingsSuccess(filteredProjectSettings));
    toast.success(SEARCH_FILTERED_PROJECTSETTINGS_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredProjectSettingsFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      SEARCH_FILTERED_PROJECTSETTINGS_ERROR_MESSAGE
    );
  }
}

function* fetchToProjectSettingsEditing(action: Action): Generator {
  const {
    editProjectSettings
  } = action.payload as EditProjectSettingsRequestPayload;

  try {
    const projectSettingsEditingResponse: AxiosResponse<ProjectSettingsWithAddAndRemove> | unknown = yield call(
      api,
      'PUT',
      `projects/project-types/${editProjectSettings.id}/`,
      {
        ...editProjectSettings
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = projectSettingsEditingResponse as AxiosResponse<ProjectSettingsWithAddAndRemove>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editProjectSettingsSuccess());
    yield put(getProjectSettingsRequest());
    yield put(getChecklistsProjectSettingsRequest());
    yield put(getActivitiesProjectSettingsRequest());
    yield put(getCrownJewelsRequest());

    toast.success(EDIT_PROJECTSETTINGS_SUCCESS_MESSAGE);
    history.push(`/project-types-vizualization/${editProjectSettings.id}/`);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));

    toast.error(
      currentError?.response?.data?.detail ?? EDIT_PROJECTSETTINGS_ERROR_MESSAGE
    );
  }
}

function* fetchToUpdateProjectSettingsStatus(action: Action): Generator {
  const { id, updatedStatus } =
    action.payload as UpdateStatusRequestPayloadParams;

  try {
    const updateProjectSettingsStatusResponse: AxiosResponse<ProjectSettings> | unknown =
      yield call(api, 'PUT', `projects/project-types/${id}/update-status/`, {
        is_active: updatedStatus,
      });

    const {
      config: { url },
      status,
      statusText,
    } = updateProjectSettingsStatusResponse as AxiosResponse<ProjectSettings>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(activeOrInactiveProjectSettingsSuccess());
    yield put(getProjectSettingsRequest());

    toast.success(ACTIVE_OR_INACTIVE_PROJECTSETTINGS_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(activeOrInactiveProjectSettingsFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      ACTIVE_OR_INACTIVE_PROJECTSETTINGS_ERROR_MESSAGE
    );
  }
}

function* fetchCreateNewProjectSettingRequest(action: Action): Generator {
  const { projectSettingPostBody } = action.payload as CreateNewProjectSettingsPayloadApi;
  try {
    const projectSettingResponse: AxiosResponse<ProjectSettings> | unknown = yield call(
      api,
      'POST',
      '/projects/project-types/create/',
      { ...projectSettingPostBody }
    );

    const {
      data: projectSettingApiResponse,
      config: { url },
      status,
      statusText,
    } = projectSettingResponse as AxiosResponse<ProjectSettings>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(createNewProjectSettingSuccess());

    history.push(`/project-types-registration/${projectSettingApiResponse.id}/`);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(createNewProjectSettingFailure());

    toast.error(
      currentError?.response?.data?.name[0] ??
      currentError?.response?.data?.detail ??
      NEW_PROJECTSETTINGS_ADDED_ERROR_MESSAGE
    );
  }
}

function* fetchAllQuestionnairesProjectSettings(): Generator {
  try {
    const questionnairesResponse: AxiosResponse<Questionnaire[]> | unknown = yield call(
      api,
      'GET',
      'projects/questionnaires/',
      {}
    );

    const {
      data: questionnaires,
      config: { url },
      status,
      statusText,
    } = questionnairesResponse as AxiosResponse<Questionnaire[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getQuestionnaireSuccess(questionnaires));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getQuestionnaireFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchSearchFilteredQuestionnaireProjectSettings(action: Action): Generator {
  const { filteredQuestionnaires, filterQuizType } = action.payload as SearchFilteredQuestionnaireRequestPayload;

  const statusQuestionnaires = filteredQuestionnaires.filter(response => typeof response === 'boolean')[0];
  const nameQuestionnaires = filteredQuestionnaires.filter(response => response !== null && typeof response !== 'boolean').join(',');

  let result = 'projects/questionnaires/?';
  if (nameQuestionnaires !== '') {
    result = result.concat(`search=${nameQuestionnaires}`);
    if (statusQuestionnaires !== undefined)
      result = result.concat(`&&is_active=${statusQuestionnaires}`);
    if (filterQuizType !== null && filterQuizType !== undefined)
      result = result.concat(`&&type_of_questionnaire=${filterQuizType}`);
  }
  else if (statusQuestionnaires !== undefined) {
    result = result.concat(`is_active=${statusQuestionnaires}`);
    if (filterQuizType !== null && filterQuizType !== undefined)
      result = result.concat(`&&type_of_questionnaire=${filterQuizType}`);
  }
  else {
    result = result.concat(`type_of_questionnaire=${filterQuizType}`);
  }

  const routeWithFilter = result;

  try {
    const questionnairesResponse: AxiosResponse<Questionnaire[]> | unknown =
      yield call(api, 'GET', routeWithFilter, {});

    const {
      data: questionnaires,
      config: { url },
      status,
      statusText,
    } = questionnairesResponse as AxiosResponse<Questionnaire[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredQuestionnaireSuccess(questionnaires));

    toast.success(SEARCH_FILTERED_QUESTIONNAIRE_SUCCESS_MESSAGE);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredQuestionnaireFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      SEARCH_FILTERED_QUESTIONNAIRE_ERROR_MESSAGE
    );
  }
}

export function* projectSettingsSagas() {
  yield all([
    takeLatest(GET_ALL_PROJECTSETTING_REQUEST, fetchAllProjectSettings),
    takeLatest(CREATE_NEW_PROJECTSETTING_REQUEST, fetchCreateNewProjectSettingRequest),
    takeLatest(EDIT_PROJECTSETTING_REQUEST, fetchToProjectSettingsEditing),
    takeLatest(GET_PROJECTSETTING_DETAILS_REQUEST, fetchProjectSettingsDetails),
    takeLatest(GET_FILTERED_PROJECTSETTING_REQUEST, fetchSearchFilteredProjectSettings),
    takeLatest(ACTIVE_OR_INACTIVE_PROJECTSETTING_REQUEST, fetchToUpdateProjectSettingsStatus),
    takeLatest(GET_ALL_QUESTIONNAIRE_PROJECTSETTINGS_REQUEST, fetchAllQuestionnairesProjectSettings),
    takeLatest(GET_FILTERED_QUESTIONNAIRE_PROJECTSETTINGS_REQUEST, fetchSearchFilteredQuestionnaireProjectSettings),
  ]);
}