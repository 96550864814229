import type { Action } from 'store/types';

import { 
  ControlActions, 
  ControlBody, 
  ControlDetail, 
  ControlEditBody, 
  ControlEditRequestBody, 
  Currencies, 
  currencyPayload, 
  pricingsPayload, 
  ProductPayload, 
  PutPricingsPayload, 
  PutProductPayload
} from './types';

const {    
  EDIT_CONTROL_REQUEST,
  EDIT_CONTROL_SUCCESS,
  EDIT_CONTROL_FAILURE,
  GET_CURRENCIES_REQUEST,
  GET_CURRENCIES_SUCCESS,
  GET_CURRENCIES_FAILURE,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  CREATE_PRICINGS_REQUEST,
  CREATE_PRICINGS_SUCCESS,
  CREATE_PRICINGS_FAILURE,
  UPDATE_PRICINGS_REQUEST,
  UPDATE_PRICINGS_SUCCESS,
  UPDATE_PRICINGS_FAILURE,
  CREATE_CURRENCIES_REQUEST,
  CREATE_CURRENCIES_SUCCESS,
  CREATE_CURRENCIES_FAILURE,
  GET_CONTROL_DETAIL_REQUEST,
  GET_CONTROL_DETAIL_SUCCESS,
  GET_CONTROL_DETAIL_FAILURE,
  CREATE_NEW_CONTROL_REQUEST,
  CREATE_NEW_CONTROL_SUCCESS,
  CREATE_NEW_CONTROL_FAILURE,
  EDIT_CONTROL_STATUS_REQUEST,
  EDIT_CONTROL_STATUS_SUCCESS,
  EDIT_CONTROL_STATUS_FAILURE,
  EDIT_CONTROL_REGISTRATION_REQUEST,
  EDIT_CONTROL_REGISTRATION_SUCCESS,
  EDIT_CONTROL_REGISTRATION_FAILURE,      
} = ControlActions;


function getControlDetailRequest(controlId: string): Action {
  return {
    type: GET_CONTROL_DETAIL_REQUEST,
    payload: {
      controlId
    },
  };
}

function getControlDetailSuccess(control: ControlDetail): Action {
  return {
    type: GET_CONTROL_DETAIL_SUCCESS,
    payload: {
      control
    },
  };
}

function getControlDetailFailure(): Action {
  return {
    type: GET_CONTROL_DETAIL_FAILURE,
  };
}

function editControlStatusRequest(controlId: string, controlStatus: boolean): Action {
  return {
    type: EDIT_CONTROL_STATUS_REQUEST,
    payload: {
      controlId,
      controlStatus
    },
  };
}

function editControlStatusSuccess(): Action {
  return {
    type: EDIT_CONTROL_STATUS_SUCCESS,
  };
}

function editControlStatusFailure(): Action {
  return {
    type: EDIT_CONTROL_STATUS_FAILURE,
  };
}

function createNewControlRequest(controlBody: ControlBody): Action {
  return {
    type: CREATE_NEW_CONTROL_REQUEST,
    payload: {
      controlBody
    },
  };
}

function createNewControlSuccess(): Action {
  return {
    type: CREATE_NEW_CONTROL_SUCCESS,
  };
}

function createNewControlFailure(): Action {
  return {
    type: CREATE_NEW_CONTROL_FAILURE,
  };
}

function editControlRegistrationsRequest(controlId: string, controlEditBody: ControlEditBody): Action {
  return {
    type: EDIT_CONTROL_REGISTRATION_REQUEST,
    payload: {
      controlId,
      controlEditBody
    },
  };
}

function editControlRegistrationsSuccess(): Action {
  return {
    type: EDIT_CONTROL_REGISTRATION_SUCCESS,
  };
}

function editControlRegistrationsFailure(): Action {
  return {
    type: EDIT_CONTROL_REGISTRATION_FAILURE,
  };
}

function editControlRequest(controlId: string, controlEditBody: ControlEditRequestBody, controlEditRegistrationBody: ControlEditBody): Action {
  return {
    type: EDIT_CONTROL_REQUEST,
    payload: {
      controlId,
      controlEditBody,
      controlEditRegistrationBody
    },
  };
}

function editControlSuccess(): Action {
  return {
    type: EDIT_CONTROL_SUCCESS,
  };
}

function editControlFailure(): Action {
  return {
    type: EDIT_CONTROL_FAILURE,
  };
}

function postNewPricingRequest(
  {    
    controlId,
    sizing_type,
    product,
    currency,
    start_range,
    end_range,
    unit_price,
  }: pricingsPayload): Action {
  return {
    type: CREATE_PRICINGS_REQUEST,
    payload: {      
      controlId,
      sizing_type,
      product,
      currency,
      start_range,
      end_range,
      unit_price,
    },
  };
}

function postNewPricingSuccess(): Action {
  return {
    type: CREATE_PRICINGS_SUCCESS,
  };
}

function postNewPricingFailure(): Action {
  return {
    type: CREATE_PRICINGS_FAILURE,
  };
}

function getCurrenciesRequest(): Action {
  return {
    type: GET_CURRENCIES_REQUEST,
  };
}

function getCurrenciesSuccess(allCurrencies: Currencies[]): Action {
  return {
    type: GET_CURRENCIES_SUCCESS,
    payload: {
      allCurrencies,
    },
  };
}

function getCurrenciesFailure(): Action {
  return {
    type: GET_CURRENCIES_FAILURE,
  };
}

function putPricingRequest(
  {
    id,
    controlId,
    sizing_type,
    currency,
    start_range,
    end_range,
    unit_price,
  }: PutPricingsPayload): Action {    
  return {
    type: UPDATE_PRICINGS_REQUEST,
    payload: {
      id,
      controlId,
      sizing_type,
      currency,
      start_range,
      end_range,
      unit_price,
    },
  };
}

function putPricingSuccess(): Action {
  return {
    type: UPDATE_PRICINGS_SUCCESS,
  };
}

function putPricingFailure(): Action {
  return {
    type: UPDATE_PRICINGS_FAILURE,
  };
}

function postNewCurrenciesRequest(
  {
    name,
    symbol,
  }: currencyPayload): Action {
  return {
    type: CREATE_CURRENCIES_REQUEST,
    payload: {
      name,
      symbol,
    },
  };
}

function postNewCurrenciesSuccess(): Action {
  return {
    type: CREATE_CURRENCIES_SUCCESS,
  };
}

function postNewCurrenciesFailure(): Action {
  return {
    type: CREATE_CURRENCIES_FAILURE,
  };
}

function postProductRequest(
  {
    controlId,
    name,
    control,
    subcontrol,
    default_control,
    type_control,
    time_to_deploy,
  }: ProductPayload): Action {
  return {
    type: CREATE_PRODUCT_REQUEST,
    payload: {
      controlId,
      name,
      control,
      subcontrol,
      default_control,
      type_control,
      time_to_deploy,
    },
  };
}

function postProductSuccess(): Action {
  return {
    type: CREATE_PRODUCT_SUCCESS,
  };
}

function postProductFailure(): Action {
  return {
    type: CREATE_PRODUCT_FAILURE,
  };
}

function putProductRequest(
  {
    id,
    name,
    is_active,
    default_control,
    controlId,
    time_to_deploy,
  }: PutProductPayload): Action {
  return {
    type: UPDATE_PRODUCT_REQUEST,
    payload: {
      id,
      name,
      is_active,
      default_control,
      controlId,
      time_to_deploy,
    },
  };
}

function putProductSuccess(): Action {
  return {
    type: UPDATE_PRODUCT_SUCCESS,
  };
}

function putProductFailure(): Action {
  return {
    type: UPDATE_PRODUCT_FAILURE,
  };
}


export const controlActionsFunctions = {  
  putPricingRequest,
  putPricingSuccess,
  putPricingFailure,
  putProductRequest,
  putProductSuccess,
  putProductFailure,
  postProductRequest,
  postProductSuccess,
  postProductFailure,  
  editControlRequest,
  editControlSuccess,
  editControlFailure,
  getCurrenciesRequest,
  getCurrenciesSuccess,
  getCurrenciesFailure,
  postNewPricingRequest,
  postNewPricingSuccess,
  postNewPricingFailure,
  getControlDetailRequest,
  getControlDetailSuccess,
  getControlDetailFailure,
  createNewControlRequest,
  createNewControlSuccess,
  createNewControlFailure,
  editControlStatusRequest,
  editControlStatusSuccess,
  editControlStatusFailure,
  postNewCurrenciesRequest,
  postNewCurrenciesSuccess,
  postNewCurrenciesFailure,  
  editControlRegistrationsRequest,
  editControlRegistrationsSuccess,
  editControlRegistrationsFailure,
};
