import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';

import { sizingTypesActionsFunctions } from './actions';
import {
  AllSizingTypes,
  SizingTypePayload,
  SizingTypesActions,
  PostSizingTypeRequestPayload,
  PutSizingTypeRequestPayload,
  SearchFilteredSizingTypesPayload,
  SizingType,
} from './types';

const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE
} = ToastMessages;

const {
  ADD_SIZINGTYPES_REQUEST
  ,EDIT_SIZINGTYPES_REQUEST
  ,GET_ALL_SIZINGTYPES_REQUEST
  ,GET_FILTERED_SIZINGTYPES_REQUEST
  ,ACTIVE_OR_INACTIVE_SIZINGTYPES_REQUEST
} = SizingTypesActions;

const {
  getSizingTypesRequest
  ,putSizingTypeSuccess
  ,putSizingTypeFailure
  ,getSizingTypesSuccess
	,getSizingTypesFailure
  ,postSizingTypeSuccess
  ,postSizingTypeFailure
  ,getSearchFilteredSizingTypesSuccess
  ,getSearchFilteredSizingTypesFailure
  ,putActiveOrInactiveSizingTypeSuccess
  ,putActiveOrInactiveSizingTypeFailure
} = sizingTypesActionsFunctions;

const { getLastApiFetchDataSuccess, getLastApiFetchDataFailure } = lastApiFetchDataActionsFunctions;

function* fetchAllSizingTypes(): Generator {
  try {
    const sizingTypesResponse: AxiosResponse<AllSizingTypes> | unknown = yield call(
      api,
      'GET',
      'controls/sizing-types/',
      {}
    );

    const {
      data: allSizingTypes,
      config: { url },
      status,
      statusText,
    } = sizingTypesResponse as AxiosResponse<AllSizingTypes>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSizingTypesSuccess(allSizingTypes));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSizingTypesFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchTPutActiveOrInactiveSizingType(action: Action): Generator {
  const { sizingType } = action.payload as SizingTypePayload;

  try {
    const updateStatusResponse: AxiosResponse<SizingType> | unknown =
      yield call(api, 'PUT', `controls/sizing-types/${sizingType?.id}/`, {
        is_active: !sizingType?.is_active
      });

    const {
      config: { url },
      status,
      statusText,
    } = updateStatusResponse as AxiosResponse<SizingType>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(putActiveOrInactiveSizingTypeSuccess());
    yield put(getSizingTypesRequest());

    toast.success('Edição do status do tipo de dimensionamento foi realizada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(putActiveOrInactiveSizingTypeFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      'Tentativa de edição do status do tipo de dimensionamento falhou.'
    );
  }
}

function* fetchPostSizingType(action: Action): Generator {
  const { name, env_assessments } = action.payload as PostSizingTypeRequestPayload;

  try {
    const addNewResponse: AxiosResponse<SizingType> | unknown = yield call(
      api,
      'POST',
      'controls/sizing-types/create/',
      {
        name,
        env_assessments,
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = addNewResponse as AxiosResponse<SizingType>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(postSizingTypeSuccess());
    yield put(getSizingTypesRequest());

    toast.success('Novo tipo de dimensionamento adicionado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(postSizingTypeFailure());

    toast.error(
      currentError?.response?.data?.name[0] ??
        currentError?.response?.data?.detail ??
        'Tentativa de adicionar um novo tipo de dimensionamento falhou.'
    );
  }
}

function* fetchPutSizingType(action: Action): Generator {
  const { id, name, env_assessments } = action.payload as PutSizingTypeRequestPayload;

  try {
    const editingResponse: AxiosResponse<SizingType> | unknown = yield call(
      api,
      'PUT',
      `controls/sizing-types/${id}/`,
      {
        name,
        env_assessments
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = editingResponse as AxiosResponse<SizingType>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(putSizingTypeSuccess());
    yield put(getSizingTypesRequest());

    toast.success('Edição do status do tipo de dimensionamento foi realizado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(putSizingTypeFailure());

    toast.error(
      currentError?.response?.data?.detail ?? 'Tentativa de edição do tipo de dimensionamento falhou.'
    );
  }
}

function* fetchSearchFilteredSizingTypes(action: Action): Generator {
  const { name, is_active } = action.payload as SearchFilteredSizingTypesPayload;

  let searchMedicalRecords = 'controls/sizing-types/';
  if (name !== null) {
    searchMedicalRecords = searchMedicalRecords.concat(`?search=${name}`);
    if (is_active !== null) {
      searchMedicalRecords = searchMedicalRecords.concat(`&&is_active=${is_active}`);
    }
  } else if (is_active !== null) {
    searchMedicalRecords = searchMedicalRecords.concat(`?is_active=${is_active}`);
  }

  try {
    const filteredMedicalRecordsResponse: AxiosResponse<AllSizingTypes> | unknown = yield call(
      api,
      'GET',
      searchMedicalRecords,
      {}
    );

    const {
      data: allSizingTypes,
      config: { url },
      status,
      statusText,
    } = filteredMedicalRecordsResponse as AxiosResponse<AllSizingTypes>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getSearchFilteredSizingTypesSuccess(allSizingTypes));
    toast.success('Filtragem da pesquisa do(s) tipo(s) de dimensionamento(s) foi realizada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getSearchFilteredSizingTypesFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      'Tentativa de filtragem da pesquisa do(s) tipo(s) de dimensionamento(s) falhou.'
    );
  }
}

export function* sizingTypesSagas() {
  yield all([
    takeLatest(ADD_SIZINGTYPES_REQUEST, fetchPostSizingType)
    ,takeLatest(EDIT_SIZINGTYPES_REQUEST, fetchPutSizingType)
    ,takeLatest(GET_ALL_SIZINGTYPES_REQUEST, fetchAllSizingTypes)
    ,takeLatest(GET_FILTERED_SIZINGTYPES_REQUEST, fetchSearchFilteredSizingTypes)
    ,takeLatest(ACTIVE_OR_INACTIVE_SIZINGTYPES_REQUEST, fetchTPutActiveOrInactiveSizingType)
  ]);
}
