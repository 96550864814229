export enum MedicalRecordsActions {
    ADD_MEDICALRECORDS_REQUEST = 'ADD_MEDICALRECORDS_REQUEST'
    ,ADD_MEDICALRECORDS_SUCCESS = 'ADD_MEDICALRECORDS_SUCCESS'
    ,ADD_MEDICALRECORDS_FAILURE = 'ADD_MEDICALRECORDS_FAILURE'
    ,EDIT_MEDICALRECORDS_REQUEST = 'EDIT_MEDICALRECORDS_REQUEST'
    ,EDIT_MEDICALRECORDS_SUCCESS = 'EDIT_MEDICALRECORDS_SUCCESS'
    ,EDIT_MEDICALRECORDS_FAILURE = 'EDIT_MEDICALRECORDS_FAILURE'
    ,GET_ALL_MEDICALRECORDS_REQUEST = 'GET_ALL_MEDICALRECORDS_REQUEST'
    ,GET_ALL_MEDICALRECORDS_SUCCESS = 'GET_ALL_MEDICALRECORDS_SUCCESS'
    ,GET_ALL_MEDICALRECORDS_FAILURE = 'GET_ALL_MEDICALRECORDS_FAILURE'
    ,GET_FILTERED_MEDICALRECORDS_REQUEST = 'GET_FILTERED_MEDICALRECORDS_REQUEST'
    ,GET_FILTERED_MEDICALRECORDS_SUCCESS = 'GET_FILTERED_MEDICALRECORDS_SUCCESS'
    ,GET_FILTERED_MEDICALRECORDS_FAILURE = 'GET_FILTERED_MEDICALRECORDS_FAILURE'
    ,ACTIVE_OR_INACTIVE_MEDICALRECORDS_REQUEST = 'ACTIVE_OR_INACTIVE_MEDICALRECORDS_REQUEST'
    ,ACTIVE_OR_INACTIVE_MEDICALRECORDS_SUCCESS = 'ACTIVE_OR_INACTIVE_MEDICALRECORDS_SUCCESS'
    ,ACTIVE_OR_INACTIVE_MEDICALRECORDS_FAILURE = 'ACTIVE_OR_INACTIVE_MEDICALRECORDS_FAILURE'
    ,GET_FILTERED_MEDICALRECORDS_QUESTIONS_REQUEST = 'GET_FILTERED_MEDICALRECORDS_QUESTIONS_REQUEST'
    ,GET_FILTERED_MEDICALRECORDS_QUESTIONS_SUCCESS = 'GET_FILTERED_MEDICALRECORDS_QUESTIONS_SUCCESS'
    ,GET_FILTERED_MEDICALRECORDS_QUESTIONS_FAILURE = 'GET_FILTERED_MEDICALRECORDS_QUESTIONS_FAILURE'
  }
  
  export type MedicalRecordQuestion = {
    id: string;
    name: string;
  };

  export type MedicalRecord = {
    id: string;
    name: string;
    question: MedicalRecordQuestion | null;
    is_active: boolean;
  };  

  export type SearchedMedicalRecord = {
    name: string | null;
    is_active: string | null;
  };

  export type SearchedMedicalRecordQuestions = {
    question: string | null;
  };

  export type AllMedicalRecords = {
    count: number;
    next: string | null;
    previous: string | null;
    results: MedicalRecord[];
  }

  export type AllMedicalRecordQuestions = {
    count: number;
    next: string | null;
    previous: string | null;
    results: MedicalRecordQuestion[];
  }

  export type OpenedModalMedicalRecords = {
    MedicalRecordsModal: boolean;
    MedicalRecordsActiveOrInactiveModal: boolean;
  };
  
  export type MedicalRecordsState = {
    allMedicalRecords: AllMedicalRecords;
    allMedicalRecordQuestions: AllMedicalRecordQuestions;
  };
  
  export type MedicalRecordPayload = {
    medicalRecord: MedicalRecord | null;
  }

  export type PutMedicalRecordRequestPayload = {
    id: string;
    name: string | undefined;
    question: string | null;
    is_active: boolean | null;
  }

  export type PostMedicalRecordRequestPayload = {
    name: string | undefined;
    question: string | null;
  }

  export type SearchFilteredMedicalRecordsPayload = {
    name: string | null;
    is_active: boolean | null;
  }

  export type SearchFilteredMedicalRecordsQuestionsPayload = {
    question: string | null;
  }