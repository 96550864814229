import produce from 'immer';
import {
  Reducer
} from 'redux';

import {
  MedicalRecordsState,
  MedicalRecordsActions
} from './types';


const {
  GET_ALL_MEDICALRECORDS_REQUEST
	,GET_ALL_MEDICALRECORDS_SUCCESS
	,GET_ALL_MEDICALRECORDS_FAILURE
  ,GET_FILTERED_MEDICALRECORDS_REQUEST
  ,GET_FILTERED_MEDICALRECORDS_SUCCESS
  ,GET_FILTERED_MEDICALRECORDS_FAILURE
  ,ACTIVE_OR_INACTIVE_MEDICALRECORDS_REQUEST
  ,ACTIVE_OR_INACTIVE_MEDICALRECORDS_SUCCESS
  ,ACTIVE_OR_INACTIVE_MEDICALRECORDS_FAILURE
  ,GET_FILTERED_MEDICALRECORDS_QUESTIONS_REQUEST
  ,GET_FILTERED_MEDICALRECORDS_QUESTIONS_SUCCESS
  ,GET_FILTERED_MEDICALRECORDS_QUESTIONS_FAILURE
} = MedicalRecordsActions;

const initialState: MedicalRecordsState = {
  allMedicalRecords: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
  allMedicalRecordQuestions: {
    count: 0,
    next: null,
    previous: null,
    results: []
  },
};

export const medicalRecordsReducer: Reducer<MedicalRecordsState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_ALL_MEDICALRECORDS_REQUEST:
      case GET_FILTERED_MEDICALRECORDS_REQUEST:
        break;

      case GET_ALL_MEDICALRECORDS_SUCCESS:
      case GET_FILTERED_MEDICALRECORDS_SUCCESS: {
        const { allMedicalRecords } = action.payload;
        draft.allMedicalRecords = allMedicalRecords;
        break;
      }

      case GET_ALL_MEDICALRECORDS_FAILURE:
      case GET_FILTERED_MEDICALRECORDS_FAILURE: {
        draft.allMedicalRecords = {
          count: 0,
          next: null,
          previous: null,
          results: []
        };
        break;
      }

      case ACTIVE_OR_INACTIVE_MEDICALRECORDS_REQUEST:
      case ACTIVE_OR_INACTIVE_MEDICALRECORDS_SUCCESS:
      case ACTIVE_OR_INACTIVE_MEDICALRECORDS_FAILURE:
        break;

      case GET_FILTERED_MEDICALRECORDS_QUESTIONS_REQUEST:
        break;

      case GET_FILTERED_MEDICALRECORDS_QUESTIONS_SUCCESS: {
        const { allMedicalRecordQuestions } = action.payload;
        draft.allMedicalRecordQuestions = allMedicalRecordQuestions;
        break;
      }

      case GET_FILTERED_MEDICALRECORDS_QUESTIONS_FAILURE:
        break;

      default:
    }
  });
};
