import { ObjectWithId } from 'store/modules/threats/types';

import { Control, SimpleControl } from '../vulnerabilities/types';

export enum ControlActions {  
  EDIT_CONTROL_REQUEST = 'EDIT_CONTROL_REQUEST',
  EDIT_CONTROL_SUCCESS = 'EDIT_CONTROL_SUCCESS',
  EDIT_CONTROL_FAILURE = 'EDIT_CONTROL_FAILURE',
  GET_CURRENCIES_REQUEST = 'GET_CURRENCIES_REQUEST',
  GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS',
  GET_CURRENCIES_FAILURE = 'GET_CURRENCIES_FAILURE',
  CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST',
  CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS',
  CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE',
  UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST',
  UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS',
  UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE',
  CREATE_PRICINGS_REQUEST = 'CREATE_PRICINGS_REQUEST',
  CREATE_PRICINGS_SUCCESS = 'CREATE_PRICINGS_SUCCESS',
  CREATE_PRICINGS_FAILURE = 'CREATE_PRICINGS_FAILURE',
  UPDATE_PRICINGS_REQUEST = 'UPDATE_PRICINGS_REQUEST',
  UPDATE_PRICINGS_SUCCESS = 'UPDATE_PRICINGS_SUCCESS',
  UPDATE_PRICINGS_FAILURE = 'UPDATE_PRICINGS_FAILURE',
  CREATE_CURRENCIES_REQUEST = 'CREATE_CURRENCIES_REQUEST',
  CREATE_CURRENCIES_SUCCESS = 'CREATE_CURRENCIES_SUCCESS',
  CREATE_CURRENCIES_FAILURE = 'CREATE_CURRENCIES_FAILURE',
  GET_CONTROL_DETAIL_REQUEST = 'GET_CONTROL_DETAIL_REQUEST',
  GET_CONTROL_DETAIL_SUCCESS = 'GET_CONTROL_DETAIL_SUCCESS',
  GET_CONTROL_DETAIL_FAILURE = 'GET_CONTROL_DETAIL_FAILURE',
  CREATE_NEW_CONTROL_REQUEST = 'CREATE_NEW_CONTROL_REQUEST',
  CREATE_NEW_CONTROL_SUCCESS = 'CREATE_NEW_CONTROL_SUCCESS',
  CREATE_NEW_CONTROL_FAILURE = 'CREATE_NEW_CONTROL_FAILURE',
  EDIT_CONTROL_STATUS_REQUEST = 'EDIT_CONTROL_STATUS_REQUEST',
  EDIT_CONTROL_STATUS_SUCCESS = 'EDIT_CONTROL_STATUS_SUCCESS',
  EDIT_CONTROL_STATUS_FAILURE = 'EDIT_CONTROL_STATUS_FAILURE',
  EDIT_CONTROL_REGISTRATION_REQUEST = 'EDIT_CONTROL_REGISTRATION_REQUEST',
  EDIT_CONTROL_REGISTRATION_SUCCESS = 'EDIT_CONTROL_REGISTRATION_SUCCESS',
  EDIT_CONTROL_REGISTRATION_FAILURE = 'EDIT_CONTROL_REGISTRATION_FAILURE',  
}

export type ControlEditBody = {
  vulnerabilities_add: ObjectWithId[],
  vulnerabilities_remove: ObjectWithId[],
  risk_factors_add: ObjectWithId[],
  risk_factors_remove: ObjectWithId[],
  threats_add: ObjectWithId[],
  threats_remove: ObjectWithId[]
}

export type ControlInfoUpdateStatus = {
  controlId: string;
  controlStatus: boolean
}

export type SubControlsWithControl = {
  benefit: number;
  cost: number;
  cost_benefit?: number;
  final_benefit?: number;
  id?: string | number;
  name: string;
  technical_capacity: number;
  control?: string;
}

export type ControlEditRequestBody = {
  friendly_id: string,
  name: string,
  class_name: number,
  group_name: number,
  type_name: number,
  category: number,
  control_type: string,
  confidentiality: boolean,
  integrity: boolean,
  availability: boolean,
  identify: boolean,
  protect: boolean,
  detect: boolean,
  respond: boolean,
  recover: boolean,
  attack_vector: string[] | undefined,
  attack_complexity: string[] | undefined,
  privileges_required: string[] | undefined,
  user_interaction: string[] | undefined,
  scope: string[] | undefined,
  confidentiality_level: string[] | undefined,
  integrity_level: string[] | undefined,
  availability_level: string[] | undefined,
  cost: number | null,
  technical_capacity: number | null,
  benefit: number | null,
  cost_benefit: number | null,
  final_benefit: number | null,
  subcontrols_add: SubControlsWithControl[],
  subcontrols_update?: SubControlsWithControl[],
  subcontrols_delete: ObjectWithId[],
  is_active: true
}

export type ControlEditBodyResponse = {
  id: string;
  vulnerabilities_add: ObjectWithId[],
  vulnerabilities_remove: ObjectWithId[],
  risk_factors_add: ObjectWithId[],
  risk_factors_remove: ObjectWithId[],
  threats_add: ObjectWithId[],
  threats_remove: ObjectWithId[]
}

export type apiControlRequestActionPayload = {
  controlId: string;
}

export type apiEditControlRegistrationsRequestActionPayload = {
  controlId: string;
  controlEditBody: ControlEditRequestBody;
  controlEditRegistrationBody: ControlEditBody;
}

export type apiCreateNewControlRequestActionPayload = {
  controlBody: ControlBody;
}

export type ControlRiskFactor = {
  id: string;
  name: string;
}

export type ControlThreats = {
  id: string;
  name: string;
  description: string;
}

export type ControlVulnerabilities = {
  id: string;
  name: string;
  vulnerability_type: string;
  cves: []
}

export type Pricing = {
  id: string;
  auto_increment_id: number;
  created_at: string;
  updated_at: string;
  is_active: boolean,
  start_range: number,
  end_range: number,
  unit_price: number;
  sizing_type: string;
  control: string | null;
  subcontrol: string | null;
  currency: string;
}

export type PricingControl = {
  id: string;
  sizing_type: SizingTypePricingControl;
  start_range: number;
  end_range: number;
  unit_price: number;
  currency: CurrencyPricingControl;
}

export type SizingTypePricingControl = {
  id: string;
  name: string;
}

export type CurrencyPricingControl = {
  id: string;
  name: string;
  symbol: string;
}

export type Product = {
  id: string;
  auto_increment_id: number;
  created_at: string;
  updated_at: string;
  is_active: boolean;
  name: string;
  default_control: boolean;
  control: string | null;
  subcontrol: string | null;
}

export type ProductControl = {
  id: string;
  name: string;
  default_control: boolean;
  is_active: boolean;
  time_to_deploy: number;
  pricings: PricingControl[];
}

export type SubControls = {
  id?: string | number;
  name: string;
  benefit: number;
  cost: number;
  cost_benefit?: number;
  final_benefit?: number;  
  technical_capacity: number;
  products?: ProductControl[];
}

export type ControlBody = { 
  name: string;
  class_name: string;
  group_name: string;
  type_name: string;
  category: string;
  control_type: string;
  confidentiality: boolean;
  integrity: boolean;
  availability: boolean;
  identify: boolean;
  protect: boolean;
  detect: boolean;
  respond: boolean;
  recover: boolean;
  attack_vector: string[] | undefined;
  attack_complexity: string[] | undefined;
  privileges_required: string[] | undefined;
  user_interaction: string[] | undefined;
  scope: string[] | undefined;
  confidentiality_level: string[] | undefined;
  integrity_level: string[] | undefined;
  availability_level: string[] | undefined;
  cost: number | null;
  subcontrols: SubControls[];
  technical_capacity: number | null;
  benefit: number | null;
  is_active: boolean
}

export type IdAndName = {
  id: string;
  name: string;
}

export type CategoriesType = {
  id: string;
  name: string;
  description: string;
  importance: number;
}

export type ControlDetailResults = {
  results: Control[] | SimpleControl[];
  count: number;
  next: boolean;
}

export type ControlDetail = {
  id: string;
  friendly_id: string;
  name: string;
  class_name: IdAndName;
  group_name: IdAndName;
  type_name: IdAndName;
  category: CategoriesType;
  control_type: IdAndName;
  confidentiality: boolean;
  integrity: boolean;
  availability: boolean;
  identify: boolean;
  protect: boolean;
  detect: boolean;
  respond: boolean;
  recover: boolean;
  attack_vector: string[];
  attack_complexity: string[];
  privileges_required: string[];
  user_interaction: string[];
  scope: string[];
  confidentiality_level: string[];
  integrity_level: string[];  
  availability_level: string[];
  cost: number;
  technical_capacity: number;
  benefit: number;  
  cost_benefit: number;  
  final_benefit: number;    
  is_active: boolean;
  subcontrols: SubControls[];
  vulnerabilities: ControlVulnerabilities[];  
  risk_factors: ControlRiskFactor[];  
  threats: ControlThreats[];  
  products: ProductControl[];
}

export type ControlDetailWithOptions = {
  attack_complexity?: string[] ;
  attack_vector?: string[] ;
  availability?: boolean;
  availability_level?: string[] ;
  benefit?: number;
  category?: CategoriesType;
  class_name?: IdAndName;
  confidentiality?: boolean;
  confidentiality_level?: string[] ;
  control_type?: IdAndName;
  cost?: number;
  cost_benefit?: number;
  detect?: boolean;
  final_benefit?: number;
  friendly_id?: string;
  group_name?: IdAndName;
  id: string;
  identify?: boolean;
  integrity?: boolean;
  integrity_level?: string[] ;
  is_active?: boolean;
  name: string;
  privileges_required?: string[] ;
  protect?: boolean;
  recover?: boolean;
  respond?: boolean;
  risk_factors?: ControlRiskFactor[];
  scope?: string[] ;
  subcontrols?: SubControls[];
  technical_capacity?: number;
  threats?: ControlThreats[];
  type_name?: IdAndName;
  user_interaction?: string[] ;
  vulnerabilities?: ControlVulnerabilities[];
}

export type Currencies = {
  id: string;
  name: string;
  symbol: string;
}

export type AllCurrencies = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Currencies[];
}

export type ControlState = {
  controlDetail: ControlDetail;
  allCurrencies: AllCurrencies;
}

export type pricingsPayload = {  
  controlId: string;
  sizing_type: string;
  product: string;
  currency: string;
  start_range: number;
  end_range: number;
  unit_price: number;
}

export type SizingTypesControler = {
  id: string;
  name: string;
};

export type PutPricingsPayload = {
  id: string;
  controlId: string;
  sizing_type: string;
  currency: string;
  start_range: number;
  end_range: number;
  unit_price: number;
}

export type currencyPayload = {
  name: string;
  symbol: string;
}

export type ProductPayload = {
  controlId: string;
  name: string;
  control: string;
  subcontrol: string;
  default_control: boolean;
  type_control: string;
  time_to_deploy: number;
}

export type PutProductPayload = {
  id: string;  
  name: string;
  is_active: boolean;
  default_control: boolean;
  controlId: string;
  time_to_deploy: number;
}