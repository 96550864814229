import { IdAndName } from '../Manutention/vulnerabilities/types';
import {
  ObjectWithId,
  ControlOfVulnerability,
  riskFactor,
  ThreatsArrayNameId,
  ThreatDetailsDamages,
  risk,
  Threat
} from '../threats/types';

export enum CustomerProjectActions {
  GET_ALL_CUSTOMER_PROJECT_REQUEST = 'GET_ALL_CUSTOMER_PROJECT_REQUEST',
  GET_ALL_CUSTOMER_PROJECT_SUCCESS = 'GET_ALL_CUSTOMER_PROJECT_SUCCESS',
  GET_ALL_CUSTOMER_PROJECT_FAILURE = 'GET_ALL_CUSTOMER_PROJECT_FAILURE',
  CREATE_NEW_CUSTOMER_PROJECT_REQUEST = 'CREATE_NEW_CUSTOMER_PROJECT_REQUEST',
  CREATE_NEW_CUSTOMER_PROJECT_SUCCESS = 'CREATE_NEW_CUSTOMER_PROJECT_SUCCESS',
  CREATE_NEW_CUSTOMER_PROJECT_FAILURE = 'CREATE_NEW_CUSTOMER_PROJECT_FAILURE',
  GET_ALL_ANALYSTS_REQUEST = 'GET_ALL_ANALYSTS_REQUEST',
  GET_ALL_ANALYSTS_SUCCESS = 'GET_ALL_ANALYSTS_SUCCESS',
  GET_ALL_ANALYSTS_FAILURE = 'GET_ALL_ANALYSTS_FAILURE',
  GET_ALL_CONTROL_CUSTOMERS_REQUEST = 'GET_ALL_CONTROL_CUSTOMERS_REQUEST',
  GET_ALL_CONTROL_CUSTOMERS_SUCCESS = 'GET_ALL_CONTROL_CUSTOMERS_SUCCESS',
  GET_ALL_CONTROL_CUSTOMERS_FAILURE = 'GET_ALL_CONTROL_CUSTOMERS_FAILURE',
  GET_ALL_CONTROL_PROJECT_TYPE_REQUEST = 'GET_ALL_CONTROL_PROJECT_TYPE_REQUEST',
  GET_ALL_CONTROL_PROJECT_TYPE_SUCCESS = 'GET_ALL_CONTROL_PROJECT_TYPE_SUCCESS',
  GET_ALL_CONTROL_PROJECT_TYPE_FAILURE = 'GET_ALL_CONTROL_PROJECT_TYPE_FAILURE',
  EDIT_CUSTOMER_PROJECT_REQUEST = 'EDIT_CUSTOMER_PROJECT_REQUEST',
  EDIT_CUSTOMER_PROJECT_SUCCESS = 'EDIT_CUSTOMER_PROJECT_SUCCESS',
  EDIT_CUSTOMER_PROJECT_FAILURE = 'EDIT_CUSTOMER_PROJECT_FAILURE',
  GET_CUSTOMER_PROJECT_DETAIL_REQUEST = ' GET_CUSTOMER_PROJECT_DETAIL_REQUEST',
  GET_CUSTOMER_PROJECT_DETAIL_SUCCESS = 'GET_CUSTOMER_PROJECT_DETAIL_SUCCESS',
  GET_CUSTOMER_PROJECT_DETAIL_FAILURE = 'GET_CUSTOMER_PROJECT_DETAIL_FAILURE',
  GET_PROJECT_CUSTOMER_DETAIL_REQUEST = ' GET_PROJECT_CUSTOMER_DETAIL_REQUEST',
  GET_PROJECT_CUSTOMER_DETAIL_SUCCESS = 'GET_PROJECT_CUSTOMER_DETAIL_SUCCESS',
  GET_PROJECT_CUSTOMER_DETAIL_FAILURE = 'GET_PROJECT_CUSTOMER_DETAIL_FAILURE',
  EDIT_QUESTIONNAIRES_INTERWEES_REQUEST = 'EDIT_QUESTIONNAIRES_INTERWEES_REQUEST',
  EDIT_QUESTIONNAIRES_INTERWEES_SUCCESS = 'EDIT_QUESTIONNAIRES_INTERWEES_SUCCESS',
  EDIT_QUESTIONNAIRES_INTERWEES_FAILURE = 'EDIT_QUESTIONNAIRES_INTERWEES_FAILURE',
  ANALYST_ANSWERING_QUESTION_REQUEST = ' ANALYST_ANSWERING_QUESTION_REQUEST',
  ANALYST_ANSWERING_QUESTION_SUCCESS = 'ANALYST_ANSWERING_QUESTION_SUCCESS',
  ANALYST_ANSWERING_QUESTION_FAILURE = 'ANALYST_ANSWERING_QUESTION_FAILURE',
  GET_CUSTOMER_QUESTIONNAIRE_ANSWER_DETAIL_REQUEST = 'GET_CUSTOMER_QUESTIONNAIRE_ANSWER_DETAIL_REQUEST',
  GET_CUSTOMER_QUESTIONNAIRE_ANSWER_DETAIL_SUCCESS = 'GET_CUSTOMER_QUESTIONNAIRE_ANSWER_DETAIL_SUCCESS',
  GET_CUSTOMER_QUESTIONNAIRE_ANSWER_DETAIL_FAILURE = 'GET_CUSTOMER_QUESTIONNAIRE_ANSWER_DETAIL_FAILURE',
  DELETE_CUSTOMER_PROJECT_QUESTIONNAIRE_REQUEST = ' DELETE_CUSTOMER_PROJECT_QUESTIONNAIRE_REQUEST',
  DELETE_CUSTOMER_PROJECT_QUESTIONNAIRE_SUCCESS = 'DELETE_CUSTOMER_PROJECT_QUESTIONNAIRE_SUCCESS',
  DELETE_CUSTOMER_PROJECT_QUESTIONNAIRE_FAILURE = 'DELETE_CUSTOMER_PROJECT_QUESTIONNAIRE_FAILURE',
  GET_QUESTIONNAIRE_ANSWER_DETAIL_REQUEST = ' GET_QUESTIONNAIRE_ANSWER_DETAIL_REQUEST',
  GET_QUESTIONNAIRE_ANSWER_DETAIL_SUCCESS = 'GET_QUESTIONNAIRE_ANSWER_DETAIL_SUCCESS',
  GET_QUESTIONNAIRE_ANSWER_DETAIL_FAILURE = 'GET_QUESTIONNAIRE_ANSWER_DETAIL_FAILURE',
  CHECKLIST_ACTIVITY_ANSWER_REQUEST = ' CHECKLIST_ACTIVITY_ANSWER_REQUEST',
  CHECKLIST_ACTIVITY_ANSWER_SUCCESS = 'CHECKLIST_ACTIVITY_ANSWER_SUCCESS',
  CHECKLIST_ACTIVITY_ANSWER_FAILURE = 'CHECKLIST_ACTIVITY_ANSWER_FAILURE',
  CHECKLIST_ACTIVITY_EVIDENCES_REQUEST = ' CHECKLIST_ACTIVITY_EVIDENCES_REQUEST',
  CHECKLIST_ACTIVITY_EVIDENCES_SUCCESS = 'CHECKLIST_ACTIVITY_EVIDENCES_SUCCESS',
  CHECKLIST_ACTIVITY_EVIDENCES_FAILURE = 'CHECKLIST_ACTIVITY_EVIDENCES_FAILURE',
  EDIT_ACTIVITY_REGISTRATIONS_REQUEST = ' EDIT_ACTIVITY_REGISTRATIONS_REQUEST',
  EDIT_ACTIVITY_REGISTRATIONS_SUCCESS = 'EDIT_ACTIVITY_REGISTRATIONS_SUCCESS',
  EDIT_ACTIVITY_REGISTRATIONS_FAILURE = 'EDIT_ACTIVITY_REGISTRATIONS_FAILURE',
  CHECKLIST_QUESTION_ANSWER_REQUEST = ' CHECKLIST_QUESTION_ANSWER_REQUEST',
  CHECKLIST_QUESTION_ANSWER_SUCCESS = 'CHECKLIST_QUESTION_ANSWER_SUCCESS',
  CHECKLIST_QUESTION_ANSWER_FAILURE = 'CHECKLIST_QUESTION_ANSWER_FAILURE',
  GET_ACTIVITY_DETAILS_REQUEST = ' GET_ACTIVITY_DETAILS_REQUEST',
  GET_ACTIVITY_DETAILS_SUCCESS = 'GET_ACTIVITY_DETAILS_SUCCESS',
  GET_ACTIVITY_DETAILS_FAILURE = 'GET_ACTIVITY_DETAILS_FAILURE',
  GET_QUESTION_DETAILS_REQUEST = 'GET_QUESTION_DETAILS_REQUEST',
  GET_QUESTION_DETAILS_SUCCESS = 'GET_QUESTION_DETAILS_SUCCESS',
  GET_QUESTION_DETAILS_FAILURE = 'GET_QUESTION_DETAILS_FAILURE',
  GET_CUSTOMER_PROJECT_CHECKLISTS_REQUEST = 'GET_CUSTOMER_PROJECT_CHECKLISTS_REQUEST',
  GET_CUSTOMER_PROJECT_CHECKLISTS_SUCCESS = 'GET_CUSTOMER_PROJECT_CHECKLISTS_SUCCESS',
  GET_CUSTOMER_PROJECT_CHECKLISTS_FAILURE = 'GET_CUSTOMER_PROJECT_CHECKLISTS_FAILURE',
  EDIT_ACTIVITY_ANSWER_REQUEST = 'EDIT_ACTIVITY_ANSWER_REQUEST',
  EDIT_ACTIVITY_ANSWER_SUCCESS = 'EDIT_ACTIVITY_ANSWER_SUCCESS',
  EDIT_ACTIVITY_ANSWER_FAILURE = 'EDIT_ACTIVITY_ANSWER_FAILURE',
  EDIT_ACTIVITY_ANSWER_EVIDENCES_REQUEST = 'EDIT_ACTIVITY_ANSWER_EVIDENCES_REQUEST',
  EDIT_ACTIVITY_ANSWER_EVIDENCES_SUCCESS = 'EDIT_ACTIVITY_ANSWER_EVIDENCES_SUCCESS',
  EDIT_ACTIVITY_ANSWER_EVIDENCES_FAILURE = 'EDIT_ACTIVITY_ANSWER_EVIDENCES_FAILURE',
  EDIT_QUESTION_ANSWER_EVIDENCES_REQUEST = 'EDIT_QUESTION_ANSWER_EVIDENCES_REQUEST',
  EDIT_QUESTION_ANSWER_EVIDENCES_SUCCESS = 'EDIT_QUESTION_ANSWER_EVIDENCES_SUCCESS',
  EDIT_QUESTION_ANSWER_EVIDENCES_FAILURE = 'EDIT_QUESTION_ANSWER_EVIDENCES_FAILURE',
  GET_CUSTOMER_PROJECT_THREATS_REQUEST = 'GET_CUSTOMER_PROJECT_THREATS_REQUEST',
  GET_CUSTOMER_PROJECT_THREATS_SUCCESS = 'GET_CUSTOMER_PROJECT_THREATS_SUCCESS',
  GET_CUSTOMER_PROJECT_THREATS_FAILURE = 'GET_CUSTOMER_PROJECT_THREATS_FAILURE',
  GET_CUSTOMER_PROJECT_SCORE_CARD_REQUEST = 'GET_CUSTOMER_PROJECT_SCORE_CARD_REQUEST',
  GET_CUSTOMER_PROJECT_SCORE_CARD_SUCCESS = 'GET_CUSTOMER_PROJECT_SCORE_CARD_SUCCESS',
  GET_CUSTOMER_PROJECT_SCORE_CARD_FAILURE = 'GET_CUSTOMER_PROJECT_SCORE_CARD_FAILURE',
  DELETE_CUSTOMER_PROJECT_CROWN_JEWEL_REQUEST = 'DELETE_CUSTOMER_PROJECT_CROWN_JEWEL_REQUEST',
  DELETE_CUSTOMER_PROJECT_CROWN_JEWEL_SUCCESS = 'DELETE_CUSTOMER_PROJECT_CROWN_JEWEL_SUCCESS',
  DELETE_CUSTOMER_PROJECT_CROWN_JEWEL_FAILURE = 'DELETE_CUSTOMER_PROJECT_CROWN_JEWEL_FAILURE',
  EDIT_CUSTOMER_PROJECT_CSF_CORE_REQUEST = 'EDIT_CUSTOMER_PROJECT_CSF_CORE_REQUEST',
  EDIT_CUSTOMER_PROJECT_CSF_CORE_SUCCESS = 'EDIT_CUSTOMER_PROJECT_CSF_CORE_SUCCESS',
  EDIT_CUSTOMER_PROJECT_CSF_CORE_FAILURE = 'EDIT_CUSTOMER_PROJECT_CSF_CORE_FAILURE',
  EDIT_CUSTOMER_PROJECT_CROWN_JEWEL_REQUEST = 'EDIT_CUSTOMER_PROJECT_CROWN_JEWEL_REQUEST',
  EDIT_CUSTOMER_PROJECT_CROWN_JEWEL_SUCCESS = 'EDIT_CUSTOMER_PROJECT_CROWN_JEWEL_SUCCESS',
  EDIT_CUSTOMER_PROJECT_CROWN_JEWEL_FAILURE = 'EDIT_CUSTOMER_PROJECT_CROWN_JEWEL_FAILURE',
  GET_THREATS_CUSTOMER_PROJECT_DETAIL_REQUEST = 'GET_THREATS_CUSTOMER_PROJECT_DETAIL_REQUEST',
  GET_THREATS_CUSTOMER_PROJECT_DETAIL_SUCCESS = 'GET_THREATS_CUSTOMER_PROJECT_DETAIL_SUCCESS',
  GET_THREATS_CUSTOMER_PROJECT_DETAIL_FAILURE = 'GET_THREATS_CUSTOMER_PROJECT_DETAIL_FAILURE',
  GET_ALL_REPORTS_TEXTS_CUSTOMER_PROJECT_REQUEST = 'GET_ALL_REPORTS_TEXTS_CUSTOMER_PROJECT_REQUEST',
  GET_ALL_REPORTS_TEXTS_CUSTOMER_PROJECT_SUCCESS = 'GET_ALL_REPORTS_TEXTS_CUSTOMER_PROJECT_SUCCESS',
  GET_ALL_REPORTS_TEXTS_CUSTOMER_PROJECT_FAILURE = 'GET_ALL_REPORTS_TEXTS_CUSTOMER_PROJECT_FAILURE',
  EDIT_QUESTIONNAIRE_ANSWERS_REQUEST = 'EDIT_QUESTIONNAIRE_ANSWERS_REQUEST',
  EDIT_QUESTIONNAIRE_ANSWERS_SUCCESS = 'EDIT_QUESTIONNAIRE_ANSWERS_SUCCESS',
  EDIT_QUESTIONNAIRE_ANSWERS_FAILURE = 'EDIT_QUESTIONNAIRE_ANSWERS_FAILURE',
  SEND_ORGANOGRAM_REQUEST = 'SEND_ORGANOGRAM_REQUEST',
  SEND_ORGANOGRAM_SUCCESS = 'SEND_ORGANOGRAM_SUCCESS',
  SEND_ORGANOGRAM_FAILURE = 'SEND_ORGANOGRAM_FAILURE',
  GET_ORGANOGRAM_CUSTOMER_PROJECT_REQUEST = 'GET_ORGANOGRAM_CUSTOMER_PROJECT_REQUEST',
  GET_ORGANOGRAM_CUSTOMER_PROJECT_SUCCESS = 'GET_ORGANOGRAM_CUSTOMER_PROJECT_SUCCESS',
  GET_ORGANOGRAM_CUSTOMER_PROJECT_FAILURE = 'GET_ORGANOGRAM_CUSTOMER_PROJECT_FAILURE',
  SEND_QUESTIONNAIRE_EVIDENCES_REQUEST = 'SEND_QUESTIONNAIRE_EVIDENCES_REQUEST',
  SEND_QUESTIONNAIRE_EVIDENCES_SUCCESS = 'SEND_QUESTIONNAIRE_EVIDENCES_SUCCESS',
  SEND_QUESTIONNAIRE_EVIDENCES_FAILURE = 'SEND_QUESTIONNAIRE_EVIDENCES_FAILURE',
  REMOVE_QUESTIONNAIRE_EVIDENCES_REQUEST = 'REMOVE_QUESTIONNAIRE_EVIDENCES_REQUEST',
  REMOVE_QUESTIONNAIRE_EVIDENCES_SUCCESS = 'REMOVE_QUESTIONNAIRE_EVIDENCES_SUCCESS',
  REMOVE_QUESTIONNAIRE_EVIDENCES_FAILURE = 'REMOVE_QUESTIONNAIRE_EVIDENCES_FAILURE',
  GET_ALL_REPORTS_PROJECT_CUSTOMER_PROJECT_REQUEST = 'GET_ALL_REPORTS_PROJECT_CUSTOMER_PROJECT_REQUEST',
  GET_ALL_REPORTS_PROJECT_CUSTOMER_PROJECT_SUCCESS = 'GET_ALL_REPORTS_PROJECT_CUSTOMER_PROJECT_SUCCESS',
  GET_ALL_REPORTS_PROJECT_CUSTOMER_PROJECT_FAILURE = 'GET_ALL_REPORTS_PROJECT_CUSTOMER_PROJECT_FAILURE',
  EDIT_APROVE_PDF_REQUEST = 'EDIT_APROVE_PDF_REQUEST',
  EDIT_APROVE_PDF_SUCCESS = 'EDIT_APROVE_PDF_SUCCESS',
  EDIT_APROVE_PDF_FAILURE = 'EDIT_APROVE_PDF_FAILURE',
  SEND_INTERVIEW_EMAIL_REQUEST = 'SEND_INTERVIEW_EMAIL_REQUEST',
  SEND_INTERVIEW_EMAIL_SUCCESS = 'SEND_INTERVIEW_EMAIL_SUCCESS',
  SEND_INTERVIEW_EMAIL_FAILURE = 'SEND_INTERVIEW_EMAIL_FAILURE',
  EDIT_ACTIVITY_REGISTRATIONS_REQUEST_DETAIL = ' EDIT_ACTIVITY_REGISTRATIONS_REQUEST_DETAIL',
  EDIT_ACTIVITY_REGISTRATIONS_REQUEST_DETAIL_TWO = 'EDIT_ACTIVITY_REGISTRATIONS_REQUEST_DETAIL_TWO',
  EDIT_CUSTOMER_PROJECT_STATUS_REQUEST = 'EDIT_CUSTOMER_PROJECT_STATUS_REQUEST',
  EDIT_CUSTOMER_PROJECT_STATUS_SUCCESS = 'EDIT_CUSTOMER_PROJECT_STATUS_SUCCESS',
  EDIT_CUSTOMER_PROJECT_STATUS_FAILURE = 'EDIT_CUSTOMER_PROJECT_STATUS_FAILURE',
  GET_FILTERED_CUSTOMER_PROJECT_REQUEST = 'GET_FILTERED_CUSTOMER_PROJECT_REQUEST',
  GET_FILTERED_CUSTOMER_PROJECT_SUCCESS = 'GET_FILTERED_CUSTOMER_PROJECT_SUCCESS',
  GET_FILTERED_CUSTOMER_PROJECT_FAILURE = 'GET_FILTERED_CUSTOMER_PROJECT_FAILURE',
  GET_CUSTOMER_PROJECT_QUESTIONNAIRES_REQUEST = 'GET_CUSTOMER_PROJECT_QUESTIONNAIRES_REQUEST',
  GET_CUSTOMER_PROJECT_QUESTIONNAIRES_SUCCESS = 'GET_CUSTOMER_PROJECT_QUESTIONNAIRES_SUCCESS',
  GET_CUSTOMER_PROJECT_QUESTIONNAIRES_FAILURE = 'GET_CUSTOMER_PROJECT_QUESTIONNAIRES_FAILURE',
  NEW_GET_CUSTOMER_PROJECT_QUESTIONNAIRES_REQUEST = 'NEW_GET_CUSTOMER_PROJECT_QUESTIONNAIRES_REQUEST',
  NEW_GET_CUSTOMER_PROJECT_QUESTIONNAIRES_SUCCESS = 'NEW_GET_CUSTOMER_PROJECT_QUESTIONNAIRES_SUCCESS',
  NEW_GET_CUSTOMER_PROJECT_QUESTIONNAIRES_FAILURE = 'NEW_GET_CUSTOMER_PROJECT_QUESTIONNAIRES_FAILURE',
  GET_CUSTOMER_PROJECT_QUESTIONNAIRES_DETAIL_REQUEST = 'GET_CUSTOMER_PROJECT_QUESTIONNAIRES_DETAIL_REQUEST',
  GET_CUSTOMER_PROJECT_QUESTIONNAIRES_DETAIL_SUCCESS = 'GET_CUSTOMER_PROJECT_QUESTIONNAIRES_DETAIL_SUCCESS',
  GET_CUSTOMER_PROJECT_QUESTIONNAIRES_DETAIL_FAILURE = 'GET_CUSTOMER_PROJECT_QUESTIONNAIRES_DETAIL_FAILURE',
  PUT_PROJECT_STATUS_CHANGE_REQUEST = 'PUT_PROJECT_STATUS_CHANGE_REQUEST',
  PUT_PROJECT_STATUS_CHANGE_SUCCESS = 'PUT_PROJECT_STATUS_CHANGE_SUCCESS',
  PUT_PROJECT_STATUS_CHANGE_FAILURE = 'PUT_PROJECT_STATUS_CHANGE_FAILURE',
  GET_SCORE_CARD_CHART_REQUEST = 'GET_SCORE_CARD_CHART_REQUEST',
  GET_SCORE_CARD_CHART_SUCCESS = 'GET_SCORE_CARD_CHART_SUCCESS',
  GET_SCORE_CARD_CHART_FAILURE = 'GET_SCORE_CARD_CHART_FAILURE',
  GET_RISK_DONUT_CHART_REQUEST = 'GET_RISK_DONUT_CHART_REQUEST',
  GET_RISK_DONUT_CHART_SUCCESS = 'GET_RISK_DONUT_CHART_SUCCESS',
  GET_RISK_DONUT_CHART_FAILURE = 'GET_RISK_DONUT_CHART_FAILURE',
  GET_RISK_HEAT_MAP_REQUEST = 'GET_RISK_HEAT_MAP_REQUEST',
  GET_RISK_HEAT_MAP_SUCCESS = 'GET_RISK_HEAT_MAP_SUCCESS',
  GET_RISK_HEAT_MAP_FAILURE = 'GET_RISK_HEAT_MAP_FAILURE',
  UPDATE_PROJECT_EXPIRATION_DATE_REQUEST = 'UPDATE_PROJECT_EXPIRATION_DATE_REQUEST',
  UPDATE_PROJECT_EXPIRATION_DATE_SUCCESS = 'UPDATE_PROJECT_EXPIRATION_DATE_SUCCESS',
  UPDATE_PROJECT_EXPIRATION_DATE_FAILURE = 'UPDATE_PROJECT_EXPIRATION_DATE_FAILURE',
  GET_ALL_CUSTOMER_PROJECT_CUSTOMER_REQUEST = 'GET_ALL_CUSTOMER_PROJECT_CUSTOMER_REQUEST',
  GET_ALL_CUSTOMER_PROJECT_CUSTOMER_SUCCESS = 'GET_ALL_CUSTOMER_PROJECT_CUSTOMER_SUCCESS',
  GET_ALL_CUSTOMER_PROJECT_CUSTOMER_FAILURE = 'GET_ALL_CUSTOMER_PROJECT_CUSTOMER_FAILURE',
  GET_ALL_ENV_ASSESSMENT_CUSTOMERPROJECT_REQUEST = 'GET_ALL_ENV_ASSESSMENT_CUSTOMERPROJECT_REQUEST',
  GET_ALL_ENV_ASSESSMENT_CUSTOMERPROJECT_SUCCESS = 'GET_ALL_ENV_ASSESSMENT_CUSTOMERPROJECT_SUCCESS',
  GET_ALL_ENV_ASSESSMENT_CUSTOMERPROJECT_FAILURE = 'GET_ALL_ENV_ASSESSMENT_CUSTOMERPROJECT_FAILURE',
  PUT_IDENTIFIED_QUANTITY_REQUEST = 'PUT_IDENTIFIED_QUANTITY_REQUEST',
  PUT_IDENTIFIED_QUANTITY_SUCCESS = 'PUT_IDENTIFIED_QUANTITY_SUCCESS',
  PUT_IDENTIFIED_QUANTITY_FAILURE = 'PUT_IDENTIFIED_QUANTITY_FAILURE',
  PUT_AUDITED_INTERWEES_REQUEST = 'PUT_AUDITED_INTERWEES_REQUEST',
  PUT_AUDITED_INTERWEES_SUCCESS = 'PUT_AUDITED_INTERWEES_SUCCESS',
  PUT_AUDITED_INTERWEES_FAILURE = 'PUT_AUDITED_INTERWEES_FAILURE',
}

export type AnswersEvidencesToRemove = {
  answers: EvidencesToRemove[],
  pLocal?: LocalType
}

export type EvidencesToRemove = {
  id: string,
  evidences_remove: ObjectWithId[]
}

export type EvidencesToRemoveActionPayload = {
  answerEvidencesToRemove: AnswersEvidencesToRemove;
};

export type EvidencesIdsBody = {
  [x: string]: any[];
};

export type LocalType = {
  projectId: string;
  questionnaireId: string | undefined;
  projectName: string | undefined;
  disabledChange?: string;
  whereDidItComeFrom: string | undefined;
  redirectToAnotherPage: boolean;
}

export type AnswersQuestionnaireEvidences = {
  evidencesIdsBody: EvidencesIdsBody[];
  pLocal?: LocalType;
}

export type EditQuestionnaireAnswer = {
  answer_id: string,
  answer: string,
  comment: string,
  customer_project: string,
  questionnaire: string,
  question: string,
  alternative: string,
  question_customer_project: string,
  alternative_customer_project: string,
  user: string,
  evidences: any[]
}

export type EditQuestionnaireAnswerBody = {
  answers: EditQuestionnaireAnswer[];
  questions_create: AnalystQuestionCreate[];
  customerProjectId: string;
  evidencesToRemove: EvidencesToRemove[];
  questionnaireId: string;
  projectName?: string;
  disabledChange: string;
  handleGetAnswer: (pAnswer: Answer) => void;
}

export type ReportsText = {
  final_considerations: string,
  id: string,
  introduction: string,
  name: string,
  recommendation: string,
  the_organization: string,
  project_scope: string,
}

export type CSFcoreMaturityEditBodyType = {
  id: string,
  maturity_level: string
}

export type CSFcoreEditBody = {
  csf_core_project: CSFcoreMaturityEditBodyType[];
}

export type QuestionnaireInterviweesPayload = {
  questionnaireId: string;
  customerProjectId: string;
  questionnaireInterwees: QuestionnaireInterviwees;
}

export type QuestionnaireInterviwees = {
  interviewees: ObjectWithId[];
}

export type Analyst = {
  corporate_name_or_name: string
  id: string;
}

export type CustomerProjectWithAddAndRemove = {
  name: string,
  project_type: string,
  customer: string,
  responsible: string,
  analysts_add: ObjectWithId[],
  analysts_remove: ObjectWithId[],
  questionnaires_add: ObjectWithId[],
  questionnaires_remove: ObjectWithId[],
  checklists_add: ObjectWithId[],
  checklists_remove: ObjectWithId[],
  activities_add: ObjectWithId[],
  activities_remove: ObjectWithId[],
  config_crown_jewels_add: ObjectWithId[],
  config_crown_jewels_remove: ObjectWithId[],
  customers_users_add: ObjectWithId[],
  customers_users_remove: ObjectWithId[],
}

export type EditCustomerProjectRequestPayload = {
  editCustomerProject: CustomerProjectWithAddAndRemove;
  customerProjectId: string;
  shouldRedirect?: boolean;
  ProjectTypeId?: string;
  isCheckListsOrActivity?: boolean;
};

export type ProjectType = {
  id: string;
  project_name: string;
  questionnaires: string[];
  checklists: string[];
  activities: string[];
  crown_jewels: string[];
  is_active: boolean;
}

export type QuestionnaireRow = {
  departments: [];
  description: string;
  id: string;
  is_active: boolean;
  name: string;
  questions: [];
  type_of_questionnaire: string;
}

export type ControlsProjectTypeResults = {
  results: ProjectType[];
}

export type ControlsCustomerResults = {
  results: Customer[];
}

export type ControlsAnalystsResults = {
  results: Analysts[];
}

export type Customer = {
  id: string,
  email: string,
  document_type: string,
  document_number: string,
  corporate_name_or_name: string,
  fantasy_name: string,
  revenue: number,
  it_budget: number,
  phone: string,
  state_registration: string,
  municipal_registration: string,
  opting_for_the_simples: boolean,
  inclusion_date_rf?: Date,
  status_rf: string,
  parent_company_subsidiary: string,
  is_active: boolean
}

export type Analysts = {
  id: string,
  email: string,
  document_type: string,
  document_number: string,
  corporate_name_or_name: string,
  fantasy_name: string,
  profile: string[],
  position: string,
  phone: string,
  status: boolean,
  is_customer: boolean,
}

export type ListingAnalysts = {
  id: string,
  corporate_name_or_name: string,
}

export type CustomerProjectResults = {
  results: CustomerProject[];
}

export type CustomerProject = {
  id: string;
  name: string;
  project_type: string;
  customer: string;
  analysts: Analyst[]
}

export type CustomerProjectInterwiies = {
  id: string,
  name: string,
  email: string,
  position: string
}

export type CustomerProjectCustomerDetail = {
  id: string,
  corporate_name_or_name: string,
  revenue: number,
  it_budget: number,
  company_size: string,
  interviewees: CustomerProjectInterwiies[]
}

export type CVEOfVulnerability = {
  id: string;
  cve_name: string;
  cve_description: string;
};

export type Vulnerability = {
  id: string;
  friendly_id: string;
  name: string;
  vulnerability_type: string;
  attack_vector: string;
  privileges_required: string;
  user_interaction: string;
  scope: string;
  confidentiality: string;
  integrity: string;
  availability: string;
  is_active: boolean;
  registration_status: string;
  controls: ObjectWithId[];
  cves: CVEOfVulnerability[];
};

export type RiskFactor = {
  id: string;
  friendly_id: string;
  name: string;
  is_active: boolean;
  registration_status: string;
  controls: ObjectWithId[];
  threats: ObjectWithId[];
};

export type AlternativesOfQuestions = {
  id: string;
  name: string;
};

export type Subcontrol = {
  id: string;
  control: string;
  name: string;
  cost: number;
  benefit: number;
  cost_benefit: string;
  technical_capacity: number;
  final_benefit: string;
  is_active: boolean;
};

export type Control = {
  id: string;
  friendly_id: string;
  name: string;
  class_name: number;
  group_name: number;
  type_name: number;
  category: number;
  control_type: string;
  confidentiality: boolean;
  integrity: boolean;
  availability: boolean;
  identify: boolean;
  protect: boolean;
  detect: boolean;
  respond: boolean;
  recover: boolean;
  attack_vector: string;
  attack_complexity: string;
  privileges_required: string;
  user_interaction: string;
  scope: string;
  confidentiality_level: string;
  integrity_level: string;
  availability_level: string;
  cost: number;
  technical_capacity: number;
  benefit: number;
  cost_benefit: string;
  final_benefit: string;
  subcontrols: Subcontrol[];
  is_active: boolean;
};

export type Department = {
  id: string;
  name: string;
};

export type AnalystAnswerQuestion = {
  id: string;
  name: string;
  question_type: string;
  has_evidence: boolean;
  is_active: boolean;
  example?: string;
  vulnerabilities: Vulnerability[];
  risk_factors: RiskFactor[];
  controls: Control[];
  departments: Department[];
  alternatives: AlternativesOfQuestions[];
  evidences: any[]
};

export type QuestionAnswer = {
  id: string;
  checklist: string;
  questionnaire: string;
  question: IdAndName;
  answer: string;
  comment: string;
  analyst: string;
  evidences: [];
  question_customer_project: IdAndName;
  vulnerabilities: Vulnerability[];
  risk_factors: RiskFactor[];
  controls: Control[];
}

export type CustomerProjetctQuestionnaires = {
  answers: QuestionAnswer[];
  customer_project_questionnaire_id: string;
  interviewees: CustomerProjectInterwiies[];
  questionnaire_id: string;
  questionnaire_name: string;
  description: string;
  questions: AnalystAnswerQuestion[];
  questions_customer_project: QuestionsCustomerProject[];
  emails_for_escalation: any[];
  final_delivery_date: string;
}

export type AuditInterviewee = {
  id: string;
  name: string;
  email: string;
  position: string;
}

export type CustomersUsers = {
  id: string;
  email: string;
  corporate_name_or_name: string;
}

export type NewCustomerProjetctQuestionnaires = {
  customer_project_questionnaire_id: string;
  questionnaire_id: string;
  questionnaire_name: string;
  type_of_questionnaire: string;
  interviewees: CustomerProjectInterwiies[];
  status: string;
  percentage_of_answers: number;
  emails_for_escalation: any[];
  final_delivery_date: string;
  date_sent_to_interviewee: string;
  audit_interviewees: AuditInterviewee[];
  customers_users: CustomersUsers[];
}

export type ChecklistQuestion = {
  id: string;
  name: string;
  example: string;
  is_active: boolean;
  has_evidence: boolean;      
  question_type: string;
  answer: ChecklistAnswer | null;
  alternatives: AlternativesOfQuestions[];
}

export type ChecklistActivityType = {
  id: string;
  name: string;
  has_evidence: boolean;
  answer: ChecklistAnswer | null;
}

export type CustomerProjectChecklist = {
  id: string;  
  subject: string;
  status?: string;
  answers: ChecklistAnswer[];
  questions: ChecklistQuestion[];
  activities: ChecklistActivityType[];
}

export type CustomerProjectCrownJewels = {
  id: string
  name: string
  result: string
  result_score: string
  weight: string
}

export type CustomerProjectProjectType = {
  activities?: []
  checklists?: []
  crown_jewels?: []
  id: string
  project_name: string
  questionnaires?: []
}

export type Framework = {
  id: string;
  name: string;
  version: string;
  is_active: boolean;
  approved?: boolean;
  approval_date?: string;
}

export type CustomerProjectResponsible = {
  id: string,
  corporate_name_or_name: string
} | string;

export type CustomerProjectDetail = {
  id: string;
  name: string;
  project_type: CustomerProjectProjectType;
  customer: CustomerProjectCustomerDetail;
  sector?: string;
  subsector?: string;
  segment?: string;
  accept_additional_customer_data?: boolean;
  responsible: CustomerProjectResponsible;
  responsible_project_manager?: CustomerProjectResponsible;
  project_status: string;
  created_at?: string;
  project_expiration_date: string;
  has_scorecard: boolean;
  justification_of_inactivation?: string;
  is_active: boolean;
  framework: Framework;
  analysts: CustomerProjectResponsible[];
  nist_version: string;
  customers_users: CustomersUsers[];
}

export type QuestionsCustomerProjectAlternatives = {
  id: string;
  question: string;
  name: string;
}

export type QuestionsCustomerProject = {
  id: string;
  customer_project_id: string;
  questionnaire: string;
  name: string;
  question_type: string;
  alternatives: QuestionsCustomerProjectAlternatives[];
  evidences: any[]
}

export type CustomerProjectsDetailActionPayload = {
  customerProjectId: string;
}

export type CustomerProjects = {
  CustomerProjects: CustomerProject[]
}

export type CustomerProjectsActionToSaga = {
  newCustomerProject: PostCustomerProjectBody;
}

export type PostCustomerProjectBody = {
  name: string;
  project_type: string;
  customer: string;
  analysts: ObjectWithId[];
}

export type Answers = {
  answer: string,
  comment: string,
  customer_project: string,
  questionnaire: string,
  question: string,
  alternative: string,
  user: string,
  evidences: any[],
  question_customer_project?: string,
}

export type OpenQuestionAnswer = {
  answer: string,
  comment: string,
  customer_project: string,
  questionnaire: string,
  question: string,
  alternative: string,
  user: string
}

export type Alternatives = {
  name: string;
  answer: OpenQuestionAnswer | any;
}

export type AnalystAnswerCreateResponse = {
  answers: AnalystAnswerCreate[]
}

export type AnalystAnswerCreate = {
  activity: string
  analyst: string
  answer: string
  checklist: string
  comment: string
  controls: []
  evidences: []
  id: string
  interviewee: string
  question: IdAndName
  question_customer_project: IdAndName
  questionnaire: string
  risk_factors: []
  vulnerabilities: []
}

export type AnalystQuestionCreate = {
  customer_project: string;
  questionnaire: string;
  name: string;
  question_type: string;
  alternatives: Alternatives[];
  answer: OpenQuestionAnswer;
  evidences: any[];
}

export type AnalystAnsweringQuestion = {
  answers: Answers[];
  questions_create: AnalystQuestionCreate[];
  analystId: string;
  projectId: string;
  questionnaireId?: string;
  projectName?: string;
}

export type ChecklistActivityAnswerPayloadBody = {
  answer: string,
  customer_project: string,
  checklist: string,
  activity: string,
  user: string,
  vulnerabilities: ObjectWithId[],
  risk_factors: ObjectWithId[],
  controls: ObjectWithId[],
}

export type ChecklistQuestionAnswerResponseBody = {
  id: string,
  answer: string,
  customer_project: string,
  checklist: string,
  question: string,
  user: string,
  comment: string,
  alternative: string,
}

export type ChecklistQuestionAnswerPayloadBody = {
  answer: string,
  customer_project: string,
  checklist: string,
  question: string,
  user: string,
  comment: string,
  alternative: string,
}

export type ChecklistQuestionAnswerPayloadType = {
  answerBody: ChecklistQuestionAnswerPayloadBody;
  evidencesBody: any[]
}

export type ChecklistActivityAnswerPayloadType = {
  answerBody: ChecklistActivityAnswerPayloadBody;
  evidencesBody: any[];
  disabledChange: string;
}

export type EditChecklistActivityAnswerPayloadType = {
  answerBody: string;
  activityId: string
  evidencesBody: any[]
}

export type EditChecklistActivityAnswerResponse = {
  id: string,
  answer: string
}

export type ChecklistActivityAnswerResponseType = {
  id: string,
  answer: string,
  comment: string,
  customer_project: string,
  checklist: string,
  question: string,
  alternative: string,
  user: string
}

export type ActivityRegistrationsBody = {
  add_vulnerabilities: ObjectWithId[],
  remove_vulnerabilities: ObjectWithId[],
  add_controls: ObjectWithId[],
  remove_controls: ObjectWithId[],
  add_risk_factors: ObjectWithId[],
  remove_risk_factors: ObjectWithId[]
}

export type QuestionEvidencesPayloadToApi = {
  newEvidences: any[],
  questionAnswerBody: QuestionAnswerEvidenceBodyType,
  questionId: string,
  customerProjectId: string
}

export type QuestionAnswerEvidenceBodyType = {
  answer: string,
  comment: string,
  alternative: string,
  evidences_remove: ObjectWithId[]
}

export type ActivityAnswerEvidenceBodyType = {
  answer: string,
  evidences_remove: ObjectWithId[]
}

export type ActivityEvidencesPayloadToApi = {
  newEvidences: any[];
  activityAnswerBody: ActivityAnswerEvidenceBodyType;
  activityId: string;
  customerProjectId: string;
  disableChange: string;
}

export type ActivityRegistrationsPayloadApi = {
  activityRegistrations: ActivityRegistrationsBody;
  activityId: string;
  disableChange: string;
  redirectId?: string;
}

export type ActivityRegistrationsPayloadApiDetail = {
  activityRegistrations: ActivityRegistrationsBody;
  activityId: string;
  redirectId: string;
  questionnaireId: string;
  projectName?: string;
  disableChange: string;
}

export type OrganogramPayloadApiBody = {
  organogramBody: any[],
  customerProjectId: string;
}

export type EvidencePayloadApiBody = {
  answerId: string,
  evidencesBody: any[],
  filteredFilesRemoved: ObjectWithId[]
}

export type ObjectCommumWithId = {
  id: string;
  name: string;
}

export type CustomersProjectsCustomer = {
  count: number;
  next: string | null;
  previous: string | null;
  results: ObjectCommumWithId[];
}

export type AllCustomersProjects = {
  activities: [];
  analysts: ListingAnalysts[];
  checklists: [];
  config_crown_jewels: [];
  crown_jewels: [];
  customer: Customer;
  id: string;
  is_active: boolean;
  name: string;
  project_status: string;
  project_type: ProjectType;
  created_at: string;
  questionnaires: [];
  responsible: CustomerProjectResponsible;
  risks: [];
  threats: [];
  responsible_project_manager?: string;
  has_scorecard: boolean;
  framework: Framework;
  project_expiration_date: string;
  customers_users: ListingAnalysts[];
}

export type ChecklistActivityDetailActionPayload = {
  activityId: string;
}

export type ChecklistQuestionDetailActionPayload = {
  questionId: string;
}

export type IdAndSubject = {
  id: string,
  subject: string
}

export type ChecklistAnswer = {
  activity: ChecklistActivityType;
  analyst: string;
  answer: string;
  checklist: IdAndSubject
  comment: string
  controls: IdAndName[]
  evidences: []
  id: string;
  interviewee: string
  question: IdAndName;
  question_customer_project: string;
  questionnaire: string;
  risk_factors: IdAndName[]
  vulnerabilities: IdAndName[]
}

export type ChecklistActivityDetail = {
  activity_id: string,
  activity_name: string
  answer: string,
  checklist_id: string,
  has_evidence: boolean,
  controls: IdAndName[],
  customer_project_id: string,
  evidences: Evidences[],
  id: string,
  risk_factors: IdAndName[],
  vulnerabilities: IdAndName[],
}

export type Evidences = {
  evidence_id: string;
  evidence_name: string;
  id: string;
  is_active: boolean;
  position: number;
}

export type QuestionDetails = {
  id: string,
  name: string,
  question_type: string,
  has_evidence: boolean,
  example: string,
  is_active: boolean,
  alternatives: QuestionsCustomerProjectAlternatives[]
}

export type ChecklistQuestionDetails = {
  answer: string,
  alternative_id: string,
  checklist_id: string,
  comment: string,
  controls: IdAndName[]
  customer_project_id: string,
  evidences: Evidences[],
  id: string,
  question: QuestionDetails,
  risk_factors: IdAndName[]
  vulnerabilities: IdAndName[]
}

export type CustomerProjectsQuestionnaires = {
  id: string,
  name: string,
  customer: CustomerProjectCustomerDetail,
  questionnaires: CustomerProjetctQuestionnaires[],
  project_status: string,
  is_active: boolean
}

export type NewCustomerProjectsQuestionnaires = {
  id: string,
  name: string,
  customer: CustomerProjectCustomerDetail,
  questionnaires: NewCustomerProjetctQuestionnaires[],
  project_status: string,
  is_active: boolean,
  questionnaires_external_percentage_total: number;
  questionnaires_internal_percentage_total: number;
}

export type answerActivity = {
  activity: ChecklistActivityType
  analyst: string
  answer: string
  checklist: string
  comment: string
  controls: []
  evidences: []
  id: string
  interviewee: string
  question: string
  question_customer_project: string
  questionnaire: string
  risk_factors: []
  vulnerabilities: []
}

export type CustomerProjectsChecklists = {
  id: string;
  name: string;
  is_active: boolean;
  project_status: string;
  checklists: CustomerProjectChecklist[];
  activities: ChecklistActivityType[];
}

export type CustomerProjectQuestionnairePayload = {
  customerProjectId: string;
};

export type CustomerProjectQuestionnaireDetailPayload = {
  customerProjectQuestionnaireId: string;
};

export type CustomerProjectDeleteCrownJewelPayload = {
  crownJewelId: string;
  customerProjectId: string;
};

export type CustomerProjectEditCSFCorePayload = {
  CSFCoreId: string,
  maturityLevel: string,
  customerProjectId: string;
  version: string;
};

export type questionnaires = {
  id: string;
  name: string;
  description: string;
  type_of_questionnaire: string;
}

export type checklists = {
  id: string;
  subject: string;
}

export type CustomerProjectsThreats = {
  id: string,
  friendly_id: string,
  name: string,
  description: string,
  is_active: boolean,
  registration_status: string,
  vulnerabilities: ControlOfVulnerability[],
  risk_factors: riskFactor[],
  threats: Threat[],
  damages: ThreatDetailsDamages[],
  controls: ThreatsArrayNameId[],
  risks: risk[],
  questionnaires_origin: questionnaires[],
  checklists_origin: checklists[],
  justification_of_inactivation: string
}

export type ProjectsThreats = {
  id: string;
  name: string;
  project_status: string;
  is_active: boolean;
  threats: Threat[];
}

export type CrownJewelScoreCard = {
  id: string,
  name: string,
  weight: string,
  result: string,
  result_score: number
}

export type CsfCoreProject = {
  id: string,
  function: string,
  category: string,
  subcategory: string,
  maturity_level: string,
  version: number
}

export type ScoreCardRating = {
  id: string,
  name: string,
  category: string,
  from: number,
  to: number
}

export type ScoreCardProject = {
  id: string,
  customer_project: string,
  sum_crown_jewels: number,
  sum_csf_core: number,
  top_score_crown_jewels: number,
  top_score_csf_core: number,
  crown_jewels_normalized: number,
  crown_jewels_lisa_classification: string,
  csf_core_lisa_classification: string,
  csf_core_normalized: number,
  final_score: number,
  score_card_rating: ScoreCardRating
}

export type CustomerProjectScoreCard = {
  id: string;
  name: string;
  crown_jewels: CrownJewelScoreCard[];
  csf_core: {
    [key: string]: CsfCoreProject[],
  };
  score_card: ScoreCardProject;
}

export type OrganogramType = {
  id: string,
  name: string,
  organogram: string
}

export type ReportsBody = {
  id: string,
  report_pdf: boolean,
  pdf_report_is_approved: boolean,
  date_of_last_approval_of_the_pdf: Date | null,
  latest_generation_pdf_report: Date | null,
  pdf_report_sent_by_the_analyst: boolean,
  report_data_sent_by_analyst: Date,
  report_doc: boolean,
}

export type ScoreCardRadarChart = {
  [key: string]: number,
}

export type RiskDonutChart = {
  critical: number;
  high: number;
  medium: number;
  low: number;
  info: number;
}

export type RiskHeatMap = {
  risks_probability_low_impact_low_count: number;
  risks_probability_low_impact_medium_count: number;
  risks_probability_low_impact_high_count: number;
  risks_probability_medium_impact_low_count: number;
  risks_probability_medium_impact_medium_count: number;
  risks_probability_medium_impact_high_count: number;
  risks_probability_high_impact_low_count: number;
  risks_probability_high_impact_medium_count: number;
  risks_probability_high_impact_high_count: number;
}

export type EnvAssessmentCustomerProject = {
  id: string;
  name: string;
  customer_quantity: string | null;
  identified_quantity: string | null;
}

export type AllEnvAssessmentCustomerProject = {
  id: string;
  env_assessments: EnvAssessmentCustomerProject[];
}

export type CustomerProjectState = {
  allCustomerProjects: AllCustomersProjects[];
  customersProjectDetail: CustomerProjectDetail;
  allAnalysts: Analysts[];
  allCustomers: Customer[];
  allProjectType: ProjectType[];
  checklistActivityDetailsData: ChecklistActivityDetail;
  checklistQuestionDetails: ChecklistQuestionDetails;
  customerProjectQuestionnaires: CustomerProjectsQuestionnaires;
  newCustomerProjectQuestionnaires: NewCustomerProjectsQuestionnaires;
  customerProjectsQuestionnaireDetails: CustomerProjectsQuestionnaireDetails;
  customerProjectChecklists: CustomerProjectsChecklists;
  customerProjectThreats: CustomerProjectsThreats;
  customerProjectScoreCard: CustomerProjectScoreCard;
  scoreCardRadarChart: ScoreCardRadarChart;
  riskDonutChart: RiskDonutChart;
  riskHeatMap: RiskHeatMap;
  allReportsTexts: ReportsText;
  organograms: OrganogramType;
  reportProject: ReportsBody;
  loading: boolean;
  projectThreats: ProjectsThreats;
  customersProjectsCustomer: CustomersProjectsCustomer;
  allEnvAssessmentCustomerProject: AllEnvAssessmentCustomerProject;
}

export type CustomerProjetcDetailApiPayload = {
  threatId: string;
}

export type ChangeStatusBodyType = {
  is_active: boolean,
  justification_of_inactivation: string
}

export type CustomerProjectChangeStatusApiPayload = {
  customerProjectId: string;
  changeStatusBody: ChangeStatusBodyType;
}

export type SearchFilteredCustomerProjectRequestPayloadParams = {
  filteredCustomerProjects: Array<string | null>;
  isActive: boolean | null;
  status_project: string | null;
  sector: string;
  subSector: string;
  segment: string;
  anonymized: boolean | null;
  showMessenger: boolean;
};

export type Alternative = {
  id: string;
  name: string;
}

export type Control_Type = {
  id: string;
  name: string;
}

export type Class_Name = {
  id: string;
  name: string;
}

export type Group_Name = {
  id: string;
  name: string;
}

export type Type_Name = {
  id: string;
  name: string;
}

export type Category = {
  id: string;
  name: string;
  importance: number;
  description: string;
}

export type Controls = {
  id: string;
  name: string;
}

export type Risk_Factor = {
  id: string;
  name: string;
}

export type Vulnerabilitie = {
  id: string;
  name: string;
}

export type Answer = {
  id: string;
  answer: string;
  comment: string;
  evidences: any[];
  vulnerabilities: Vulnerabilitie[];
  risk_factors: Risk_Factor[];
  controls: Controls[];
  user?: string;
  updated_at?: string;
}

export type Question = {
  id: string;
  name: string;
  question_type: string;
  has_evidence: boolean;
  example: string;
  is_active: boolean;
  order: number;
  alternatives: Alternative[];
  departments: Department[];
  answer: Answer;
}

export type Questions_Customer_Project = {
  id: string;
  name: string;
  question_type: string;
  alternatives: Alternative[];
  answer: Answer;
}

export type CustomerProjectsQuestionnaireDetails = {
  customer_project_questionnaire_id: string;
  questionnaire_id: string;
  questionnaire_name: string;
  description: string;
  questions: Question[];
  questions_customer_project: Questions_Customer_Project[];
  audit_interviewees: AuditInterviewee[];
  customers_users: CustomersUsers[];
}

export type NewQuestion = {
  id: string;
  name: string;
  questions: number;
  is_active: boolean;
}

export type Project_Type = {
  id: string;
  project_name: string;
}

export type Customer_Project = {
  id: string;
  corporate_name_or_name: string;
  revenue: number;
  it_budget: number;
  company_size: string;
  interviewees: [];
}

export type Responsible = {
  id: string;
  corporate_name_or_name: string;
}

export type Analysts_Project = {
  id: string;
  corporate_name_or_name: string;
}

export type NewProjectCreate = {
  id: string;
  name: string;
  project_type: Project_Type,
  customer: Customer_Project,
  responsible: Responsible,
  analysts: Analysts_Project[],
  project_status: string;
  justification_of_inactivation: string;
  is_active: boolean;
}

export type ProjectStatuschangePayloadApi = {
  customerProjectId: string;
  project_status: string;
}

export type CustomerProjectEditCrownJewelPayload = {
  crownJewelsId: string;
  result: string;
  weight: string;
  customerProjectId: string;
};

export type ProjectExpirationDatePayloadApi = {
  customerProjectId: string;
  project_expiration_date: string;
  goToListing: boolean;
}

export type CustomerProjectPagePayload = {
  isCustomerProjectPage: boolean;
}

export type IdentifiedQuantityPayload = {
  id: string;
  identified_quantity: number;
  customerProjectId: string;
}

export type LinkAuditedIntervieweesPayload = {
  customerProjectQuestionnaireId: string;
  customerProjectId: string;
  audit_interviewees: string[];
  customers_users: string[];
  linkUser: boolean;
}

export type LinkAuditedIntervieweesResponsePayload = {
  id: string;
  audit_interviewees: string[];
}