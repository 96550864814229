import type { Action } from 'store/types';

import { 
  AllSizingTypes,
  SizingTypePayload,
  SizingTypesActions, 
  PostSizingTypeRequestPayload,
  PutSizingTypeRequestPayload,
  SearchFilteredSizingTypesPayload,
} from './types';

const {
  ADD_SIZINGTYPES_REQUEST
  ,ADD_SIZINGTYPES_SUCCESS
  ,ADD_SIZINGTYPES_FAILURE
  ,EDIT_SIZINGTYPES_REQUEST
  ,EDIT_SIZINGTYPES_SUCCESS
  ,EDIT_SIZINGTYPES_FAILURE
  ,GET_ALL_SIZINGTYPES_REQUEST
	,GET_ALL_SIZINGTYPES_SUCCESS
	,GET_ALL_SIZINGTYPES_FAILURE
  ,GET_FILTERED_SIZINGTYPES_REQUEST
  ,GET_FILTERED_SIZINGTYPES_SUCCESS
  ,GET_FILTERED_SIZINGTYPES_FAILURE
  ,ACTIVE_OR_INACTIVE_SIZINGTYPES_REQUEST
  ,ACTIVE_OR_INACTIVE_SIZINGTYPES_SUCCESS
  ,ACTIVE_OR_INACTIVE_SIZINGTYPES_FAILURE
} = SizingTypesActions;

function getSizingTypesRequest(): Action {
  return {
    type: GET_ALL_SIZINGTYPES_REQUEST,
  };
}

function getSizingTypesSuccess(allSizingTypes: AllSizingTypes): Action {
  return {
    type: GET_ALL_SIZINGTYPES_SUCCESS,
    payload: {
      allSizingTypes,
    },
  };
}

function getSizingTypesFailure(): Action {
  return {
    type: GET_ALL_SIZINGTYPES_FAILURE,
  };
}

function getSearchFilteredSizingTypesRequest(
  {
    name,
    is_active
  }: SearchFilteredSizingTypesPayload
): Action {
  return {
    type: GET_FILTERED_SIZINGTYPES_REQUEST,
    payload: {
      name,
      is_active
    },
  };
}

function getSearchFilteredSizingTypesSuccess(allSizingTypes: AllSizingTypes): Action {
  return {
    type: GET_FILTERED_SIZINGTYPES_SUCCESS,
    payload: {
      allSizingTypes,
    },
  };
}

function getSearchFilteredSizingTypesFailure(): Action {
  return {
    type: GET_FILTERED_SIZINGTYPES_FAILURE,
  };
}

function putActiveOrInactiveSizingTypeRequest(
  {
    sizingType
  }: SizingTypePayload): Action {
  return {
    type: ACTIVE_OR_INACTIVE_SIZINGTYPES_REQUEST,
    payload: {
      sizingType,
    },
  };
}

function putActiveOrInactiveSizingTypeSuccess(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_SIZINGTYPES_SUCCESS,
  };
}

function putActiveOrInactiveSizingTypeFailure(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_SIZINGTYPES_FAILURE,
  };
}

function postSizingTypeRequest(
  {
    name,
    env_assessments
  }: PostSizingTypeRequestPayload): Action {
  return {
    type: ADD_SIZINGTYPES_REQUEST,
    payload: {
      name,
      env_assessments
    },
  };
}

function postSizingTypeSuccess(): Action {
  return {
    type: ADD_SIZINGTYPES_SUCCESS,
  };
}

function postSizingTypeFailure(): Action {
  return {
    type: ADD_SIZINGTYPES_FAILURE,
  };
}

function putSizingTypeRequest(
  {
    id,
    name,
    env_assessments,
  }: PutSizingTypeRequestPayload): Action {
  return {
    type: EDIT_SIZINGTYPES_REQUEST,
    payload: {
      id,
      name,
      env_assessments,
    },
  };
}

function putSizingTypeSuccess(): Action {
  return {
    type: EDIT_SIZINGTYPES_SUCCESS,
  };
}

function putSizingTypeFailure(): Action {
  return {
    type: EDIT_SIZINGTYPES_FAILURE,
  };
}

export const sizingTypesActionsFunctions = {
  postSizingTypeRequest
  ,postSizingTypeSuccess
  ,postSizingTypeFailure
  ,putSizingTypeRequest
  ,putSizingTypeSuccess
  ,putSizingTypeFailure
  ,getSizingTypesRequest
  ,getSizingTypesSuccess
  ,getSizingTypesFailure
  ,getSearchFilteredSizingTypesRequest
  ,getSearchFilteredSizingTypesSuccess
  ,getSearchFilteredSizingTypesFailure
  ,putActiveOrInactiveSizingTypeRequest
  ,putActiveOrInactiveSizingTypeSuccess
  ,putActiveOrInactiveSizingTypeFailure
};
