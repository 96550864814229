export enum SizingTypesActions {
    ADD_SIZINGTYPES_REQUEST = 'ADD_SIZINGTYPES_REQUEST'
    ,ADD_SIZINGTYPES_SUCCESS = 'ADD_SIZINGTYPES_SUCCESS'
    ,ADD_SIZINGTYPES_FAILURE = 'ADD_SIZINGTYPES_FAILURE'
    ,EDIT_SIZINGTYPES_REQUEST = 'EDIT_SIZINGTYPES_REQUEST'
    ,EDIT_SIZINGTYPES_SUCCESS = 'EDIT_SIZINGTYPES_SUCCESS'
    ,EDIT_SIZINGTYPES_FAILURE = 'EDIT_SIZINGTYPES_FAILURE'
    ,GET_ALL_SIZINGTYPES_REQUEST = 'GET_ALL_SIZINGTYPES_REQUEST'
    ,GET_ALL_SIZINGTYPES_SUCCESS = 'GET_ALL_SIZINGTYPES_SUCCESS'
    ,GET_ALL_SIZINGTYPES_FAILURE = 'GET_ALL_SIZINGTYPES_FAILURE'
    ,GET_FILTERED_SIZINGTYPES_REQUEST = 'GET_FILTERED_SIZINGTYPES_REQUEST'
    ,GET_FILTERED_SIZINGTYPES_SUCCESS = 'GET_FILTERED_SIZINGTYPES_SUCCESS'
    ,GET_FILTERED_SIZINGTYPES_FAILURE = 'GET_FILTERED_SIZINGTYPES_FAILURE'
    ,ACTIVE_OR_INACTIVE_SIZINGTYPES_REQUEST = 'ACTIVE_OR_INACTIVE_SIZINGTYPES_REQUEST'
    ,ACTIVE_OR_INACTIVE_SIZINGTYPES_SUCCESS = 'ACTIVE_OR_INACTIVE_SIZINGTYPES_SUCCESS'
    ,ACTIVE_OR_INACTIVE_SIZINGTYPES_FAILURE = 'ACTIVE_OR_INACTIVE_SIZINGTYPES_FAILURE'
  }

  export type EnvAssessmentsSizingType = {
    id: string;
    name: string;
  }

  export type SizingType = {
    id: string;
    name: string;
    env_assessments: EnvAssessmentsSizingType[];
    is_active: boolean;
  };  

  export type SearchedSizingType = {
    name: string | null;
    is_active: string | null;
  };

  export type SearchedSizingTypeQuestions = {
    question: string | null;
  };

  export type AllSizingTypes = {
    count: number;
    next: string | null;
    previous: string | null;
    results: SizingType[];
  }

  export type OpenedModalSizingTypes = {
    SizingTypesModal: boolean;
    SizingTypesActiveOrInactiveModal: boolean;
  };
  
  export type SizingTypeState = {
    allSizingTypes: AllSizingTypes;
  };
  
  export type SizingTypePayload = {
    sizingType: SizingType | null;
  }

  export type PutSizingTypeRequestPayload = {
    id: string;
    name: string | undefined;
    env_assessments: string[];
  }

  export type PostSizingTypeRequestPayload = {
    name: string | undefined;
    env_assessments: string[];
  }

  export type SearchFilteredSizingTypesPayload = {
    name: string | null;
    is_active: boolean | null;
  }

  export type SearchFilteredSizingTypesQuestionsPayload = {
    question: string | null;
  }