import type { Action } from 'store/types';

import {
  ControlsRiskDetail,
  ChangeStatusBodyType,
  ControlRecommendations,
  ChangeRankingPhaseBodyType,
  ControlRecommendationsActions,
  ControlCustomerRecommendations,
} from './types';

const {
  GET_CONTROL_RECOMMENDATIONS_REQUEST,
  GET_CONTROL_RECOMMENDATIONS_SUCCESS,
  GET_CONTROL_RECOMMENDATIONS_FAILURE,
  GET_CONTROL_RECOMMENDATIONS_DETAIL_REQUEST,
  GET_CONTROL_RECOMMENDATIONS_DETAIL_SUCCESS,
  GET_CONTROL_RECOMMENDATIONS_DETAIL_FAILURE,
  EDIT_CONTROL_RECOMMENDATIONS_STATUS_REQUEST,
  EDIT_CONTROL_RECOMMENDATIONS_STATUS_SUCCESS,
  EDIT_CONTROL_RECOMMENDATIONS_STATUS_FAILURE,
  EDIT_CONTROL_RECOMMENDATIONS_RANKING_PHASE_REQUEST,
  EDIT_CONTROL_RECOMMENDATIONS_RANKING_PHASE_SUCCESS,
  EDIT_CONTROL_RECOMMENDATIONS_RANKING_PHASE_FAILURE,
  GENERATE_CONTROL_RECOMMENDATIONS_CUSTOMER_PROJECT_REQUEST,
  GENERATE_CONTROL_RECOMMENDATIONS_CUSTOMER_PROJECT_SUCCESS,
  GENERATE_CONTROL_RECOMMENDATIONS_CUSTOMER_PROJECT_FAILURE,
  GET_CONTROL_CUSTOMER_RECOMMENDATIONS_REQUEST,
  GET_CONTROL_CUSTOMER_RECOMMENDATIONS_SUCCESS,
  GET_CONTROL_CUSTOMER_RECOMMENDATIONS_FAILURE,
  GET_GENERATE_PHASES_RECOMMENDATIONS_REQUEST,
  GET_GENERATE_PHASES_RECOMMENDATIONS_SUCCESS,
  GET_GENERATE_PHASES_RECOMMENDATIONS_FAILURE,
  GET_GENERATE_PRICING_RECOMMENDATIONS_REQUEST,
  GET_GENERATE_PRICING_RECOMMENDATIONS_SUCCESS,
  GET_GENERATE_PRICING_RECOMMENDATIONS_FAILURE,
} = ControlRecommendationsActions;

function getControlRecommendationsRequest(customerProjectId: string): Action {
  return {
    type: GET_CONTROL_RECOMMENDATIONS_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getControlRecommendationsSuccess(controlRecommendations: ControlRecommendations): Action {
  return {
    type: GET_CONTROL_RECOMMENDATIONS_SUCCESS,
    payload: {
      controlRecommendations
    }
  };
}

function getControlRecommendationsFailure(): Action {
  return {
    type: GET_CONTROL_RECOMMENDATIONS_FAILURE,
  };
}

function editControlRecommendationsStatusRequest(
  controlId: string,
  changeStatusBody: ChangeStatusBodyType,
  customerProjectId: string
): Action {
  return {
    type: EDIT_CONTROL_RECOMMENDATIONS_STATUS_REQUEST,
    payload: {
      controlId,
      changeStatusBody,
      customerProjectId
    }
  };
}

function editControlRecommendationsStatusSuccess(): Action {
  return {
    type: EDIT_CONTROL_RECOMMENDATIONS_STATUS_SUCCESS,
  };
}

function editControlRecommendationsStatusFailure(): Action {
  return {
    type: EDIT_CONTROL_RECOMMENDATIONS_STATUS_FAILURE,
  };
}

function getControlRecommendationsDetailRequest(controlId: string): Action {
  return {
    type: GET_CONTROL_RECOMMENDATIONS_DETAIL_REQUEST,
    payload: {
      controlId,
    }
  };
}

function getControlRecommendationsDetailSuccess(controlsRiskDetail: ControlsRiskDetail): Action {
  return {
    type: GET_CONTROL_RECOMMENDATIONS_DETAIL_SUCCESS,
    payload: {
      controlsRiskDetail
    }
  };
}

function getControlRecommendationsDetailFailure(): Action {
  return {
    type: GET_CONTROL_RECOMMENDATIONS_DETAIL_FAILURE,
  };
}

function generateControlRecommendationsCustomerProjectRequest(customerProjectId: string): Action {
  return {
    type: GENERATE_CONTROL_RECOMMENDATIONS_CUSTOMER_PROJECT_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function generateControlRecommendationsCustomerProjectSuccess(): Action {
  return {
    type: GENERATE_CONTROL_RECOMMENDATIONS_CUSTOMER_PROJECT_SUCCESS,
  };
}

function generateControlRecommendationsCustomerProjectFailure(): Action {
  return {
    type: GENERATE_CONTROL_RECOMMENDATIONS_CUSTOMER_PROJECT_FAILURE,
  };
}

function editControlRecommendationsRankingPhaseRequest(
  controlId: string,
  changeRankingPhaseBody: ChangeRankingPhaseBodyType,
): Action {
  return {
    type: EDIT_CONTROL_RECOMMENDATIONS_RANKING_PHASE_REQUEST,
    payload: {
      controlId,
      changeRankingPhaseBody,
    }
  };
}

function editControlRecommendationsRankingPhaseSuccess(): Action {
  return {
    type: EDIT_CONTROL_RECOMMENDATIONS_RANKING_PHASE_SUCCESS,
  };
}

function editControlRecommendationsRankingPhaseFailure(): Action {
  return {
    type: EDIT_CONTROL_RECOMMENDATIONS_RANKING_PHASE_FAILURE,
  };
}

function getControlCustomerRecommendationsRequest(projectId: string): Action {
  return {
    type: GET_CONTROL_CUSTOMER_RECOMMENDATIONS_REQUEST,
    payload: {
      projectId,
    }
  };
}

function getControlCustomerRecommendationsSuccess(controlCustomerRecommendations: ControlCustomerRecommendations): Action {
  return {
    type: GET_CONTROL_CUSTOMER_RECOMMENDATIONS_SUCCESS,
    payload: {
      controlCustomerRecommendations
    }
  };
}

function getControlCustomerRecommendationsFailure(): Action {
  return {
    type: GET_CONTROL_CUSTOMER_RECOMMENDATIONS_FAILURE,
  };
}

function getGeneratePhasesRecommendationsRequest(projectId: string): Action {
  return {
    type: GET_GENERATE_PHASES_RECOMMENDATIONS_REQUEST,
    payload: {
      projectId,
    }
  };
}

function getGeneratePhasesRecommendationsSuccess(): Action {
  return {
    type: GET_GENERATE_PHASES_RECOMMENDATIONS_SUCCESS,
  };
}

function getGeneratePhasesRecommendationsFailure(): Action {
  return {
    type: GET_GENERATE_PHASES_RECOMMENDATIONS_FAILURE,
  };
}

function postGeneratePricingRecommendationsRequest(projectId: string): Action {
  return {
    type: GET_GENERATE_PRICING_RECOMMENDATIONS_REQUEST,
    payload: {
      projectId,
    }
  };
}

function postGeneratePricingRecommendationsSuccess(): Action {
  return {
    type: GET_GENERATE_PRICING_RECOMMENDATIONS_SUCCESS,
  };
}

function postGeneratePricingRecommendationsFailure(): Action {
  return {
    type: GET_GENERATE_PRICING_RECOMMENDATIONS_FAILURE,
  };
}

export const controlRecommendationsActionsFunctions = {
  getControlRecommendationsRequest,
  getControlRecommendationsSuccess,
  getControlRecommendationsFailure,
  getControlRecommendationsDetailRequest,
  getControlRecommendationsDetailSuccess,
  getControlRecommendationsDetailFailure,
  editControlRecommendationsStatusRequest,
  editControlRecommendationsStatusSuccess,
  editControlRecommendationsStatusFailure,
  editControlRecommendationsRankingPhaseRequest,
  editControlRecommendationsRankingPhaseSuccess,
  editControlRecommendationsRankingPhaseFailure,
  generateControlRecommendationsCustomerProjectRequest,
  generateControlRecommendationsCustomerProjectSuccess,
  generateControlRecommendationsCustomerProjectFailure,
  getControlCustomerRecommendationsRequest,
  getControlCustomerRecommendationsSuccess,
  getControlCustomerRecommendationsFailure,
  getGeneratePhasesRecommendationsRequest,
  getGeneratePhasesRecommendationsSuccess,
  getGeneratePhasesRecommendationsFailure,
  postGeneratePricingRecommendationsRequest,
  postGeneratePricingRecommendationsSuccess,
  postGeneratePricingRecommendationsFailure,
};