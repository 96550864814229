import produce from 'immer';
import { Reducer } from 'redux';

import { 
  ControlRecommendationsActions,
  ControlRecommendationsState
} from './types'; 

const {
  GET_CONTROL_RECOMMENDATIONS_REQUEST,
  GET_CONTROL_RECOMMENDATIONS_SUCCESS,
  GET_CONTROL_RECOMMENDATIONS_FAILURE,
  GET_CONTROL_RECOMMENDATIONS_DETAIL_REQUEST,
  GET_CONTROL_RECOMMENDATIONS_DETAIL_SUCCESS,
  GET_CONTROL_RECOMMENDATIONS_DETAIL_FAILURE,
  GET_CONTROL_CUSTOMER_RECOMMENDATIONS_REQUEST,
  GET_CONTROL_CUSTOMER_RECOMMENDATIONS_SUCCESS,
  GET_CONTROL_CUSTOMER_RECOMMENDATIONS_FAILURE,
  GET_GENERATE_PRICING_RECOMMENDATIONS_REQUEST,
  GET_GENERATE_PRICING_RECOMMENDATIONS_SUCCESS,
  GET_GENERATE_PRICING_RECOMMENDATIONS_FAILURE,
} = ControlRecommendationsActions;

const initialState: ControlRecommendationsState = {
  controlRecommendations: {
    id: '',
    name: '',
    last_update_recommended_controls: '',
    controls_risk_customer_project: [],
  },
  controlCustomerRecommendations: {
    last_update_recommended_controls: '',
    total_budget_infosec: 0,
    total_cost_recommended_controls: 0,
    total_other_controls: 0,
    total_pareto: 0,
    recommended_controls: [],
  },
  controlsRisk: {
    id: '',
    position: 0,
    name: '',
    stage: 0,
    group: '',
    count_critical_risks: 0,
    count_high_risks: 0,
    count_medium_risks: 0,
    count_low_risks: 0,
    count_info_risks: 0,
    total_benefit: 0,
    is_active: false,
    minimized_risks: 0,
    justification_of_inactivation: '',
  },
  controlsRiskDetail: {
    id: '',
    name: '',
    group: '',
    is_active: false,
    justification_of_inactivation: '',
    position: 0,  
    stage: 0,
    risks_critical: [],
    risks_high: [],
    risks_medium: [],
    risks_low: [],
    risks_info: [],
    time_to_deploy: null,
  }
};

export const controlRecommendationsReducer: Reducer<ControlRecommendationsState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_CONTROL_RECOMMENDATIONS_REQUEST:
        break;

      case GET_CONTROL_RECOMMENDATIONS_SUCCESS: {
        const { controlRecommendations } = action.payload;
        draft.controlRecommendations = controlRecommendations;
        break;
      }

      case GET_CONTROL_RECOMMENDATIONS_FAILURE: {
        draft.controlRecommendations = {
          id: '',
          name: '',
          last_update_recommended_controls: '',
          controls_risk_customer_project: [],
        };
        break;
      }

      case GET_CONTROL_RECOMMENDATIONS_DETAIL_REQUEST:
        break;

      case GET_CONTROL_RECOMMENDATIONS_DETAIL_SUCCESS: {
        const { controlsRiskDetail } = action.payload;
        draft.controlsRiskDetail = controlsRiskDetail;
        break;
      }

      case GET_CONTROL_RECOMMENDATIONS_DETAIL_FAILURE: {
        draft.controlsRiskDetail = {
          id: '',
          name: '',
          group: '',
          is_active: false,
          justification_of_inactivation: '',
          position: 0,  
          stage: 0,
          risks_critical: [],
          risks_high: [],
          risks_medium: [],
          risks_low: [],
          risks_info: [],
          time_to_deploy: null,
        };
        break;
      }

      case GET_CONTROL_CUSTOMER_RECOMMENDATIONS_REQUEST:
        break;

      case GET_CONTROL_CUSTOMER_RECOMMENDATIONS_SUCCESS: {
        const { controlCustomerRecommendations } = action.payload;
        draft.controlCustomerRecommendations = controlCustomerRecommendations;
        break;
      }

      case GET_CONTROL_CUSTOMER_RECOMMENDATIONS_FAILURE: {
        draft.controlCustomerRecommendations = {
          last_update_recommended_controls: '',
          total_budget_infosec: 0,
          total_cost_recommended_controls: 0,
          total_other_controls: 0,
          total_pareto: 0,
          recommended_controls: [],
        };
        break;
      }

      case GET_GENERATE_PRICING_RECOMMENDATIONS_REQUEST:
      case GET_GENERATE_PRICING_RECOMMENDATIONS_SUCCESS:
      case GET_GENERATE_PRICING_RECOMMENDATIONS_FAILURE:
        break;

      default: {
        return draft;
      }
    }
  });
};