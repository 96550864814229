import type { Action } from 'store/types';

import {
  ActivityAnswerEvidenceBodyType,
  ActivityRegistrationsBody,
  AnalystQuestionCreate,
  Analysts,
  Answers,
  ChecklistActivityAnswerPayloadBody,
  ChecklistActivityDetail,
  ChecklistQuestionAnswerPayloadBody,
  Customer,
  CustomerProject,
  CustomerProjectActions,
  CustomerProjectDetail,
  CustomerProjectsChecklists,
  CustomerProjectWithAddAndRemove,
  PostCustomerProjectBody,
  ProjectType,
  QuestionAnswerEvidenceBodyType,
  QuestionnaireInterviwees,
  CustomerProjectsThreats,
  CustomerProjectScoreCard,
  ReportsText,
  EditQuestionnaireAnswer,
  OrganogramType,
  EvidencesIdsBody,
  AnswersEvidencesToRemove,
  EvidencesToRemove,
  ReportsBody,
  ChangeStatusBodyType,
  CustomerProjectsQuestionnaires,
  NewCustomerProjectsQuestionnaires,
  CustomerProjectsQuestionnaireDetails,
  LocalType,
  Answer,
  ScoreCardRadarChart,
  RiskDonutChart,
  RiskHeatMap,
  ProjectExpirationDatePayloadApi,
  ProjectsThreats,
  CustomersProjectsCustomer,
  AllEnvAssessmentCustomerProject,
  IdentifiedQuantityPayload,
  LinkAuditedIntervieweesPayload,
} from './types';

const {
  GET_ALL_CUSTOMER_PROJECT_REQUEST,
  GET_ALL_CUSTOMER_PROJECT_SUCCESS,
  GET_ALL_CUSTOMER_PROJECT_FAILURE,
  CREATE_NEW_CUSTOMER_PROJECT_REQUEST,
  CREATE_NEW_CUSTOMER_PROJECT_SUCCESS,
  CREATE_NEW_CUSTOMER_PROJECT_FAILURE,
  GET_ALL_ANALYSTS_REQUEST,
  GET_ALL_ANALYSTS_SUCCESS,
  GET_ALL_ANALYSTS_FAILURE,
  GET_ALL_CONTROL_CUSTOMERS_REQUEST,
  GET_ALL_CONTROL_CUSTOMERS_SUCCESS,
  GET_ALL_CONTROL_CUSTOMERS_FAILURE,
  GET_ALL_CONTROL_PROJECT_TYPE_REQUEST,
  GET_ALL_CONTROL_PROJECT_TYPE_SUCCESS,
  GET_ALL_CONTROL_PROJECT_TYPE_FAILURE,
  EDIT_CUSTOMER_PROJECT_REQUEST,
  EDIT_CUSTOMER_PROJECT_SUCCESS,
  EDIT_CUSTOMER_PROJECT_FAILURE,
  GET_CUSTOMER_PROJECT_DETAIL_REQUEST,
  GET_CUSTOMER_PROJECT_DETAIL_SUCCESS,
  GET_CUSTOMER_PROJECT_DETAIL_FAILURE,
  EDIT_QUESTIONNAIRES_INTERWEES_REQUEST,
  EDIT_QUESTIONNAIRES_INTERWEES_SUCCESS,
  EDIT_QUESTIONNAIRES_INTERWEES_FAILURE,
  ANALYST_ANSWERING_QUESTION_REQUEST,
  ANALYST_ANSWERING_QUESTION_SUCCESS,
  ANALYST_ANSWERING_QUESTION_FAILURE,
  GET_CUSTOMER_QUESTIONNAIRE_ANSWER_DETAIL_REQUEST,
  GET_CUSTOMER_QUESTIONNAIRE_ANSWER_DETAIL_SUCCESS,
  GET_CUSTOMER_QUESTIONNAIRE_ANSWER_DETAIL_FAILURE,
  DELETE_CUSTOMER_PROJECT_QUESTIONNAIRE_REQUEST,
  DELETE_CUSTOMER_PROJECT_QUESTIONNAIRE_SUCCESS,
  DELETE_CUSTOMER_PROJECT_QUESTIONNAIRE_FAILURE,
  GET_QUESTIONNAIRE_ANSWER_DETAIL_REQUEST,
  GET_QUESTIONNAIRE_ANSWER_DETAIL_SUCCESS,
  GET_QUESTIONNAIRE_ANSWER_DETAIL_FAILURE,
  CHECKLIST_ACTIVITY_ANSWER_REQUEST,
  CHECKLIST_ACTIVITY_ANSWER_SUCCESS,
  CHECKLIST_ACTIVITY_ANSWER_FAILURE,
  CHECKLIST_ACTIVITY_EVIDENCES_REQUEST,
  CHECKLIST_ACTIVITY_EVIDENCES_SUCCESS,
  CHECKLIST_ACTIVITY_EVIDENCES_FAILURE,
  EDIT_ACTIVITY_REGISTRATIONS_REQUEST,
  EDIT_ACTIVITY_REGISTRATIONS_SUCCESS,
  EDIT_ACTIVITY_REGISTRATIONS_FAILURE,
  CHECKLIST_QUESTION_ANSWER_REQUEST,
  CHECKLIST_QUESTION_ANSWER_SUCCESS,
  CHECKLIST_QUESTION_ANSWER_FAILURE,
  GET_ACTIVITY_DETAILS_REQUEST,
  GET_ACTIVITY_DETAILS_SUCCESS,
  GET_ACTIVITY_DETAILS_FAILURE,
  GET_QUESTION_DETAILS_REQUEST,
  GET_QUESTION_DETAILS_SUCCESS,
  GET_QUESTION_DETAILS_FAILURE,
  GET_CUSTOMER_PROJECT_CHECKLISTS_REQUEST,
  GET_CUSTOMER_PROJECT_CHECKLISTS_SUCCESS,
  GET_CUSTOMER_PROJECT_CHECKLISTS_FAILURE,
  EDIT_ACTIVITY_ANSWER_REQUEST,
  EDIT_ACTIVITY_ANSWER_SUCCESS,
  EDIT_ACTIVITY_ANSWER_FAILURE,
  EDIT_ACTIVITY_ANSWER_EVIDENCES_REQUEST,
  EDIT_ACTIVITY_ANSWER_EVIDENCES_SUCCESS,
  EDIT_ACTIVITY_ANSWER_EVIDENCES_FAILURE,
  EDIT_QUESTION_ANSWER_EVIDENCES_REQUEST,
  EDIT_QUESTION_ANSWER_EVIDENCES_SUCCESS,
  EDIT_QUESTION_ANSWER_EVIDENCES_FAILURE,
  GET_CUSTOMER_PROJECT_THREATS_REQUEST,
  GET_CUSTOMER_PROJECT_THREATS_SUCCESS,
  GET_CUSTOMER_PROJECT_THREATS_FAILURE,
  GET_CUSTOMER_PROJECT_SCORE_CARD_REQUEST,
  GET_CUSTOMER_PROJECT_SCORE_CARD_SUCCESS,
  GET_CUSTOMER_PROJECT_SCORE_CARD_FAILURE,
  DELETE_CUSTOMER_PROJECT_CROWN_JEWEL_REQUEST,
  DELETE_CUSTOMER_PROJECT_CROWN_JEWEL_SUCCESS,
  DELETE_CUSTOMER_PROJECT_CROWN_JEWEL_FAILURE,
  EDIT_CUSTOMER_PROJECT_CSF_CORE_REQUEST,
  EDIT_CUSTOMER_PROJECT_CSF_CORE_SUCCESS,
  EDIT_CUSTOMER_PROJECT_CSF_CORE_FAILURE,
  EDIT_CUSTOMER_PROJECT_CROWN_JEWEL_REQUEST,
  EDIT_CUSTOMER_PROJECT_CROWN_JEWEL_SUCCESS,
  EDIT_CUSTOMER_PROJECT_CROWN_JEWEL_FAILURE,
  GET_THREATS_CUSTOMER_PROJECT_DETAIL_REQUEST,
  GET_THREATS_CUSTOMER_PROJECT_DETAIL_SUCCESS,
  GET_THREATS_CUSTOMER_PROJECT_DETAIL_FAILURE,
  GET_ALL_REPORTS_TEXTS_CUSTOMER_PROJECT_REQUEST,
  GET_ALL_REPORTS_TEXTS_CUSTOMER_PROJECT_SUCCESS,
  GET_ALL_REPORTS_TEXTS_CUSTOMER_PROJECT_FAILURE,
  EDIT_QUESTIONNAIRE_ANSWERS_REQUEST,
  EDIT_QUESTIONNAIRE_ANSWERS_SUCCESS,
  EDIT_QUESTIONNAIRE_ANSWERS_FAILURE,
  SEND_ORGANOGRAM_REQUEST,
  SEND_ORGANOGRAM_SUCCESS,
  SEND_ORGANOGRAM_FAILURE,
  GET_ORGANOGRAM_CUSTOMER_PROJECT_REQUEST,
  GET_ORGANOGRAM_CUSTOMER_PROJECT_SUCCESS,
  GET_ORGANOGRAM_CUSTOMER_PROJECT_FAILURE,
  SEND_QUESTIONNAIRE_EVIDENCES_REQUEST,
  SEND_QUESTIONNAIRE_EVIDENCES_SUCCESS,
  SEND_QUESTIONNAIRE_EVIDENCES_FAILURE,
  REMOVE_QUESTIONNAIRE_EVIDENCES_REQUEST,
  REMOVE_QUESTIONNAIRE_EVIDENCES_SUCCESS,
  REMOVE_QUESTIONNAIRE_EVIDENCES_FAILURE,
  GET_ALL_REPORTS_PROJECT_CUSTOMER_PROJECT_REQUEST,
  GET_ALL_REPORTS_PROJECT_CUSTOMER_PROJECT_SUCCESS,
  GET_ALL_REPORTS_PROJECT_CUSTOMER_PROJECT_FAILURE,
  EDIT_APROVE_PDF_REQUEST,
  EDIT_APROVE_PDF_SUCCESS,
  EDIT_APROVE_PDF_FAILURE,
  SEND_INTERVIEW_EMAIL_REQUEST,
  SEND_INTERVIEW_EMAIL_SUCCESS,
  SEND_INTERVIEW_EMAIL_FAILURE,
  EDIT_ACTIVITY_REGISTRATIONS_REQUEST_DETAIL,
  EDIT_ACTIVITY_REGISTRATIONS_REQUEST_DETAIL_TWO,
  EDIT_CUSTOMER_PROJECT_STATUS_REQUEST,
  EDIT_CUSTOMER_PROJECT_STATUS_SUCCESS,
  EDIT_CUSTOMER_PROJECT_STATUS_FAILURE,
  GET_FILTERED_CUSTOMER_PROJECT_REQUEST,
  GET_FILTERED_CUSTOMER_PROJECT_SUCCESS,
  GET_FILTERED_CUSTOMER_PROJECT_FAILURE,
  GET_CUSTOMER_PROJECT_QUESTIONNAIRES_REQUEST,
  GET_CUSTOMER_PROJECT_QUESTIONNAIRES_SUCCESS,
  GET_CUSTOMER_PROJECT_QUESTIONNAIRES_FAILURE,
  NEW_GET_CUSTOMER_PROJECT_QUESTIONNAIRES_REQUEST,
  NEW_GET_CUSTOMER_PROJECT_QUESTIONNAIRES_SUCCESS,
  NEW_GET_CUSTOMER_PROJECT_QUESTIONNAIRES_FAILURE,
  GET_CUSTOMER_PROJECT_QUESTIONNAIRES_DETAIL_REQUEST,
  GET_CUSTOMER_PROJECT_QUESTIONNAIRES_DETAIL_SUCCESS,
  GET_CUSTOMER_PROJECT_QUESTIONNAIRES_DETAIL_FAILURE,
  PUT_PROJECT_STATUS_CHANGE_REQUEST,
  PUT_PROJECT_STATUS_CHANGE_SUCCESS,
  PUT_PROJECT_STATUS_CHANGE_FAILURE,
  GET_SCORE_CARD_CHART_REQUEST,
  GET_SCORE_CARD_CHART_SUCCESS,
  GET_SCORE_CARD_CHART_FAILURE,
  GET_RISK_DONUT_CHART_REQUEST,
  GET_RISK_DONUT_CHART_SUCCESS,
  GET_RISK_DONUT_CHART_FAILURE,
  GET_RISK_HEAT_MAP_REQUEST,
  GET_RISK_HEAT_MAP_SUCCESS,
  GET_RISK_HEAT_MAP_FAILURE,
  UPDATE_PROJECT_EXPIRATION_DATE_REQUEST,
  UPDATE_PROJECT_EXPIRATION_DATE_SUCCESS,
  UPDATE_PROJECT_EXPIRATION_DATE_FAILURE,
  GET_PROJECT_CUSTOMER_DETAIL_REQUEST,
  GET_PROJECT_CUSTOMER_DETAIL_SUCCESS,
  GET_PROJECT_CUSTOMER_DETAIL_FAILURE,
  GET_ALL_CUSTOMER_PROJECT_CUSTOMER_REQUEST,
  GET_ALL_CUSTOMER_PROJECT_CUSTOMER_SUCCESS,
  GET_ALL_CUSTOMER_PROJECT_CUSTOMER_FAILURE,
  GET_ALL_ENV_ASSESSMENT_CUSTOMERPROJECT_REQUEST,
  GET_ALL_ENV_ASSESSMENT_CUSTOMERPROJECT_SUCCESS,
  GET_ALL_ENV_ASSESSMENT_CUSTOMERPROJECT_FAILURE,
  PUT_IDENTIFIED_QUANTITY_REQUEST,
  PUT_IDENTIFIED_QUANTITY_SUCCESS,
  PUT_IDENTIFIED_QUANTITY_FAILURE,
  PUT_AUDITED_INTERWEES_REQUEST,
  PUT_AUDITED_INTERWEES_SUCCESS,
  PUT_AUDITED_INTERWEES_FAILURE,
} = CustomerProjectActions;

function editCustomerProjectRequest(
  customerProjectId: string,
  editCustomerProject: CustomerProjectWithAddAndRemove,
  shouldRedirect?: boolean,
  ProjectTypeId?: string,
  isCheckListsOrActivity?: boolean,
): Action {
  return {
    type: EDIT_CUSTOMER_PROJECT_REQUEST,
    payload: {
      customerProjectId,
      editCustomerProject,
      shouldRedirect,
      ProjectTypeId,
      isCheckListsOrActivity,
    }
  };
}

function editCustomerProjectSuccess(): Action {
  return {
    type: EDIT_CUSTOMER_PROJECT_SUCCESS,
  };
}

function editCustomerProjectFailure(): Action {
  return {
    type: EDIT_CUSTOMER_PROJECT_FAILURE,
  };
}

function editQuestionnaireInterweesVinculationsRequest(questionnaireId: string, customerProjectId: string, questionnaireInterwees: QuestionnaireInterviwees): Action {
  return {
    type: EDIT_QUESTIONNAIRES_INTERWEES_REQUEST,
    payload: {
      questionnaireId,
      customerProjectId,
      questionnaireInterwees,
    }
  };
}

function editQuestionnaireInterweesVinculationsSuccess(): Action {
  return {
    type: EDIT_QUESTIONNAIRES_INTERWEES_SUCCESS,
  };
}

function editQuestionnaireInterweesVinculationsFailure(): Action {
  return {
    type: EDIT_QUESTIONNAIRES_INTERWEES_FAILURE,
  };
}

function getCustomerProjectDetailRequest(customerProjectId: string): Action {
  return {
    type: GET_CUSTOMER_PROJECT_DETAIL_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getCustomerProjectDetailSuccess(customerProjectDetail: CustomerProjectDetail): Action {
  return {
    type: GET_CUSTOMER_PROJECT_DETAIL_SUCCESS,
    payload: {
      customerProjectDetail
    }
  };
}

function getCustomerProjectDetailFailure(): Action {
  return {
    type: GET_CUSTOMER_PROJECT_DETAIL_FAILURE,
  };
}

function getCustomerProjectChecklistsRequest(customerProjectId: string): Action {
  return {
    type: GET_CUSTOMER_PROJECT_CHECKLISTS_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getCustomerProjectChecklistsSuccess(customerProjectChecklists: CustomerProjectsChecklists[]): Action {
  return {
    type: GET_CUSTOMER_PROJECT_CHECKLISTS_SUCCESS,
    payload: {
      customerProjectChecklists
    }
  };
}

function getCustomerProjectChecklistsFailure(): Action {
  return {
    type: GET_CUSTOMER_PROJECT_CHECKLISTS_FAILURE,
  };
}

function getQuestionnaireAnswerRequest(answerId: string): Action {
  return {
    type: GET_QUESTIONNAIRE_ANSWER_DETAIL_REQUEST,
    payload: {
      answerId
    }
  };
}

function getQuestionnaireAnswerSuccess(customerProjectDetail: CustomerProjectDetail): Action {
  return {
    type: GET_QUESTIONNAIRE_ANSWER_DETAIL_SUCCESS,
    payload: {
      customerProjectDetail
    }
  };
}

function getQuestionnaireAnswerFailure(): Action {
  return {
    type: GET_QUESTIONNAIRE_ANSWER_DETAIL_FAILURE,
  };
}

function deleteCustomerProjectQuestionnaireRequest(questionnaireId: string): Action {
  return {
    type: DELETE_CUSTOMER_PROJECT_QUESTIONNAIRE_REQUEST,
    payload: {
      questionnaireId,
    }
  };
}

function deleteCustomerProjectQuestionnaireSuccess(): Action {
  return {
    type: DELETE_CUSTOMER_PROJECT_QUESTIONNAIRE_SUCCESS,
  };
}

function deleteCustomerProjectQuestionnaireFailure(): Action {
  return {
    type: DELETE_CUSTOMER_PROJECT_QUESTIONNAIRE_FAILURE,
  };
}

function getCustomerQuestionnaireAnswerDetailRequest(customerProjectId: string): Action {
  return {
    type: GET_CUSTOMER_QUESTIONNAIRE_ANSWER_DETAIL_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getCustomerQuestionnaireAnswerDetailSuccess(): Action {
  return {
    type: GET_CUSTOMER_QUESTIONNAIRE_ANSWER_DETAIL_SUCCESS,
  };
}

function getCustomerQuestionnaireAnswerDetailFailure(): Action {
  return {
    type: GET_CUSTOMER_QUESTIONNAIRE_ANSWER_DETAIL_FAILURE,
  };
}

function getCustomerProjectRequest(): Action {
  return {
    type: GET_ALL_CUSTOMER_PROJECT_REQUEST,
  };
}

function getCustomerProjectSuccess(customerProjects: CustomerProject[]): Action {
  return {
    type: GET_ALL_CUSTOMER_PROJECT_SUCCESS,
    payload: {
      customerProjects
    }
  };
}

function getCustomerProjectFailure(): Action {
  return {
    type: GET_ALL_CUSTOMER_PROJECT_FAILURE,
  };
}

function getControlProjectTypeRequest(isCustomerProjectPage = false): Action {
  return {
    type: GET_ALL_CONTROL_PROJECT_TYPE_REQUEST,
    payload: {
      isCustomerProjectPage,
    },
  };
}

function getControlProjectTypeSuccess(projectType: ProjectType[]): Action {
  return {
    type: GET_ALL_CONTROL_PROJECT_TYPE_SUCCESS,
    payload: {
      projectType
    }
  };
}

function getControlProjectTypeFailure(): Action {
  return {
    type: GET_ALL_CONTROL_PROJECT_TYPE_FAILURE,
  };
}

function getControlCustomersRequest(isCustomerProjectPage = false): Action {
  return {
    type: GET_ALL_CONTROL_CUSTOMERS_REQUEST,
    payload: {
      isCustomerProjectPage,
    },
  };
}

function getControlCustomersSuccess(customer: Customer[]): Action {
  return {
    type: GET_ALL_CONTROL_CUSTOMERS_SUCCESS,
    payload: {
      customer
    }
  };
}

function getControlCustomersFailure(): Action {
  return {
    type: GET_ALL_CONTROL_CUSTOMERS_FAILURE,
  };
}

function getAnalystsRequest(isCustomerProjectPage = false): Action {
  return {
    type: GET_ALL_ANALYSTS_REQUEST,
    payload: {
      isCustomerProjectPage,
    },
  };
}

function getAnalystsSuccess(analysts: Analysts[]): Action {
  return {
    type: GET_ALL_ANALYSTS_SUCCESS,
    payload: {
      analysts
    }
  };
}

function getAnalystsFailure(): Action {
  return {
    type: GET_ALL_ANALYSTS_FAILURE,
  };
}

function createNewCustomerProjectRequest(newCustomerProject: PostCustomerProjectBody): Action {
  return {
    type: CREATE_NEW_CUSTOMER_PROJECT_REQUEST,
    payload: {
      newCustomerProject
    }
  };
}

function createNewCustomerProjectRequestSuccess(): Action {
  return {
    type: CREATE_NEW_CUSTOMER_PROJECT_SUCCESS,
  };
}

function createNewCustomerProjectRequestFailure(): Action {
  return {
    type: CREATE_NEW_CUSTOMER_PROJECT_FAILURE,
  };
}

function createAnalystQuestionnaireAnswerRequest(
  answers: Answers[],
  questions_create: AnalystQuestionCreate[],
  analystId: string | null,
  projectId: string,
  questionnaireId: string,
  projectName: string,
): Action {
  return {
    type: ANALYST_ANSWERING_QUESTION_REQUEST,
    payload: {
      answers,
      questions_create,
      analystId,
      projectId,
      questionnaireId,
      projectName,
    }
  };
}

function createAnalystQuestionnaireAnswerSuccess(): Action {
  return {
    type: ANALYST_ANSWERING_QUESTION_SUCCESS,
  };
}

function createAnalystQuestionnaireAnswerFailure(): Action {
  return {
    type: ANALYST_ANSWERING_QUESTION_FAILURE,
  };
}

function createActivityAnswerRequest(
  answerBody: ChecklistActivityAnswerPayloadBody,
  evidencesBody: any[],
  disabledChange: string,
): Action {
  return {
    type: CHECKLIST_ACTIVITY_ANSWER_REQUEST,
    payload: {
      answerBody,
      evidencesBody,
      disabledChange,
    }
  };
}

function createActivityAnswerSuccess(): Action {
  return {
    type: CHECKLIST_ACTIVITY_ANSWER_SUCCESS,
  };
}

function createActivityAnswerFailure(): Action {
  return {
    type: CHECKLIST_ACTIVITY_ANSWER_FAILURE,
  };
}

function editActivityAnswerRequest(answerBody: string, activityId: string, evidencesBody: any[]): Action {
  return {
    type: EDIT_ACTIVITY_ANSWER_REQUEST,
    payload: {
      answerBody,
      activityId,
      evidencesBody
    }
  };
}

function editActivityAnswerSuccess(): Action {
  return {
    type: EDIT_ACTIVITY_ANSWER_SUCCESS,
  };
}

function editActivityAnswerFailure(): Action {
  return {
    type: EDIT_ACTIVITY_ANSWER_FAILURE,
  };
}

function getActivityDetailsRequest(activityId: string): Action {
  return {
    type: GET_ACTIVITY_DETAILS_REQUEST,
    payload: {
      activityId,
    }
  };
}

function getActivityDetailsSuccess(activityBody: ChecklistActivityDetail): Action {
  return {
    type: GET_ACTIVITY_DETAILS_SUCCESS,
    payload: {
      activityBody
    }
  };
}

function getActivityDetailsFailure(): Action {
  return {
    type: GET_ACTIVITY_DETAILS_FAILURE,
  };
}

function getQuestionDetailsRequest(questionId: string): Action {
  return {
    type: GET_QUESTION_DETAILS_REQUEST,
    payload: {
      questionId,
    }
  };
}

function getQuestionDetailsSuccess(questionBody: ChecklistActivityDetail): Action {
  return {
    type: GET_QUESTION_DETAILS_SUCCESS,
    payload: {
      questionBody
    }
  };
}

function getQuestionDetailsFailure(): Action {
  return {
    type: GET_QUESTION_DETAILS_FAILURE,
  };
}

function createQuestionAnswerRequest(answerBody: ChecklistQuestionAnswerPayloadBody, evidencesBody: any[]): Action {
  return {
    type: CHECKLIST_QUESTION_ANSWER_REQUEST,
    payload: {
      answerBody,
      evidencesBody
    }
  };
}

function createQuestionAnswerSuccess(): Action {
  return {
    type: CHECKLIST_QUESTION_ANSWER_SUCCESS,
  };
}

function createQuestionAnswerFailure(): Action {
  return {
    type: CHECKLIST_QUESTION_ANSWER_FAILURE,
  };
}

function sendActivityEvidencesRequest(evidencesBody: any[], answerId: string): Action {
  return {
    type: CHECKLIST_ACTIVITY_EVIDENCES_REQUEST,
    payload: {
      evidencesBody,
      answerId,
    }
  };
}

function sendActivityEvidencesSuccess(): Action {
  return {
    type: CHECKLIST_ACTIVITY_EVIDENCES_SUCCESS,
  };
}

function sendActivityEvidencesFailure(): Action {
  return {
    type: CHECKLIST_ACTIVITY_EVIDENCES_FAILURE,
  };
}

function editActivityRegistrationsRequest(
  activityRegistrations: ActivityRegistrationsBody,
  activityId: string,
  redirectId: string,
  disableChange: string,
): Action {
  return {
    type: EDIT_ACTIVITY_REGISTRATIONS_REQUEST,
    payload: {
      activityRegistrations,
      activityId,
      redirectId,
      disableChange
    }
  };
}

function editActivityRegistrationsRequestDetail(
  activityRegistrations: ActivityRegistrationsBody,
  activityId: string,
  redirectId: string,
  questionnaireId: string,
  projectName: string,
  disableChange: string,
): Action {
  return {
    type: EDIT_ACTIVITY_REGISTRATIONS_REQUEST_DETAIL,
    payload: {
      activityRegistrations,
      activityId,
      redirectId,
      questionnaireId,
      projectName,
      disableChange,
    }
  };
}

function editActivityRegistrationsRequestDetailTwo(
  activityRegistrations: ActivityRegistrationsBody,
  activityId: string,
  disableChange: string,
  redirectId?: string,
): Action {
  return {
    type: EDIT_ACTIVITY_REGISTRATIONS_REQUEST_DETAIL_TWO,
    payload: {
      activityRegistrations,
      activityId,
      disableChange,
      redirectId,
    }
  };
}

function editActivityRegistrationsSuccess(): Action {
  return {
    type: EDIT_ACTIVITY_REGISTRATIONS_SUCCESS,
  };
}

function editActivityRegistrationsFailure(): Action {
  return {
    type: EDIT_ACTIVITY_REGISTRATIONS_FAILURE,
  };
}

function editActivityAnswerEvidencesRequest(
  newEvidences: any[],
  activityAnswerBody: ActivityAnswerEvidenceBodyType,
  activityId: string,
  customerProjectId: string,
  disableChange: string,
): Action {
  return {
    type: EDIT_ACTIVITY_ANSWER_EVIDENCES_REQUEST,
    payload: {
      activityAnswerBody,
      activityId,
      newEvidences,
      customerProjectId,
      disableChange
    }
  };
}

function editActivityAnswerEvidencesSuccess(): Action {
  return {
    type: EDIT_ACTIVITY_ANSWER_EVIDENCES_SUCCESS,
  };
}

function editActivityAnswerEvidencesFailure(): Action {
  return {
    type: EDIT_ACTIVITY_ANSWER_EVIDENCES_FAILURE,
  };
}

function editQuestionAnswerEvidencesRequest(newEvidences: any[], questionAnswerBody: QuestionAnswerEvidenceBodyType, questionId: string, customerProjectId: string): Action {
  return {
    type: EDIT_QUESTION_ANSWER_EVIDENCES_REQUEST,
    payload: {
      questionAnswerBody,
      questionId,
      newEvidences,
      customerProjectId
    }
  };
}

function editQuestionAnswerEvidencesSuccess(): Action {
  return {
    type: EDIT_QUESTION_ANSWER_EVIDENCES_SUCCESS,
  };
}

function editQuestionAnswerEvidencesFailure(): Action {
  return {
    type: EDIT_QUESTION_ANSWER_EVIDENCES_FAILURE,
  };
}

function getCustomerProjectThreatsRequest(customerProjectId: string): Action {
  return {
    type: GET_CUSTOMER_PROJECT_THREATS_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getCustomerProjectThreatsSuccess(projectsThreats: ProjectsThreats): Action {
  return {
    type: GET_CUSTOMER_PROJECT_THREATS_SUCCESS,
    payload: {
      projectsThreats
    }
  };
}

function getCustomerProjectThreatsFailure(): Action {
  return {
    type: GET_CUSTOMER_PROJECT_THREATS_FAILURE,
  };
}

function getCustomerProjectScoreCardRequest(customerProjectId: string): Action {
  return {
    type: GET_CUSTOMER_PROJECT_SCORE_CARD_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getCustomerProjectThreatsDetailRequest(threatId: string): Action {
  return {
    type: GET_THREATS_CUSTOMER_PROJECT_DETAIL_REQUEST,
    payload: {
      threatId,
    }
  };
}

function getCustomerProjectScoreCardSuccess(customerProjectScoreCard: CustomerProjectScoreCard): Action {
  return {
    type: GET_CUSTOMER_PROJECT_SCORE_CARD_SUCCESS,
    payload: {
      customerProjectScoreCard
    }
  };
}

function getCustomerProjectThreatsDetailSuccess(customerProjectThreats: CustomerProjectsThreats): Action {
  return {
    type: GET_THREATS_CUSTOMER_PROJECT_DETAIL_SUCCESS,
    payload: {
      customerProjectThreats
    }
  };
}

function getCustomerProjectScoreCardFailure(): Action {
  return {
    type: GET_CUSTOMER_PROJECT_SCORE_CARD_FAILURE,
  };
}

function deleteCustomerProjectCrownJewelsRequest(crownJewelId: string, customerProjectId: string): Action {
  return {
    type: DELETE_CUSTOMER_PROJECT_CROWN_JEWEL_REQUEST,
    payload: {
      crownJewelId,
      customerProjectId
    }
  };
}

function deleteCustomerProjectCrownJewelsSuccess(): Action {
  return {
    type: DELETE_CUSTOMER_PROJECT_CROWN_JEWEL_SUCCESS,
  };
}

function deleteCustomerProjectCrownJewelsFailure(): Action {
  return {
    type: DELETE_CUSTOMER_PROJECT_CROWN_JEWEL_FAILURE,
  };
}

function editCustomerProjectCSFCoreRequest(
  CSFCoreId: string,
  maturityLevel: string,
  customerProjectId: string,
  version: string,
): Action {
  return {
    type: EDIT_CUSTOMER_PROJECT_CSF_CORE_REQUEST,
    payload: {
      CSFCoreId,
      maturityLevel,
      customerProjectId,
      version,
    }
  };
}

function editCustomerProjectCSFCoreSuccess(): Action {
  return {
    type: EDIT_CUSTOMER_PROJECT_CSF_CORE_SUCCESS,
  };
}

function editCustomerProjectCSFCoreFailure(): Action {
  return {
    type: EDIT_CUSTOMER_PROJECT_CSF_CORE_FAILURE,
  };
}

function editCustomerProjectCrownJewelRequest(
  crownJewelsId: string,
  result: string,
  weight: string,
  customerProjectId: string,
): Action {
  return {
    type: EDIT_CUSTOMER_PROJECT_CROWN_JEWEL_REQUEST,
    payload: {
      crownJewelsId,
      result,
      weight,
      customerProjectId,
    }
  };
}

function editCustomerProjectCrownJewelSuccess(): Action {
  return {
    type: EDIT_CUSTOMER_PROJECT_CROWN_JEWEL_SUCCESS,
  };
}

function editCustomerProjectCrownJewelFailure(): Action {
  return {
    type: EDIT_CUSTOMER_PROJECT_CROWN_JEWEL_FAILURE,
  };
}


function getCustomerProjectThreatsDetailFailure(): Action {
  return {
    type: GET_THREATS_CUSTOMER_PROJECT_DETAIL_FAILURE,
  };
}

function getCustomerProjectTextsRequest(customerProjectId: string): Action {
  return {
    type: GET_ALL_REPORTS_TEXTS_CUSTOMER_PROJECT_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getCustomerProjectTextsSuccess(allReportsText: ReportsText): Action {
  return {
    type: GET_ALL_REPORTS_TEXTS_CUSTOMER_PROJECT_SUCCESS,
    payload: {
      allReportsText
    }
  };
}

function getCustomerProjectTextsFailure(): Action {
  return {
    type: GET_ALL_REPORTS_TEXTS_CUSTOMER_PROJECT_FAILURE,
  };
}

function editQuestionnaireAnswersRequest(
  answers: EditQuestionnaireAnswer[],
  questions_create: AnalystQuestionCreate[],
  customerProjectId: string,
  evidencesToRemove: EvidencesToRemove[],
  questionnaireId: string,
  projectName: string,
  disabledChange: string,
  handleGetAnswer: (pAnswer: Answer) => void,
): Action {
  return {
    type: EDIT_QUESTIONNAIRE_ANSWERS_REQUEST,
    payload: {
      answers,
      questions_create,
      customerProjectId,
      evidencesToRemove,
      questionnaireId,
      projectName,
      disabledChange,
      handleGetAnswer
    }
  };
}

function editQuestionnaireAnswersSuccess(): Action {
  return {
    type: EDIT_QUESTIONNAIRE_ANSWERS_SUCCESS,
  };
}

function editQuestionnaireAnswersFailure(): Action {
  return {
    type: EDIT_QUESTIONNAIRE_ANSWERS_FAILURE,
  };
}

function sendOrganogramRequest(organogramBody: any[], customerProjectId: string): Action {
  return {
    type: SEND_ORGANOGRAM_REQUEST,
    payload: {
      organogramBody,
      customerProjectId,
    }
  };
}

function sendOrganogramSuccess(): Action {
  return {
    type: SEND_ORGANOGRAM_SUCCESS,
  };
}

function sendOrganogramFailure(): Action {
  return {
    type: SEND_ORGANOGRAM_FAILURE,
  };
}


function getCustomerProjectOrganogramsRequest(customerProjectId: string): Action {
  return {
    type: GET_ORGANOGRAM_CUSTOMER_PROJECT_REQUEST,
    payload: {
      customerProjectId
    }
  };
}

function getCustomerProjectOrganogramsSuccess(organogram: OrganogramType): Action {
  return {
    type: GET_ORGANOGRAM_CUSTOMER_PROJECT_SUCCESS,
    payload: {
      organogram
    }
  };
}

function getCustomerProjectOrganogramsFailure(): Action {
  return {
    type: GET_ORGANOGRAM_CUSTOMER_PROJECT_FAILURE,
  };
}

function sendQuestionnaireEvidencesRequest(evidencesIdsBody: EvidencesIdsBody[], pLocal?: LocalType): Action {
  return {
    type: SEND_QUESTIONNAIRE_EVIDENCES_REQUEST,
    payload: {
      evidencesIdsBody,
      pLocal
    }
  };
}

function sendQuestionnaireEvidencesSuccess(): Action {
  return {
    type: SEND_QUESTIONNAIRE_EVIDENCES_SUCCESS,
  };
}

function sendQuestionnaireEvidencesFailure(): Action {
  return {
    type: SEND_QUESTIONNAIRE_EVIDENCES_FAILURE,
  };
}

function removeQuestionnaireEvidencesRequest(answers: AnswersEvidencesToRemove, pLocal?: LocalType): Action {
  return {
    type: REMOVE_QUESTIONNAIRE_EVIDENCES_REQUEST,
    payload: {
      answers,
      pLocal
    }
  };
}

function removeQuestionnaireEvidencesSuccess(): Action {
  return {
    type: REMOVE_QUESTIONNAIRE_EVIDENCES_SUCCESS,
  };
}

function removeQuestionnaireEvidencesFailure(): Action {
  return {
    type: REMOVE_QUESTIONNAIRE_EVIDENCES_FAILURE,
  };
}

function getCustomerProjectReportsProjectRequest(customerProjectId: string): Action {
  return {
    type: GET_ALL_REPORTS_PROJECT_CUSTOMER_PROJECT_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getCustomerProjectReportsProjectSuccess(report: ReportsBody): Action {
  return {
    type: GET_ALL_REPORTS_PROJECT_CUSTOMER_PROJECT_SUCCESS,
    payload: {
      report
    }
  };
}

function getCustomerProjectReportsProjectFailure(): Action {
  return {
    type: GET_ALL_REPORTS_PROJECT_CUSTOMER_PROJECT_FAILURE,
  };
}

function editReportApprovePdfRequest(customerProjectId: string, approveStatus: boolean): Action {
  return {
    type: EDIT_APROVE_PDF_REQUEST,
    payload: {
      customerProjectId,
      approveStatus
    }
  };
}

function editReportApprovePdfSuccess(): Action {
  return {
    type: EDIT_APROVE_PDF_SUCCESS,
  };
}

function editReportApprovePdfFailure(): Action {
  return {
    type: EDIT_APROVE_PDF_FAILURE,
  };
}

function sendInterviewEmailRequest(questionnaireCustomerProjectId: string): Action {
  return {
    type: SEND_INTERVIEW_EMAIL_REQUEST,
    payload: {
      questionnaireCustomerProjectId
    }
  };
}

function sendInterviewEmailSuccess(): Action {
  return {
    type: SEND_INTERVIEW_EMAIL_SUCCESS,
  };
}

function sendInterviewEmailFailure(): Action {
  return {
    type: SEND_INTERVIEW_EMAIL_FAILURE,
  };
}

function editCustomerProjectStatusRequest(
  customerProjectId: string,
  changeStatusBody: ChangeStatusBodyType,
): Action {
  return {
    type: EDIT_CUSTOMER_PROJECT_STATUS_REQUEST,
    payload: {
      customerProjectId,
      changeStatusBody,
    }
  };
}

function editCustomerProjectStatusSuccess(): Action {
  return {
    type: EDIT_CUSTOMER_PROJECT_STATUS_SUCCESS,
  };
}

function editCustomerProjectStatusFailure(): Action {
  return {
    type: EDIT_CUSTOMER_PROJECT_STATUS_FAILURE,
  };
}

function getSearchFilteredCustomerProjectRequest(
  filteredCustomerProjects: Array<string | boolean | null>,
  isActive: boolean | null,
  status_project: string | null,
  sector: string,
  subSector: string,
  segment: string,
  anonymized: boolean | null,
  showMessenger = true,  
): Action {
  return {
    type: GET_FILTERED_CUSTOMER_PROJECT_REQUEST,
    payload: {
      filteredCustomerProjects,
      isActive,
      status_project,
      sector,
      subSector,
      segment,
      anonymized,
      showMessenger,
    },
  };
}

function getSearchFilteredCustomerProjectSuccess(customerProjects: CustomerProject[]): Action {
  return {
    type: GET_FILTERED_CUSTOMER_PROJECT_SUCCESS,
    payload: {
      customerProjects,
    },
  };
}

function getSearchFilteredCustomerProjectFailure(): Action {
  return {
    type: GET_FILTERED_CUSTOMER_PROJECT_FAILURE,
  };
}

function getCustomerProjectQuestionnairesRequest(customerProjectId: string): Action {
  return {
    type: GET_CUSTOMER_PROJECT_QUESTIONNAIRES_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getCustomerProjectQuestionnairesSuccess(customerProjectQuestionnaires: CustomerProjectsQuestionnaires[]): Action {
  return {
    type: GET_CUSTOMER_PROJECT_QUESTIONNAIRES_SUCCESS,
    payload: {
      customerProjectQuestionnaires
    }
  };
}

function getCustomerProjectQuestionnairesFailure(): Action {
  return {
    type: GET_CUSTOMER_PROJECT_QUESTIONNAIRES_FAILURE,
  };
}

function getNewCustomerProjectQuestionnairesRequest(customerProjectId: string): Action {
  return {
    type: NEW_GET_CUSTOMER_PROJECT_QUESTIONNAIRES_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getNewCustomerProjectQuestionnairesSuccess(newCustomerProjectQuestionnaires: NewCustomerProjectsQuestionnaires[]): Action {
  return {
    type: NEW_GET_CUSTOMER_PROJECT_QUESTIONNAIRES_SUCCESS,
    payload: {
      newCustomerProjectQuestionnaires
    }
  };
}

function getNewCustomerProjectQuestionnairesFailure(): Action {
  return {
    type: NEW_GET_CUSTOMER_PROJECT_QUESTIONNAIRES_FAILURE,
  };
}

function getCustomerProjectQuestionnairesDetailRequest(customerProjectQuestionnaireId: string): Action {
  return {
    type: GET_CUSTOMER_PROJECT_QUESTIONNAIRES_DETAIL_REQUEST,
    payload: {
      customerProjectQuestionnaireId,
    }
  };
}

function getCustomerProjectQuestionnairesDetailSuccess(customerProjectsQuestionnaireDetails: CustomerProjectsQuestionnaireDetails[]): Action {
  return {
    type: GET_CUSTOMER_PROJECT_QUESTIONNAIRES_DETAIL_SUCCESS,
    payload: {
      customerProjectsQuestionnaireDetails
    }
  };
}

function getCustomerProjectQuestionnairesDetailFailure(): Action {
  return {
    type: GET_CUSTOMER_PROJECT_QUESTIONNAIRES_DETAIL_FAILURE,
  };
}

function putProjectStatusChangeRequest(
  customerProjectId: string,
  project_status: string
): Action {
  return {
    type: PUT_PROJECT_STATUS_CHANGE_REQUEST,
    payload: {
      customerProjectId,
      project_status
    }
  };
}

function putProjectStatusChangeSuccess(): Action {
  return {
    type: PUT_PROJECT_STATUS_CHANGE_SUCCESS,
  };
}

function putProjectStatusChangeFailure(): Action {
  return {
    type: PUT_PROJECT_STATUS_CHANGE_FAILURE,
  };
}

function getCustomersProjectScoreCardRadarChatRequest(
  customerProjectId: string
): Action {
  return {
    type: GET_SCORE_CARD_CHART_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getCustomersProjectScoreCardRadarChatSuccess(
  scoreCardRadarChart: ScoreCardRadarChart
): Action {
  return {
    type: GET_SCORE_CARD_CHART_SUCCESS,
    payload: {
      scoreCardRadarChart
    }
  };
}

function getCustomersProjectScoreCardRadarChatFailure(): Action {
  return {
    type: GET_SCORE_CARD_CHART_FAILURE,
  };
}

function getRiskDonutChartRequest(
  customerProjectId: string
): Action {
  return {
    type: GET_RISK_DONUT_CHART_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getRiskDonutChartSuccess(
  riskDonutChart: RiskDonutChart
): Action {
  return {
    type: GET_RISK_DONUT_CHART_SUCCESS,
    payload: {
      riskDonutChart
    }
  };
}

function getRiskDonutChartFailure(): Action {
  return {
    type: GET_RISK_DONUT_CHART_FAILURE,
  };
}

function getRiskHeatMapRequest(
  customerProjectId: string
): Action {
  return {
    type: GET_RISK_HEAT_MAP_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getRiskHeatMapSuccess(
  riskHeatMap: RiskHeatMap
): Action {
  return {
    type: GET_RISK_HEAT_MAP_SUCCESS,
    payload: {
      riskHeatMap
    }
  };
}

function getRiskHeatMapFailure(): Action {
  return {
    type: GET_RISK_HEAT_MAP_FAILURE,
  };
}

function updateProjectExpirationDateRequest({
  customerProjectId,
  project_expiration_date,
  goToListing,
}: ProjectExpirationDatePayloadApi): Action {
  return {
    type: UPDATE_PROJECT_EXPIRATION_DATE_REQUEST,
    payload: {
      customerProjectId,
      project_expiration_date,
      goToListing,
    }
  };
}

function updateProjectExpirationDateSuccess(): Action {
  return {
    type: UPDATE_PROJECT_EXPIRATION_DATE_SUCCESS,
  };
}

function updateProjectExpirationDateFailure(): Action {
  return {
    type: UPDATE_PROJECT_EXPIRATION_DATE_FAILURE,
  };
}

function getProjectCustomerDetailRequest(customerProjectId: string): Action {
  return {
    type: GET_PROJECT_CUSTOMER_DETAIL_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getProjectCustomerDetailSuccess(customerProjectDetail: CustomerProjectDetail): Action {
  return {
    type: GET_PROJECT_CUSTOMER_DETAIL_SUCCESS,
    payload: {
      customerProjectDetail
    }
  };
}

function getProjectCustomerDetailFailure(): Action {
  return {
    type: GET_PROJECT_CUSTOMER_DETAIL_FAILURE,
  };
}

function getCustomerProjectCustomerRequest(): Action {
  return {
    type: GET_ALL_CUSTOMER_PROJECT_CUSTOMER_REQUEST,
  };
}

function getCustomerProjectCustomerSuccess(customersProjectsCustomer: CustomersProjectsCustomer): Action {
  return {
    type: GET_ALL_CUSTOMER_PROJECT_CUSTOMER_SUCCESS,
    payload: {
      customersProjectsCustomer
    }
  };
}

function getCustomerProjecCustomertFailure(): Action {
  return {
    type: GET_ALL_CUSTOMER_PROJECT_CUSTOMER_FAILURE,
  };
}

function getEnvAssessmentCustomerProjectRequest(customerProjectId: string): Action {
  return {
    type: GET_ALL_ENV_ASSESSMENT_CUSTOMERPROJECT_REQUEST,
    payload: {
      customerProjectId,
    }
  };
}

function getEnvAssessmentCustomerProjectSuccess(allEnvAssessmentCustomerProject: AllEnvAssessmentCustomerProject): Action {
  return {
    type: GET_ALL_ENV_ASSESSMENT_CUSTOMERPROJECT_SUCCESS,
    payload: {
      allEnvAssessmentCustomerProject
    }
  };
}

function getEnvAssessmentCustomerProjectFailure(): Action {
  return {
    type: GET_ALL_ENV_ASSESSMENT_CUSTOMERPROJECT_FAILURE,
  };
}

function putIdentifiedQuantityRequest(
  { 
    id, 
    identified_quantity,
    customerProjectId
  }: IdentifiedQuantityPayload): Action {
  return {
    type: PUT_IDENTIFIED_QUANTITY_REQUEST,
    payload: {
      id, 
      identified_quantity,
      customerProjectId
    }
  };
}

function putIdentifiedQuantitySuccess(): Action {
  return {
    type: PUT_IDENTIFIED_QUANTITY_SUCCESS,
  };
}

function putIdentifiedQuantityFailure(): Action {
  return {
    type: PUT_IDENTIFIED_QUANTITY_FAILURE,
  };
}

function putLinkAuditedIntervieweesRequest({
  customerProjectQuestionnaireId,
  customerProjectId,
  audit_interviewees,
  customers_users,
  linkUser,
}: LinkAuditedIntervieweesPayload): Action {
  return {
    type: PUT_AUDITED_INTERWEES_REQUEST,
    payload: {
      customerProjectQuestionnaireId,
      customerProjectId,
      audit_interviewees,
      customers_users,
      linkUser,
    }
  };
}

function putLinkAuditedIntervieweesSuccess(): Action {
  return {
    type: PUT_AUDITED_INTERWEES_SUCCESS,
  };
}

function putLinkAuditedIntervieweesFailure(): Action {
  return {
    type: PUT_AUDITED_INTERWEES_FAILURE,
  };
}


export const customerProjectActionsFunctions = {
  sendInterviewEmailRequest,
  sendInterviewEmailSuccess,
  sendInterviewEmailFailure,
  editReportApprovePdfRequest,
  editReportApprovePdfSuccess,
  editReportApprovePdfFailure,
  getCustomerProjectReportsProjectRequest,
  getCustomerProjectReportsProjectSuccess,
  getCustomerProjectReportsProjectFailure,
  removeQuestionnaireEvidencesRequest,
  removeQuestionnaireEvidencesSuccess,
  removeQuestionnaireEvidencesFailure,
  sendQuestionnaireEvidencesRequest,
  sendQuestionnaireEvidencesSuccess,
  sendQuestionnaireEvidencesFailure,
  getCustomerProjectOrganogramsRequest,
  getCustomerProjectOrganogramsSuccess,
  getCustomerProjectOrganogramsFailure,
  sendOrganogramRequest,
  sendOrganogramSuccess,
  sendOrganogramFailure,
  editQuestionnaireAnswersRequest,
  editQuestionnaireAnswersSuccess,
  editQuestionnaireAnswersFailure,
  getCustomerProjectTextsRequest,
  getCustomerProjectTextsSuccess,
  getCustomerProjectTextsFailure,
  editCustomerProjectCrownJewelRequest,
  editCustomerProjectCrownJewelSuccess,
  editCustomerProjectCrownJewelFailure,
  editCustomerProjectCSFCoreRequest,
  editCustomerProjectCSFCoreSuccess,
  editCustomerProjectCSFCoreFailure,
  deleteCustomerProjectCrownJewelsRequest,
  deleteCustomerProjectCrownJewelsSuccess,
  deleteCustomerProjectCrownJewelsFailure,
  createActivityAnswerRequest,
  createActivityAnswerSuccess,
  createActivityAnswerFailure,
  getCustomerProjectRequest,
  getCustomerProjectSuccess,
  getCustomerProjectFailure,
  createNewCustomerProjectRequest,
  createNewCustomerProjectRequestSuccess,
  createNewCustomerProjectRequestFailure,
  createAnalystQuestionnaireAnswerRequest,
  createAnalystQuestionnaireAnswerSuccess,
  createAnalystQuestionnaireAnswerFailure,
  getAnalystsRequest,
  getAnalystsSuccess,
  getAnalystsFailure,
  getControlCustomersRequest,
  getControlCustomersSuccess,
  getControlCustomersFailure,
  getControlProjectTypeRequest,
  getControlProjectTypeSuccess,
  getControlProjectTypeFailure,
  editCustomerProjectRequest,
  editCustomerProjectSuccess,
  editCustomerProjectFailure,
  getCustomerProjectDetailRequest,
  getCustomerProjectDetailSuccess,
  getCustomerProjectDetailFailure,
  editQuestionnaireInterweesVinculationsRequest,
  editQuestionnaireInterweesVinculationsSuccess,
  editQuestionnaireInterweesVinculationsFailure,
  getCustomerQuestionnaireAnswerDetailRequest,
  getCustomerQuestionnaireAnswerDetailSuccess,
  getCustomerQuestionnaireAnswerDetailFailure,
  deleteCustomerProjectQuestionnaireRequest,
  deleteCustomerProjectQuestionnaireSuccess,
  deleteCustomerProjectQuestionnaireFailure,
  getQuestionnaireAnswerRequest,
  getQuestionnaireAnswerSuccess,
  getQuestionnaireAnswerFailure,
  sendActivityEvidencesRequest,
  sendActivityEvidencesSuccess,
  sendActivityEvidencesFailure,
  editActivityRegistrationsRequest,
  editActivityRegistrationsSuccess,
  editActivityRegistrationsFailure,
  createQuestionAnswerRequest,
  createQuestionAnswerSuccess,
  createQuestionAnswerFailure,
  getActivityDetailsRequest,
  getActivityDetailsSuccess,
  getActivityDetailsFailure,
  getQuestionDetailsRequest,
  getQuestionDetailsSuccess,
  getQuestionDetailsFailure,
  getCustomerProjectChecklistsRequest,
  getCustomerProjectChecklistsSuccess,
  getCustomerProjectChecklistsFailure,
  editActivityAnswerRequest,
  editActivityAnswerSuccess,
  editActivityAnswerFailure,
  editActivityAnswerEvidencesRequest,
  editActivityAnswerEvidencesSuccess,
  editActivityAnswerEvidencesFailure,
  editQuestionAnswerEvidencesRequest,
  editQuestionAnswerEvidencesSuccess,
  editQuestionAnswerEvidencesFailure,
  getCustomerProjectThreatsRequest,
  getCustomerProjectThreatsSuccess,
  getCustomerProjectThreatsFailure,
  getCustomerProjectScoreCardRequest,
  getCustomerProjectScoreCardSuccess,
  getCustomerProjectScoreCardFailure,
  getCustomerProjectThreatsDetailRequest,
  getCustomerProjectThreatsDetailSuccess,
  getCustomerProjectThreatsDetailFailure,
  editActivityRegistrationsRequestDetail,
  editActivityRegistrationsRequestDetailTwo,
  editCustomerProjectStatusRequest,
  editCustomerProjectStatusSuccess,
  editCustomerProjectStatusFailure,
  getSearchFilteredCustomerProjectRequest,
  getSearchFilteredCustomerProjectSuccess,
  getSearchFilteredCustomerProjectFailure,

  getCustomerProjectQuestionnairesRequest,
  getCustomerProjectQuestionnairesSuccess,
  getCustomerProjectQuestionnairesFailure,
  getNewCustomerProjectQuestionnairesRequest,
  getNewCustomerProjectQuestionnairesSuccess,
  getNewCustomerProjectQuestionnairesFailure,
  getCustomerProjectQuestionnairesDetailRequest,
  getCustomerProjectQuestionnairesDetailSuccess,
  getCustomerProjectQuestionnairesDetailFailure,
  putProjectStatusChangeRequest,
  putProjectStatusChangeSuccess,
  putProjectStatusChangeFailure,
  getCustomersProjectScoreCardRadarChatRequest,
  getCustomersProjectScoreCardRadarChatSuccess,
  getCustomersProjectScoreCardRadarChatFailure,
  getRiskDonutChartRequest,
  getRiskDonutChartSuccess,
  getRiskDonutChartFailure,
  getRiskHeatMapRequest,
  getRiskHeatMapSuccess,
  getRiskHeatMapFailure,
  updateProjectExpirationDateRequest,
  updateProjectExpirationDateSuccess,
  updateProjectExpirationDateFailure,
  getProjectCustomerDetailRequest,
  getProjectCustomerDetailSuccess,
  getProjectCustomerDetailFailure,
  getCustomerProjectCustomerRequest,
  getCustomerProjectCustomerSuccess,
  getCustomerProjecCustomertFailure,
  getEnvAssessmentCustomerProjectRequest,
  getEnvAssessmentCustomerProjectSuccess,
  getEnvAssessmentCustomerProjectFailure,
  putIdentifiedQuantityRequest,
  putIdentifiedQuantitySuccess,
  putIdentifiedQuantityFailure,
  putLinkAuditedIntervieweesRequest,
  putLinkAuditedIntervieweesSuccess,
  putLinkAuditedIntervieweesFailure,
};
