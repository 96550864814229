import produce from 'immer';
import { Reducer } from 'redux';

import { ControlActions, ControlState } from './types';

const {
  EDIT_CONTROL_REQUEST,
  EDIT_CONTROL_SUCCESS,
  EDIT_CONTROL_FAILURE,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  GET_CURRENCIES_REQUEST,
  GET_CURRENCIES_SUCCESS,
  GET_CURRENCIES_FAILURE,
  CREATE_PRICINGS_REQUEST,
  CREATE_PRICINGS_SUCCESS,
  CREATE_PRICINGS_FAILURE,
  UPDATE_PRICINGS_REQUEST,
  UPDATE_PRICINGS_SUCCESS,
  UPDATE_PRICINGS_FAILURE,
  CREATE_CURRENCIES_REQUEST,
  CREATE_CURRENCIES_SUCCESS,
  CREATE_CURRENCIES_FAILURE,
  GET_CONTROL_DETAIL_REQUEST,
  GET_CONTROL_DETAIL_SUCCESS,
  GET_CONTROL_DETAIL_FAILURE,
  CREATE_NEW_CONTROL_REQUEST,
  CREATE_NEW_CONTROL_SUCCESS,
  CREATE_NEW_CONTROL_FAILURE,
  EDIT_CONTROL_STATUS_REQUEST,
  EDIT_CONTROL_STATUS_SUCCESS,
  EDIT_CONTROL_STATUS_FAILURE,
  EDIT_CONTROL_REGISTRATION_REQUEST,
  EDIT_CONTROL_REGISTRATION_SUCCESS,
  EDIT_CONTROL_REGISTRATION_FAILURE,  
} = ControlActions;

const initialState: ControlState = {
  controlDetail: {
    attack_complexity: [],
    attack_vector: [],
    availability: false,
    availability_level: [],
    benefit: 0,
    category: {
      id: '',
      name: '',
      description: '',
      importance: 0
    },
    class_name: {
      id: '',
      name: '',
    },
    confidentiality: false,
    confidentiality_level: [],
    control_type: {
      id: '',
      name: '',
    },
    cost: 0,
    cost_benefit: 0,
    detect: false,
    final_benefit: 0,
    friendly_id: '',
    group_name: {
      id: '',
      name: '',
    },
    id: '',
    identify: false,
    integrity: false,
    integrity_level: [],
    is_active: false,
    name: '',
    privileges_required: [],
    protect: false,
    recover: false,
    respond: false,
    risk_factors: [],
    scope: [],
    subcontrols: [],
    technical_capacity: 0,
    threats: [],
    type_name: {
      id: '',
      name: '',
    },
    user_interaction: [],
    vulnerabilities: [],
    products: [],
  },
  allCurrencies: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
};

export const controlReducer: Reducer<ControlState> = (
  state = initialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      
      case EDIT_CONTROL_REQUEST:
      case EDIT_CONTROL_SUCCESS:
      case EDIT_CONTROL_FAILURE:
      case CREATE_PRODUCT_REQUEST:
      case CREATE_PRODUCT_SUCCESS:
      case CREATE_PRODUCT_FAILURE:
      case UPDATE_PRODUCT_REQUEST:
      case UPDATE_PRODUCT_SUCCESS:
      case UPDATE_PRODUCT_FAILURE:
      case UPDATE_PRICINGS_REQUEST:
      case UPDATE_PRICINGS_SUCCESS:
      case UPDATE_PRICINGS_FAILURE:
      case CREATE_PRICINGS_REQUEST:
      case CREATE_PRICINGS_SUCCESS:
      case CREATE_PRICINGS_FAILURE:
      case CREATE_CURRENCIES_REQUEST:
      case CREATE_CURRENCIES_SUCCESS:
      case CREATE_CURRENCIES_FAILURE:
      case GET_CONTROL_DETAIL_REQUEST:
      case CREATE_NEW_CONTROL_REQUEST:
      case CREATE_NEW_CONTROL_SUCCESS:
      case CREATE_NEW_CONTROL_FAILURE:
      case EDIT_CONTROL_STATUS_REQUEST:
      case EDIT_CONTROL_STATUS_SUCCESS:
      case EDIT_CONTROL_STATUS_FAILURE:
      case EDIT_CONTROL_REGISTRATION_REQUEST:
      case EDIT_CONTROL_REGISTRATION_SUCCESS: 
      case EDIT_CONTROL_REGISTRATION_FAILURE:      
        break;

      case GET_CONTROL_DETAIL_SUCCESS: {
        const { control } = action.payload;
        draft.controlDetail = control;
        break;
      }

      case GET_CONTROL_DETAIL_FAILURE: {
        draft.controlDetail = {
          attack_complexity: [],
          attack_vector: [],
          availability: false,
          availability_level: [],
          benefit: 0,
          category: {
            id: '',
            name: '',
            description: '',
            importance: 0
          },
          class_name: {
            id: '',
            name: '',
          },
          confidentiality: false,
          confidentiality_level: [],
          control_type: {
            id: '',
            name: '',
          },
          cost: 0,
          cost_benefit: 0,
          detect: false,
          final_benefit: 0,
          friendly_id: '',
          group_name: {
            id: '',
            name: '',
          },
          id: '',
          identify: false,
          integrity: false,
          integrity_level: [],
          is_active: false,
          name: '',
          privileges_required: [],
          protect: false,
          recover: false,
          respond: false,
          risk_factors: [],
          scope: [],
          subcontrols: [],
          technical_capacity: 0,
          threats: [],
          type_name: {
            id: '',
            name: '',
          },
          user_interaction: [],
          vulnerabilities: [],
          products: [],
        };
        break;
      }

      case GET_CURRENCIES_REQUEST:
        break;

      case GET_CURRENCIES_SUCCESS: {
        const { allCurrencies } = action.payload;
        draft.allCurrencies = allCurrencies;
        break;
      }

      case GET_CURRENCIES_FAILURE: {
        draft.allCurrencies = {
          count: 0,
          next: null,
          previous: null,
          results: [],
        };
        break;
      }

      default:
    }
  });
};
