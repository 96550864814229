import type { Action } from 'store/types';

import { 
  AllMedicalRecordQuestions,
  AllMedicalRecords,
  MedicalRecordPayload,
  MedicalRecordsActions, 
  PostMedicalRecordRequestPayload,
  PutMedicalRecordRequestPayload,
  SearchFilteredMedicalRecordsPayload,
  SearchFilteredMedicalRecordsQuestionsPayload
} from './types';

const {
  ADD_MEDICALRECORDS_REQUEST
  ,ADD_MEDICALRECORDS_SUCCESS
  ,ADD_MEDICALRECORDS_FAILURE
  ,EDIT_MEDICALRECORDS_REQUEST
  ,EDIT_MEDICALRECORDS_SUCCESS
  ,EDIT_MEDICALRECORDS_FAILURE
  ,GET_ALL_MEDICALRECORDS_REQUEST
	,GET_ALL_MEDICALRECORDS_SUCCESS
	,GET_ALL_MEDICALRECORDS_FAILURE
  ,GET_FILTERED_MEDICALRECORDS_REQUEST
  ,GET_FILTERED_MEDICALRECORDS_SUCCESS
  ,GET_FILTERED_MEDICALRECORDS_FAILURE
  ,ACTIVE_OR_INACTIVE_MEDICALRECORDS_REQUEST
  ,ACTIVE_OR_INACTIVE_MEDICALRECORDS_SUCCESS
  ,ACTIVE_OR_INACTIVE_MEDICALRECORDS_FAILURE
  ,GET_FILTERED_MEDICALRECORDS_QUESTIONS_REQUEST
  ,GET_FILTERED_MEDICALRECORDS_QUESTIONS_SUCCESS
  ,GET_FILTERED_MEDICALRECORDS_QUESTIONS_FAILURE
} = MedicalRecordsActions;

function getMedicalRecordsRequest(): Action {
  return {
    type: GET_ALL_MEDICALRECORDS_REQUEST,
  };
}

function getMedicalRecordsSuccess(allMedicalRecords: AllMedicalRecords): Action {
  return {
    type: GET_ALL_MEDICALRECORDS_SUCCESS,
    payload: {
      allMedicalRecords,
    },
  };
}

function getMedicalRecordsFailure(): Action {
  return {
    type: GET_ALL_MEDICALRECORDS_FAILURE,
  };
}

function putActiveOrInactiveMedicalRecordRequest(
  {
    medicalRecord
  }: MedicalRecordPayload): Action {
  return {
    type: ACTIVE_OR_INACTIVE_MEDICALRECORDS_REQUEST,
    payload: {
      medicalRecord,
    },
  };
}

function putActiveOrInactiveMedicalRecordSuccess(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_MEDICALRECORDS_SUCCESS,
  };
}

function putActiveOrInactiveMedicalRecordFailure(): Action {
  return {
    type: ACTIVE_OR_INACTIVE_MEDICALRECORDS_FAILURE,
  };
}

function postMedicalRecordRequest(
  {
    name,
    question
  }: PostMedicalRecordRequestPayload): Action {
  return {
    type: ADD_MEDICALRECORDS_REQUEST,
    payload: {
      name,
      question
    },
  };
}

function postMedicalRecordSuccess(): Action {
  return {
    type: ADD_MEDICALRECORDS_SUCCESS,
  };
}

function postMedicalRecordFailure(): Action {
  return {
    type: ADD_MEDICALRECORDS_FAILURE,
  };
}

function putMedicalRecordRequest(
  {
    id,
    name,
    question,
    is_active
  }: PutMedicalRecordRequestPayload): Action {
  return {
    type: EDIT_MEDICALRECORDS_REQUEST,
    payload: {
      id,
      name,
      question,
      is_active
    },
  };
}

function putMedicalRecordSuccess(): Action {
  return {
    type: EDIT_MEDICALRECORDS_SUCCESS,
  };
}

function putMedicalRecordFailure(): Action {
  return {
    type: EDIT_MEDICALRECORDS_FAILURE,
  };
}

function getSearchFilteredMedicalRecordsRequest(
  {
    name,
    is_active
  }: SearchFilteredMedicalRecordsPayload
): Action {
  return {
    type: GET_FILTERED_MEDICALRECORDS_REQUEST,
    payload: {
      name,
      is_active
    },
  };
}

function getSearchFilteredMedicalRecordsSuccess(allMedicalRecords: AllMedicalRecords): Action {
  return {
    type: GET_FILTERED_MEDICALRECORDS_SUCCESS,
    payload: {
      allMedicalRecords,
    },
  };
}

function getSearchFilteredMedicalRecordsFailure(): Action {
  return {
    type: GET_FILTERED_MEDICALRECORDS_FAILURE,
  };
}

function getSearchFilteredMedicalRecordsQuestionsRequest(
  {
    question,
  }: SearchFilteredMedicalRecordsQuestionsPayload
): Action {
  return {
    type: GET_FILTERED_MEDICALRECORDS_QUESTIONS_REQUEST,
    payload: {
      question,
    },
  };
}

function getSearchFilteredMedicalRecordsQuestionsSuccess(allMedicalRecordQuestions: AllMedicalRecordQuestions): Action {
  return {
    type: GET_FILTERED_MEDICALRECORDS_QUESTIONS_SUCCESS,
    payload: {
      allMedicalRecordQuestions,
    },
  };
}

function getSearchFilteredMedicalRecordsQuestionsFailure(): Action {
  return {
    type: GET_FILTERED_MEDICALRECORDS_QUESTIONS_FAILURE,
  };
}

export const medicalRecordsActionsFunctions = {
  postMedicalRecordRequest
  ,postMedicalRecordSuccess
  ,postMedicalRecordFailure
  ,putMedicalRecordRequest
  ,putMedicalRecordSuccess
  ,putMedicalRecordFailure
  ,getMedicalRecordsRequest
  ,getMedicalRecordsSuccess
  ,getMedicalRecordsFailure
  ,getSearchFilteredMedicalRecordsRequest
  ,getSearchFilteredMedicalRecordsSuccess
  ,getSearchFilteredMedicalRecordsFailure
  ,putActiveOrInactiveMedicalRecordRequest
  ,putActiveOrInactiveMedicalRecordSuccess
  ,putActiveOrInactiveMedicalRecordFailure
  ,getSearchFilteredMedicalRecordsQuestionsRequest
  ,getSearchFilteredMedicalRecordsQuestionsSuccess
  ,getSearchFilteredMedicalRecordsQuestionsFailure
};
