import { AxiosError, AxiosResponse } from 'axios';
import { ToastMessages } from 'constants/toast';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services/api';
import { history } from 'services/history';
import { EditCustomerStatus } from 'store/modules/customer/types';
import { lastApiFetchDataActionsFunctions } from 'store/modules/lastApiFetchData/actions';
import type { Action } from 'store/types';

import { controlActionsFunctions } from './actions';
import { apiControlRequestActionPayload, apiCreateNewControlRequestActionPayload, apiEditControlRegistrationsRequestActionPayload, ControlActions, ControlDetail, ControlEditBodyResponse, ControlEditRequestBody, ControlInfoUpdateStatus, Currencies, currencyPayload, Pricing, pricingsPayload, Product, ProductPayload, PutPricingsPayload, PutProductPayload } from './types';


const {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_AUTHORIZATION_ERROR_MESSAGE,
} = ToastMessages;

const {  
  putPricingSuccess,
  putPricingFailure,
  putProductSuccess,
  putProductFailure,
  editControlSuccess,
  editControlFailure,  
  postProductSuccess,
  postProductFailure,
  getCurrenciesRequest,
  getCurrenciesSuccess,
  getCurrenciesFailure,
  postNewPricingSuccess,
  postNewPricingFailure,
  getControlDetailRequest,
  getControlDetailSuccess,
  getControlDetailFailure,
  createNewControlSuccess,
  createNewControlFailure,
  editControlStatusSuccess,
  editControlStatusFailure,  
  postNewCurrenciesSuccess,
  postNewCurrenciesFailure,
  editControlRegistrationsRequest,
  editControlRegistrationsSuccess,
  editControlRegistrationsFailure,
} = controlActionsFunctions;

const {    
  EDIT_CONTROL_REQUEST,  
  UPDATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_REQUEST,
  GET_CURRENCIES_REQUEST,
  CREATE_PRICINGS_REQUEST,
  UPDATE_PRICINGS_REQUEST,
  CREATE_CURRENCIES_REQUEST,
  GET_CONTROL_DETAIL_REQUEST,
  CREATE_NEW_CONTROL_REQUEST,
  EDIT_CONTROL_STATUS_REQUEST,
  EDIT_CONTROL_REGISTRATION_REQUEST,
} = ControlActions;

const { getLastApiFetchDataSuccess, getLastApiFetchDataFailure } =
  lastApiFetchDataActionsFunctions;

function* fetchControlDetailRequest(action: Action): Generator {
  const { controlId } = action.payload as apiControlRequestActionPayload;
  try {
    const riskFactorsResponse: AxiosResponse<ControlDetail> | unknown =
      yield call(api, 'GET', `controls/controls/${controlId}/detail/`, {});

    const {
      data: control,
      config: { url },
      status,
      statusText,
    } = riskFactorsResponse as AxiosResponse<ControlDetail>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getControlDetailSuccess(control));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getControlDetailFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
        DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchEditControlStatus(action: Action): Generator {
  const { controlId, controlStatus } = action.payload as ControlInfoUpdateStatus;
  try {
    const editControlStatus: AxiosResponse<EditCustomerStatus> | unknown = yield call(
      api,
      'PUT',
      `/controls/controls/${controlId}/update-status/`,
      {
        is_active: controlStatus
      }
    );

    const {
      config: { url },
      status,
      statusText,
    } = editControlStatus as AxiosResponse<EditCustomerStatus>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editControlStatusSuccess());
    toast.success('Status editado com Sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editControlStatusFailure());

    toast.error('Falha ao editar status.');
  }
}

function* fetchCreateNewControlRequest(action: Action): Generator {
  const { controlBody } = action.payload as apiCreateNewControlRequestActionPayload;
  try {
    const riskFactorsResponse: AxiosResponse<ControlDetail> | unknown =
      yield call(api, 'POST', 'controls/controls/create/', {
        ...controlBody
      });

    const {
      data: control,
      config: { url },
      status,
      statusText,
    } = riskFactorsResponse as AxiosResponse<ControlDetail>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(createNewControlSuccess());
    history.push(`/control-registration/${control.id}`);
    toast.success('Controle criado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(createNewControlFailure());

    if(currentError?.response?.data?.detail === 'You do not have permission to perform this action.'){
      toast.warning('Você não tem permissão para criar controles');
    }

    if(currentError?.response?.data?.name[0] === 'control with this name already exists.') {
      toast.warn('Já existe um controle com este nome.');      
    } else {
      toast.error(
        currentError?.response?.data?.messages[0]?.message ??
          DEFAULT_ERROR_MESSAGE
      );
    }    
  }
}

function* fetchEditControlRegistrationRequest(action: Action): Generator {
  const { controlId, controlEditBody } = action.payload as apiEditControlRegistrationsRequestActionPayload;
  try {
    const riskFactorsResponse: AxiosResponse<ControlEditBodyResponse> | unknown =
      yield call(api, 'PUT', `controls/controls/${controlId}/update-links/`, {
        ...controlEditBody
      });

    const {
      data: control,
      config: { url },
      status,
      statusText,
    } = riskFactorsResponse as AxiosResponse<ControlEditBodyResponse>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editControlRegistrationsSuccess());
    history.push(`/control-visualization/${controlId}/`);
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(editControlRegistrationsFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
        DEFAULT_ERROR_MESSAGE
    );
  }
}

function* fetchEditControlRequest(action: Action): Generator {
  const { controlId, controlEditBody, controlEditRegistrationBody } = action.payload as apiEditControlRegistrationsRequestActionPayload;
  try {
    const controlEditRequestResponse: AxiosResponse<ControlEditRequestBody> | unknown =
      yield call(api, 'PUT', `controls/controls/${controlId}/`, {
        ...controlEditBody
      });

    const {
      data: control,
      config: { url },
      status,
      statusText,
    } = controlEditRequestResponse as AxiosResponse<ControlEditRequestBody>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(editControlSuccess());
    yield put(editControlRegistrationsRequest(controlId, controlEditRegistrationBody));
    history.push(`/control-visualization/${controlId}/`);
    toast.success('Vínculos registrados com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(editControlFailure());
    yield put(editControlRegistrationsFailure());
    history.push('/controls');
    toast.error('Não foi possível editar esse controle.');
  }
}

function* fetchNewPricingRequest(action: Action): Generator {  
  const { controlId, sizing_type, currency, product, start_range, end_range, unit_price, } = action.payload as pricingsPayload;
  try {
    const pricingsResponse: AxiosResponse<Pricing> | unknown =
      yield call(api, 'POST', 'controls/pricings/create/', {
        sizing_type, 
        currency, 
        product,
        start_range, 
        end_range, 
        unit_price,
      });

    const {
      config: { url },
      status,
      statusText,
    } = pricingsResponse as AxiosResponse<Pricing>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(postNewPricingSuccess());
    yield put(getControlDetailRequest(controlId));
    toast.success('Precificação criada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(postNewPricingFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
        DEFAULT_ERROR_MESSAGE
    );    
  }
}

function* fetchGetCurrecies(): Generator {
  try {
    const currenciesResponse: AxiosResponse<Currencies[]> | unknown = yield call(
      api,
      'GET',
      'controls/currencies/',
      {}
    );

    const {
      data: allCurrencies,
      config: { url },
      status,
      statusText,
    } = currenciesResponse as AxiosResponse<Currencies[]>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(getCurrenciesSuccess(allCurrencies));
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(getCurrenciesFailure());

    if (currentError?.response?.status === 403) {
      toast.warning(DEFAULT_AUTHORIZATION_ERROR_MESSAGE);
    } else {
      toast.error(currentError?.response?.data?.messages[0]?.message ?? DEFAULT_ERROR_MESSAGE);
    }
  }
}

function* fetchTPutPricing(action: Action): Generator {
  const { id, controlId, sizing_type, currency, start_range, end_range, unit_price, } = action.payload as PutPricingsPayload;

  try {
    const updateResponse: AxiosResponse<Pricing> | unknown =
      yield call(api, 'PUT', `controls/pricings/${id}/`, {
        sizing_type, 
        currency, 
        start_range, 
        end_range, 
        unit_price,
      });

    const {
      config: { url },
      status,
      statusText,
    } = updateResponse as AxiosResponse<Pricing>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(putPricingSuccess());
    yield put(getControlDetailRequest(controlId));

    toast.success('Edição da precificação foi realizada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(putPricingFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      'Tentativa de edição de precificação falhou.'
    );
  }
}

function* fetchNewCurreciesRequest(action: Action): Generator {
  const { name, symbol } = action.payload as currencyPayload;
  try {
    const currenciesResponse: AxiosResponse<Currencies> | unknown =
      yield call(api, 'POST', 'controls/currencies/create/', {
        name, 
        symbol,
      });

    const {
      config: { url },
      status,
      statusText,
    } = currenciesResponse as AxiosResponse<Currencies>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(postNewCurrenciesSuccess());
    yield put(getCurrenciesRequest());
    toast.success('Moeda criada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(postNewCurrenciesFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
        DEFAULT_ERROR_MESSAGE
    );    
  }
}

function* fetchPostProductRequest(action: Action): Generator {
  const { controlId, name, control, subcontrol, default_control, type_control, time_to_deploy } = action.payload as ProductPayload;
  try {
    const pricingsResponse: AxiosResponse<Product> | unknown =
      yield call(api, 'POST', 'controls/product/create/', type_control === 'CONTROL' ? {
        name, 
        control, 
        default_control,
        time_to_deploy
      } : {
        name, 
        subcontrol, 
        default_control,
        time_to_deploy
      });

    const {
      config: { url },
      status,
      statusText,
    } = pricingsResponse as AxiosResponse<Product>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(postProductSuccess());
    yield put(getControlDetailRequest(controlId));
    toast.success('Produto criado com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(postProductFailure());

    toast.error(
      currentError?.response?.data?.messages[0]?.message ??
        DEFAULT_ERROR_MESSAGE
    );    
  }
}

function* fetchTPutProduct(action: Action): Generator {
  const { id, name, is_active, default_control, controlId, time_to_deploy } = action.payload as PutProductPayload;

  try {
    const updateResponse: AxiosResponse<Product> | unknown =
      yield call(api, 'PUT', `controls/product/${id}/`, {
        name,         
        default_control,
        is_active,
        time_to_deploy
      });

    const {
      config: { url },
      status,
      statusText,
    } = updateResponse as AxiosResponse<Product>;

    yield put(
      getLastApiFetchDataSuccess({
        url,
        status,
        statusText,
      })
    );

    yield put(putPricingSuccess());
    yield put(getControlDetailRequest(controlId));

    toast.success('Edição do produto foi realizada com sucesso.');
  } catch (error) {
    const currentError = error as AxiosError;

    yield put(getLastApiFetchDataFailure(currentError));
    yield put(putPricingFailure());

    toast.error(
      currentError?.response?.data?.detail ??
      'Tentativa de edição do produto falhou.'
    );
  }
}

export function* controlSagas() {
  yield all([
    takeLatest(UPDATE_PRODUCT_REQUEST, fetchTPutProduct),
    takeLatest(GET_CURRENCIES_REQUEST, fetchGetCurrecies),
    takeLatest(UPDATE_PRICINGS_REQUEST, fetchTPutPricing),
    takeLatest(EDIT_CONTROL_REQUEST, fetchEditControlRequest),
    takeLatest(CREATE_PRICINGS_REQUEST, fetchNewPricingRequest),
    takeLatest(CREATE_PRODUCT_REQUEST, fetchPostProductRequest),
    takeLatest(EDIT_CONTROL_STATUS_REQUEST, fetchEditControlStatus),
    takeLatest(CREATE_CURRENCIES_REQUEST, fetchNewCurreciesRequest),
    takeLatest(GET_CONTROL_DETAIL_REQUEST, fetchControlDetailRequest),
    takeLatest(CREATE_NEW_CONTROL_REQUEST, fetchCreateNewControlRequest),
    takeLatest(EDIT_CONTROL_REGISTRATION_REQUEST, fetchEditControlRegistrationRequest),    
  ]);
}
